import React, { useEffect } from 'react';
import {
	select,
	scaleBand,
	scaleLinear,
	axisBottom,
	axisLeft,
	ticks,
} from 'd3';
import { Numbers } from '../../utils/Enum';
import { STEPTheme } from '../../utils/Theme';

interface DataItem {
	label: string;
	topBarVal: number;
	bottomBarVal: number;
	middleBarVal: number;
}
interface BarChartProps {
	data: DataItem[];
	width: number;
	height: number;
	chartType: string;
	bottomBarColor: string;
	middleBarColor: string;
	topBarColor: string;
	id: string;
	yMax: number;
}

export const BarChartComponent: React.FC<BarChartProps> = (props) => {
	const svgStretchFactor = Numbers.zeroPointSixFive;
	const svgMarginTop = Numbers.twenty;
	const svgMarginRight = Numbers.ten;
	const svgMarginBottom = Numbers.twenty;
	const svgMarginLeft = Numbers.sixtyFive;
	const barPadding = Numbers.zeroPointEight;
	const gridOpacity = Numbers.zeroPointOne;

	const createBarChart = (id: string, chartType: string, yMax: number) => {
		//Selects container in which bar chart is and defines dimensions for bar chart
		const container = document.getElementById(id);
		// Get the width of the screen and based on it decide svg width
		const windowWidth = window.innerWidth;
		const containerWidth = windowWidth * svgStretchFactor;
		// Set a fixed height
		const containerHeight = props.height;
		const svgMargin = {
			top: svgMarginTop,
			right: svgMarginRight,
			bottom: svgMarginBottom,
			left: svgMarginLeft,
		};
		const svgWidth = containerWidth - svgMargin.left - svgMargin.right;
		const height = containerHeight - svgMargin.top - svgMargin.bottom;

		select(container).select('svg').remove();

		const svg = select(container)
			.append('svg')
			// Set the initial width based on the container
			.attr('width', containerWidth)
			.attr('height', containerHeight)
			.append('g')
			.attr('transform', `translate(${svgMargin.left},${svgMargin.top})`);

		// Defines the X-Scale
		// Increase or Decrease the padding to control thickness of bars
		const xScale = scaleBand()
			.range([0, svgWidth])
			.padding(barPadding)
			.domain(props.data.map((d) => d.label));

		// Defines Y- Scale with axis being zero and max being 100
		const yScale = scaleLinear().range([height, 0]).domain([0, yMax]);
		const numTicks = 5;
		const tickValues = ticks(yScale.domain()[0], yScale.domain()[1], numTicks);

		svg
			.append('g')
			.attr('transform', `translate(0,${height})`)
			.call(axisBottom(xScale));
		svg.append('g').call(
			axisLeft(yScale)
				.tickValues(tickValues)
				.tickFormat((d) => (chartType === 'Percentage' ? `${d}% ` : `${d}`))
		);

		// To make small ticks blend with the background
		svg.selectAll('.tick line').attr('stroke', 'white');

		// Renders the Grid visible in the background
		svg
			.append('g')
			.call(
				axisLeft(yScale)
					.tickValues(tickValues)
					.tickSize(-svgWidth)
					.tickFormat(() => ``)
			)
			.style('opacity', gridOpacity)
			.style('z-index', '-10')
			.attr('class', 'grid');

		// Blends the X-axis and Y-axis lines with Background
		svg.selectAll('.domain').attr('stroke', 'white');

		// Designs the bars
		const bars = svg
			.selectAll('.bar-group')
			.data(props.data)
			.enter()
			.append('g')
			.attr('class', 'bar-group')
			.attr('transform', (d) => `translate(${xScale(d.label)},0)`)
			.style('font-family', "'JohnsonText-Regular'")
			.style('font-size', '12px');
		//  Tooltip component is added and kept invisible and is made visible only on hover
		const tooltip = select(container)
			.append('div')
			.attr('class', 'tooltip')
			.style('display', 'none')
			.style('position', 'absolute')
			.style('background-color', STEPTheme.colors.black)
			.style('color', STEPTheme.colors.white)
			.style('padding', '5px')
			.style('border-radius', '5px')
			.style('box-shadow', '0px 4px 20px rgba(0, 0, 0, 0.2)')
			.style('font-family', "'JohnsonText-Regular'")
			.style('font-size', '12px');
		// Responsible for rendering bottom part of each bar

		bars
			.append('rect')
			.attr('class', 'bottom-bar')
			.attr('data-testid', (d) => `${d.label} bottom`)
			.attr('y', (d) => yScale(d.bottomBarVal))
			.attr('height', (d) => height - yScale(d.bottomBarVal))
			.attr('width', xScale.bandwidth())
			.style('fill', props.bottomBarColor)
			.on('mouseover', (event, d) => {
				tooltip
					.style('display', 'block')
					.style('top', `${event.pageY - Numbers.thirty}px`)
					.style('left', `${event.pageX}px`);
				tooltip.html(
					`<strong>In Scope</strong><br> ${d.bottomBarVal}${chartType === 'Percentage' ? '%' : ''}`
				);
			})
			.on('mouseout', () => {
				tooltip.style('display', 'none');
			});
		// Responsible for rendering middle part of each bar
		bars
			.append('rect')
			.attr('class', 'middle bar')
			.attr('data-testid', (d) => `${d.label} middle`)
			.attr('y', (d) => yScale(d.bottomBarVal + d.middleBarVal))
			.attr('height', (d) => height - yScale(d.middleBarVal))
			.attr('width', xScale.bandwidth())
			.style('fill', props.middleBarColor)
			.on('mouseover', (event, d) => {
				tooltip
					.style('display', 'block')
					.style('top', `${event.pageY - Numbers.thirty}px`)
					.style('left', `${event.pageX}px`);
				tooltip.html(
					`<strong>Not Assessed</strong><br> ${d.middleBarVal}${chartType === 'Percentage' ? '%' : ''}`
				);
			})
			.on('mouseout', () => {
				tooltip.style('display', 'none');
			});

		// Responsible for rendering top part of each bar
		bars
			.append('rect')
			.attr('class', 'top-bar')
			.attr('data-testid', (d) => `${d.label} top`)
			.attr('y', (d) => yScale(d.bottomBarVal + d.topBarVal + d.middleBarVal))
			.attr('height', (d) => height - yScale(d.topBarVal))
			.attr('width', xScale.bandwidth())
			.style('fill', props.topBarColor)
			.on('mouseover', (event, d) => {
				tooltip
					.style('display', 'block')
					.style('top', `${event.pageY - Numbers.thirty}px`)
					.style('left', `${event.pageX}px`);
				tooltip.html(
					`<strong>Out of Scope</strong><br> ${d.topBarVal}${chartType === 'Percentage' ? '%' : ''}`
				);
			})
			.on('mouseout', () => {
				tooltip.style('display', 'none');
			});
		svg
			.selectAll('text')
			.style('font-family', "'JohnsonText-Regular'")
			.style('font-size', '12px');
	};

	useEffect(() => {
		createBarChart(props.id, props.chartType, props.yMax);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	/**
	 * Dynamically update SVG width on window resize
	 * Update the SVG width
	 */
	window.addEventListener('resize', () => {
		createBarChart(props.id, props.chartType, props.yMax);
	});
	return <div id={props.id}></div>;
};

export default BarChartComponent;
