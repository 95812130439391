import React, { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Paper, makeStyles } from '@material-ui/core';
import { Box, Button, styled } from '@mui/material';
import Add from '@mui/icons-material/Add';
import QuestionsList from '../../components/questions/QuestionsList';
import {
	dynamicId,
	getQuestionnairePayload,
	reorder,
} from '../../utils/Helpers';
import { CreateQuestionnaireQuestionsProps } from '../../types/CommonTypes';
import { STEPTheme } from '../../utils/Theme';
import QuestionPopup from '../../components/questions/QuestionPopup';
import QuestionSubHeader from '../../components/questions/QuestionSubHeader';
import QuestionHeader from '../../components/questions/QuestionHeader';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	APILoadingStatus,
	configurationData,
	resetAPIFlag,
	surveyQuestionnaireData,
	updateQuestionnaireData,
	updateSurveyQuestionnaire,
} from '../../redux/SurveySlice';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { QUE_JOURNEY_STEPS } from '../../utils/Enum';
import GenericModalPopup from '../../components/common/GenericModalPopup';
import { commonLabels } from '../../utils/CommonLabels';
import Loader from '../../components/Loader';
import {
	API_STATUS_CONSTANT,
	QUECONSTANT,
} from '../../constants/StepConstants';
import QuestionnaireFooter from '../../components/questions/QuestionnaireFooter';
import {
	setCheckboxState,
	setDropDownOptionsCount,
	setPageIndex,
} from '../../redux/QuestionnaireSurveySlice';

const useStyles = makeStyles({
	actionBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '20px',
	},
	plusIcon: {
		width: '0.8em',
	},
	flexPaper: {
		flex: 1,
		margin: 16,
		minWidth: 350,
		maxWidth: '1024px',
		boxShadow: 'none',
		background: STEPTheme.colors.background,
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		background: STEPTheme.colors.background,
		justifyContent: 'center',
	},
});

const AddQuestionButton = styled(Button)({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	width: 'auto',
	height: '32px',
	marginBottom: '5px',
	color: STEPTheme.colors.text,
	textTransform: 'capitalize',
	background: 'white',
	padding: '8px 8px 8px 4px',
	borderRadius: '8px',
	border: `1px solid ${STEPTheme.colors.text}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.white,
		borderColor: STEPTheme.colors.text,
		color: STEPTheme.colors.text,
	},
});

const CreateSurveyQuestions: React.FC<CreateQuestionnaireQuestionsProps> = ({
	handleNext,
	handleBack,
	backToDetailsPage,
	activeStep,
}) => {
	const dispatch = useAppDispatch();
	const classes = useStyles();
	const itemsFack: ISurveyFormsQuestion[] = [];
	const [items, setItems] = React.useState(itemsFack);
	const [popupOpen, setPopupOpen] = React.useState(false);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
	const [open, setOpen] = React.useState(false);
	const handleClosePopup = () => {
		setOpen(false);
		dispatch(resetAPIFlag('idle'));
	};
	const pageFooterIndex = useAppSelector((state) => state.Questionnaire.index);
	const [showLoader, setLoaderOpen] = React.useState(false);
	const [isSaveProceed, setSaveProceed] = React.useState(false);
	const APICompletionStatus = useAppSelector(APILoadingStatus);
	const configs = useAppSelector(configurationData);
	useEffect(() => {
		if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
			setLoaderOpen(false);
			if (isSaveProceed) {
				dispatch(resetAPIFlag('idle'));
				handleNext();
			} else {
				setOpen(true);
			}
		} else {
			dispatch(resetAPIFlag('idle'));
			setOpen(false);
			setLoaderOpen(false);
			setSaveProceed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	useEffect(() => {
		// Check if there are questions and questionIsValid is true for all questions
		if (surveyQuestionnaireInfo.surveyFormsQuestion.length === 0) {
			setDisableSubmit(true);
		} else if (
			surveyQuestionnaireInfo.surveyFormsQuestion.length > 0 &&
			items.length === surveyQuestionnaireInfo.surveyFormsQuestion.length
		) {
			const invalidQuestionLength =
				surveyQuestionnaireInfo.surveyFormsQuestion.filter((question) => {
					return (
						question.questionIsValid === false ||
						question.questionButtonLabel === commonLabels.save
					);
				}).length;
			if (invalidQuestionLength > 0) {
				setDisableSubmit(true);
			} else {
				setDisableSubmit(false);
			}
		} else {
			if (items.length > surveyQuestionnaireInfo.surveyFormsQuestion.length) {
				const unsavedQuestionLength = items.filter((question) => {
					return question.questionButtonLabel === commonLabels.save;
				}).length;

				if (unsavedQuestionLength > 0) {
					setDisableSubmit(true);
				}
			}
		}
	}, [surveyQuestionnaireInfo, items, disableSubmit]);

	const handleClosePopupQue = (e: string) => {
		setPopupOpen(false);
		if (e !== 'close') {
			addQuestion(e);
		}
	};
	useEffect(() => {
		setItems(surveyQuestionnaireInfo.surveyFormsQuestion);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveAsProceed = () => {
		setSaveProceed(true);
		setLoaderOpen(true);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			currentStep: QUE_JOURNEY_STEPS.STEP_QUESTIONS,
			userEmailId: configs?.user?.userEmailId || '',
		});
		dispatch(updateQuestionnaireData(payload));
	};

	const saveAsDraft = () => {
		setLoaderOpen(true);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			currentStep: QUE_JOURNEY_STEPS.STEP_QUESTIONS,
			userEmailId: configs?.user?.userEmailId || '',
		});
		dispatch(updateQuestionnaireData(payload));
	};

	const onDragEnd = ({ destination, source }: DropResult) => {
		const updatedData = surveyQuestionnaireInfo.surveyFormsQuestion;
		if (
			items[source.index].isNew ||
			(destination && items[destination.index].isNew)
		) {
			alert(questionsLabels.queReorderError);
			return;
		}
		// dropped outside the list
		if (!destination) return;
		const newItems = reorder(updatedData, source.index, destination.index);
		const updatedItems = newItems.map((item, index) => {
			return {
				...item,
				order: index,
			};
		});
		dispatch(
			updateSurveyQuestionnaire({
				...surveyQuestionnaireInfo,
				currentStep: QUE_JOURNEY_STEPS.STEP_QUESTIONS,
				surveyFormsQuestion: [...updatedItems],
			})
		);
		setItems(updatedItems);
	};

	const addQuestion = (type: string) => {
		const newItem: ISurveyFormsQuestion = {
			questionId: dynamicId(),
			questionType: type,
			options: [],
			question: '',
			instructions: '',
			isNew: true,
			order: items.length,
			questionIsValid: false,
			questionButtonLabel: commonLabels.save,
		};
		setDisableSubmit(true);
		setItems((prevItems) => [...prevItems, newItem]);
		if (
			[
				QUECONSTANT.QUESTION_TYPE_YES_NO,
				QUECONSTANT.QUESTION_TYPE_MULTI_SELECT,
				QUECONSTANT.QUESTION_TYPE_OPEN,
				QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION,
				QUECONSTANT.QUESTION_TYPE_DROPDOWN,
			].includes(type)
		) {
			const newIndex = pageFooterIndex + 1;
			dispatch(setPageIndex(newIndex));
		}
		if (type === QUECONSTANT.QUESTION_TYPE_DROPDOWN) {
			dispatch(
				setDropDownOptionsCount({
					questionId: newItem.questionId,
					length: 0,
				})
			);
		}
	};

	const handleAddQuestion = () => {
		setPopupOpen(true);
	};
	const handlerDeleteQuestion = (index: string) => {
		let updatedData = surveyQuestionnaireInfo.surveyFormsQuestion.filter(
			(question) => question.questionId !== index
		);
		// updating order of questions
		updatedData = updatedData.map((data, position) => {
			return {
				...data,
				order: position,
			};
		});
		dispatch(
			updateSurveyQuestionnaire({
				...surveyQuestionnaireInfo,
				currentStep: QUE_JOURNEY_STEPS.STEP_QUESTIONS,
				surveyFormsQuestion: updatedData,
			})
		);
		setItems((prevItems) =>
			prevItems.filter((item) => item.questionId !== index)
		);
		const newIndex = pageFooterIndex - 1;
		dispatch(setPageIndex(newIndex));
	};

	const getOptions = (id: string) => {
		return surveyQuestionnaireInfo.surveyFormsQuestion.filter(
			(question) => question.questionId === id
		)[0].options;
	};

	const handleQueClone = (data: ISurveyFormsQuestion) => {
		let options = data.options;
		if (data.questionType === QUECONSTANT.QUESTION_TYPE_DROPDOWN) {
			options = getOptions(data.questionId);
		}
		let newItem: ISurveyFormsQuestion = {
			questionId: dynamicId(),
			questionType: data.questionType,
			options: options,
			question: data.question,
			instructions: data.instructions,
			isNew: true,
			order: items.length,
			questionIsValid: false,
			questionButtonLabel: commonLabels.save,
		};
		if (
			data.questionType === QUECONSTANT.QUESTION_TYPE_DROPDOWN &&
			data?.fileName
		) {
			newItem = { ...newItem, fileName: data.fileName };
		}
		if (data.instructions) {
			// disptach to set checked for cloned question
			dispatch(
				setCheckboxState({ questionId: newItem.questionId, value: true })
			);
		}
		setItems((prevItems) => [...prevItems, newItem]);
		if (
			[
				QUECONSTANT.QUESTION_TYPE_YES_NO,
				QUECONSTANT.QUESTION_TYPE_MULTI_SELECT,
				QUECONSTANT.QUESTION_TYPE_OPEN,
				QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION,
				QUECONSTANT.QUESTION_TYPE_DROPDOWN,
			].includes(data.questionType)
		) {
			const newIndex = pageFooterIndex + 1;
			dispatch(setPageIndex(newIndex));
		}
	};

	const saveAndEditQuestion = (
		formData: ISurveyFormsQuestion,
		index: number
	) => {
		if (formData.isNew) {
			formData.isNew = false;
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_QUESTIONS,
					surveyFormsQuestion: [
						...surveyQuestionnaireInfo.surveyFormsQuestion,
						{ ...formData },
					],
				})
			);
		} else {
			const updatedSurveyFormsQuestion =
				surveyQuestionnaireInfo.surveyFormsQuestion.map(
					(question, queIndex: number) => {
						if (queIndex === index) {
							// assuming each question has a unique id
							return { ...formData }; // update the desired item
						}
						return question; // keep the other items as they are
					}
				);
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_QUESTIONS,
					surveyFormsQuestion: updatedSurveyFormsQuestion,
				})
			);
		}
	};
	return (
		<div className={classes.root}>
			<Loader isLoading={showLoader} />
			<Paper className={classes.flexPaper}>
				<div style={{ height: 'auto', paddingBottom: '90px' }}>
					<QuestionHeader />
					<QuestionSubHeader
						showEditIcon={true}
						handleBack={handleBack}
						backToDetailsPage={backToDetailsPage}
						activeStep={activeStep}
					/>
					<QuestionsList
						onQueSave={saveAndEditQuestion}
						onQueClone={handleQueClone}
						deleteQue={handlerDeleteQuestion}
						items={items}
						onDragEnd={onDragEnd}
					/>

					<Box className={classes.actionBox}>
						<AddQuestionButton onClick={() => handleAddQuestion()}>
							<Add className={classes.plusIcon} />
							{questionsLabels.addNewQue}
						</AddQuestionButton>
					</Box>
				</div>
				<QuestionnaireFooter
					handleNext={saveAsProceed}
					handleSaveDraft={saveAsDraft}
					buttonEnable={disableSubmit}
					index={pageFooterIndex}
				></QuestionnaireFooter>
			</Paper>
			<QuestionPopup open={popupOpen} onClose={handleClosePopupQue} />
			<GenericModalPopup
				openProp={open}
				msgBody={commonLabels.saveAsDraftText}
				mainMsg=""
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={handleClosePopup}
				leftBtnHandler={handleClosePopup}
			/>
		</div>
	);
};
export default CreateSurveyQuestions;
