import axiosIntercept from '../app/authentication/axiosIntercept';
export const QuestionnaireResultsData = async (requestPayload: {
	questionnaireId: string;
	action?: string;
}) => {
	return axiosIntercept.get(
		`questionnaire/${requestPayload.questionnaireId}/getResults`
	);
};

export const DownloadResults = async (id: string) => {
	return axiosIntercept.post(`questionnaire/${id}/downloadresult`, null, {
		responseType: 'blob',
	});
};

export default QuestionnaireResultsData;
