import { MenuItem, Select } from '@mui/material';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { ConfigureOptionsProp } from '../../types/QuestionType';
import { QUECONSTANT } from '../../constants/StepConstants';

const ConfigureOptions = (props: ConfigureOptionsProp) => {
	const { option, isEditable, index, questions, classN, register, i } = props;
	const returnDropdDownValues = (questionIndex: number) => {
		{
			let options = [
				<MenuItem value="" key="default">
					{QUECONSTANT.QUESTION_CONDITION_LABEL}
				</MenuItem>,
				<MenuItem value={questionsLabels.endQuestionnaire} key="end">
					{questionsLabels.endQuestionnaire}
				</MenuItem>,
			];
			options = [...options];
			questions.forEach((question: ISurveyFormsQuestion) => {
				if (question?.order && question?.order > questionIndex) {
					options.push(
						<MenuItem
							key={question?.questionId}
							value={`${questionsLabels.continueTo} ${question?.order + 1}`}
						>
							{questionsLabels.continueTo} {question?.order + 1}
						</MenuItem>
					);
				}
			});
			return options;
		}
	};
	return (
		<Select
			className={classN}
			labelId="dropdown-label"
			id="dropdown"
			data-testid="dropdown"
			disabled={!isEditable}
			variant="standard"
			value={option?.condition}
			{...register(`options.${i}.condition`)}
			displayEmpty
		>
			{returnDropdDownValues(index)}
		</Select>
	);
};

export default ConfigureOptions;
