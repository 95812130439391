import './App.css';
import './assets/css/styles.css';
import './assets/css/mui-overwrite.css';
import './assets/css/base.css';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
	InteractionRequiredAuthError,
	IPublicClientApplication,
} from '@azure/msal-browser';
import { commonLabels } from '../src/utils/CommonLabels';
import Header from './components/Header';
import Footer from './components/Footer';
import RouterOutlet from './app/RouterOutlet';
import { stepMuiTheme } from './utils/Theme';
import {
	configurationData,
	fetchConfigData,
	setConfigurationData,
} from './redux/SurveySlice';
import { useAppDispatch, useAppSelector } from './app/Hooks';
import { getDocumentCookie, checkRolePermissions } from './utils/Helpers';
import { loginRequest, msalConfig } from './app/authentication/AuthConfig';
import { setLocalStorageValues } from './middleware/LocalStorageService';
import GenericConfirmationPopup from './components/common/GenericConfirmationPopup';
import { Numbers } from './utils/Enum';

declare global {
	interface Window {
		msalInstance: IPublicClientApplication;
		msalUserName: string;
	}
}

const STEPContainer = styled('div')`
	padding: 0px 0px;
	margin: 0px 0px;
`;

const App = () => {
	const cookieAuth = getDocumentCookie();
	const { instance } = useMsal();
	const [userData, setUserData] = useState(cookieAuth);
	const dispatch = useAppDispatch();
	const configs = useAppSelector(configurationData);
	const isDev =
		process.env.REACT_APP_ENVIRONMENT &&
		process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'dev';
	const renewalTime = {
		five: 5,
		sixty: 60,
		thousand: 1000,
	};
	const TOKEN_RENEWAL_TIME =
		renewalTime.five * renewalTime.sixty * renewalTime.thousand;
	const LOGIN_TIMEOUT = 1500;
	const [showRedirectPopup, setShowRedirectPopup] = useState(false);
	let interval: NodeJS.Timeout | undefined;
	const localStorageUpdated = async (valueSet = false) => {
		if (!valueSet) {
			const isUpdated: boolean = await setLocalStorageValues();
			// call update to cookie only when localStorage set- avoid multiple useEffect trigger
			isUpdated && setUserData(getDocumentCookie());
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		checkToken();
		removeRedirectUrl();
		// check if accounts retreived from cache
		if (instance.getAllAccounts().length === 0) {
			localStorageUpdated();
		} else {
			localStorageUpdated(true);
			const account = instance.getAllAccounts()[0];
			const userName = account?.username;
			window.msalInstance = instance;
			window.msalUserName = userName;
			// Set auto token renewal call on each 5 min
			// eslint-disable-next-line react-hooks/exhaustive-deps
			interval = setInterval(() => {
				const config = {
					scopes: loginRequest.scopes,
					authority: msalConfig.auth.authority,
					account: account,
				};
				const redRequest = {
					scopes: loginRequest.scopes,
					loginHint: userName,
				};
				instance
					.acquireTokenSilent(config)
					.then(
						(res) => {
							// eslint-disable-next-line no-console
							console.log('Token Renewed...', isDev ? res : {}); //NOSONAR
						},
						(err) => {
							if (err instanceof InteractionRequiredAuthError) {
								return instance.acquireTokenRedirect(redRequest);
							}
						}
					)
					.catch((error) => {
						if (error instanceof InteractionRequiredAuthError) {
							return instance.acquireTokenRedirect(redRequest);
						}
					});
			}, TOKEN_RENEWAL_TIME);
		}
		setTimeout(() => {
			window.location.hash = '';
		}, LOGIN_TIMEOUT);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		if (configs?.rolePermissions?.isAdmin && !configs?.location) {
			dispatch(fetchConfigData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configs?.rolePermissions]);

	showRedirectPopUp = () => {
		setShowRedirectPopup(true);
	};

	const checkToken = () => {
		if (userData?.idToken) {
			dispatch(
				setConfigurationData({
					...configs,
					user: userData,
					rolePermissions: checkRolePermissions(),
				})
			);
		} else if (window.location.href.includes('type=fromEmail')) {
			const urlParameter =
				window.location.pathname.slice(Numbers.one) + '?type=fromEmail';
			window.location.replace(
				`${process.env.REACT_APP_REDIRECT_URI}${urlParameter}`
			);
		} else {
			window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
		}
	};

	const removeRedirectUrl = () => {
		if (localStorage['redirectURI'] && localStorage['redirectURI'] !== '') {
			const redirectURI = localStorage['redirectURI'];
			localStorage.removeItem('redirectURI');
			window.location.pathname = redirectURI;
		}
	};

	if (
		window.location.href.includes('type=fromEmail') &&
		userData?.idToken === ''
	) {
		// Not showing user survey page until user is authenticated
		return <div>{commonLabels.waitMsg}</div>;
	} else {
		// User is authenticated with valid token
		return (
			<ThemeProvider theme={stepMuiTheme}>
				<Header />
				<STEPContainer>
					<GenericConfirmationPopup
						open={showRedirectPopup}
						msgBody={commonLabels.sessionExpiredText}
						title={commonLabels.sessionExpiredTitle}
						rightBtnText={commonLabels.redirectButtonLabel}
						leftBtnText={''}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={() => {
							// Redirect to DTH
							localStorage.clear();
							// Token expired redirect to DTH
							window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
						}}
						leftBtnHandler={() => {}}
					></GenericConfirmationPopup>
					<RouterOutlet />
				</STEPContainer>
				<Footer />
			</ThemeProvider>
		);
	}
};

export default App;
export let showRedirectPopUp: () => void;
