import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { STEPTheme } from '../../utils/Theme';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/LeftArrow.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg';
import ButtonsGroup from '../../components/questionnaire-results/ButtonsGroup';
import BarChart from '../../components/questionnaire-results/BarChart';
import ChartSummary from '../../components/questionnaire-results/ChartSummary';
import DonutChart from '../../components/common/DonutChart';
import BarChartComponent from '../../components/questionnaire-results/StackedBarChart';
import ChartIndicator from '../../components/questionnaire-results/ChartIndicator';
import { Numbers } from '../../utils/Enum';
import { getTimeInLastRefreshFormat } from '../../utils/DateFormatting';
import StyledTooltip from '../../components/StyledTooltip';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	downloadResults,
	downloadResultsApiStatus,
	downloadResultsData,
	fetchQuestionnaireResultsData,
	questionnaireResultsData,
	setDownloadResultsApiStatus,
	setDownloadResultsData,
	setResultApiCompletionStatus,
} from '../../redux/QuestionnaireResultsSlice';
import {
	commonLabels,
	questionnaireResultsLabels,
} from '../../utils/CommonLabels';
import {
	getQuestionnaireResultByCategory,
	getOptionalResultsData,
	getCompletedByRegionData,
	getCompletedOverallData,
} from '../../utils/QuestionnaireResult';
import Loader from '../../components/Loader';
import {
	API_STATUS_CONSTANT,
	BASIC_CONSTANT,
} from '../../constants/StepConstants';
import Toaster from '../../components/common/Toaster';
import GenericModalPopup from '../../components/common/GenericModalPopup';

const StyledPrevButton = styled(IconButton)({
	'&:hover': {
		backgroundColor: 'transparent',
	},
	position: 'absolute',
	top: '110px',
	left: '40px',
	fill: 'transparent',
});

const GraphContainers = styled(Box)({
	display: 'flex',
	padding: '20px',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	borderRadius: '12px',
	background: '#fff',
	height: '228px',
});

const QuestionnaireResultContainer = styled(Box)({
	background: '#F9F8F7',
	minHeight: '500px',
	padding: '40px 100px',
});

const ResultHeadingContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'baseline',
	marginBottom: '20px',
});

const HeadingTextContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'baseline',
	gap: '20px',
});

const GeneralResultHeadingContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '10px',
});

const GeneralResultsContainer = styled(Box)({
	display: 'flex',
	width: '100%',
	gap: '3%',
	marginBottom: '20px',
});

const DownloadButtonContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'flex-end',
	margin: '20px 0px 0px 0px',
});

const QuestionnaireResult: React.FC<unknown> = () => {
	//TO-DO Uncomment below Line of Code when Results API is available.
	const [showLoader, setShowLoader] = React.useState(true);
	const [showToaster, setShowToaster] = React.useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const [disabledCompleteDownload, setDisabledCompleteDownload] =
		React.useState(false);
	const [dataDisplayType, setDataDisplayType] = useState(
		questionnaireResultsLabels.dataDisplayTypePercentage
	);
	const [selectedSector, setSelectedSector] = useState(
		questionnaireResultsLabels.allBtnTxt
	);
	const sectorBtnList = [
		questionnaireResultsLabels.allBtnTxt,
		questionnaireResultsLabels.medTechBtnTxt,
		questionnaireResultsLabels.pharmaBtnTxt,
		questionnaireResultsLabels.nonOperatingBtnTxt,
	];
	const dataDisplayTypeBtnList = [
		questionnaireResultsLabels.dataDisplayTypePercentage,
		questionnaireResultsLabels.dataDisplayTypeValues,
	];

	const navigate = useNavigate();
	const downloadResultsApiCurrentStatus = useAppSelector(
		downloadResultsApiStatus
	);
	const downloadResultsFileData = useAppSelector(downloadResultsData);
	const goToHome = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		navigate('/');
		navigate(0);
	};

	const dispatch = useAppDispatch();

	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	useEffect(() => {
		dispatch(
			fetchQuestionnaireResultsData({
				questionnaireId: `${questionnaireID}`,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireID]);

	const questionnaireResultData = useAppSelector(questionnaireResultsData);
	useEffect(() => {
		if (
			questionnaireResultData.resultApiCompletionStatus ===
				API_STATUS_CONSTANT.SUCCESS ||
			questionnaireResultData.resultApiCompletionStatus ===
				API_STATUS_CONSTANT.FAILED
		) {
			setShowLoader(false);
			dispatch(setResultApiCompletionStatus(API_STATUS_CONSTANT.IDLE));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireResultData.resultApiCompletionStatus]);
	// This Function will return the data for that particular sector which is stored in "currentSectorData".
	// This will be passed in below functions for dynamic data handling based on sector
	const currentSectorData = getQuestionnaireResultByCategory(
		questionnaireResultData?.summaryCollection,
		selectedSector
	);

	// This function will return fields required for donut chart
	const donutChartVal = getCompletedOverallData(
		currentSectorData?.overallCompletionSummary,
		dataDisplayType
	);

	// This function will return data required for plotting "Completed by Region Bar chart"
	const completedByRegionData = getCompletedByRegionData(
		currentSectorData?.regionalCompletionSummary,
		dataDisplayType
	);

	// This Function will return data required for optional Bar chart plotting
	const optionalCompletionData = getOptionalResultsData(
		currentSectorData?.optionalScopeSummaryByRegion,
		dataDisplayType
	);

	// Using data received from getOptionalResultsData function optionalBarChartData is prepared
	const optionalBarChartData = [
		{
			label: questionnaireResultsLabels.northAmericaTxt,
			topBarVal: optionalCompletionData.northAmericaOutOfScope,
			bottomBarVal: optionalCompletionData.northAmericaInScope,
			middleBarVal: optionalCompletionData.northAmericaNotAssessed,
		},
		{
			label: questionnaireResultsLabels.latinAmericaTxt,
			topBarVal: optionalCompletionData.latinAmericaOutOfScope,
			bottomBarVal: optionalCompletionData.latinAmericaInScope,
			middleBarVal: optionalCompletionData.latinAmericaNotAssessed,
		},
		{
			label: questionnaireResultsLabels.emeaTxt,
			topBarVal: optionalCompletionData.emeaOutOfScope,
			bottomBarVal: optionalCompletionData.emeaInScope,
			middleBarVal: optionalCompletionData.emeaNotAssessed,
		},
		{
			label: questionnaireResultsLabels.asiaPacificTxt,
			topBarVal: optionalCompletionData.asiaPacificOutOfScope,
			bottomBarVal: optionalCompletionData.asiaPacificInScope,
			middleBarVal: optionalCompletionData.asiaPacificNotAssessed,
		},
	];
	// Updates the currently selected sector
	const handleDataDisplayChange = (buttonName: string) => {
		setDataDisplayType(buttonName);
	};

	useEffect(() => {
		if (
			downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.SUCCESS &&
			downloadResultsFileData
		) {
			handleExportFile(downloadResultsFileData as Blob);
			dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
			setDisabledCompleteDownload(false);
		}
		if (downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.FAILED) {
			setDisabledCompleteDownload(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadResultsApiCurrentStatus, downloadResultsFileData]);

	const handleExportFile = (excelData: Blob) => {
		const blob = new Blob([excelData], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = `${commonLabels.result}_${questionnaireResultData?.questionnaireCode}.csv`;
		downloadLink.click();
		dispatch(setDownloadResultsData(null));
	};

	const handleCompleteDownload = () => {
		if (
			questionnaireResultData?.summaryCollection?.all?.overallCompletionSummary
				?.respondedInNumber > 0
		) {
			if (questionnaireID) {
				setShowToaster(true);
				setDisabledCompleteDownload(true);
				dispatch(downloadResults(questionnaireID));
			}
		} else {
			setShowPopup(true);
		}
	};
	const handleCloseToast = () => {
		setShowToaster(false);
	};
	const handleClosePopup = () => {
		setShowPopup(false);
	};
	return (
		<>
			<QuestionnaireResultContainer>
				<Toaster
					onClose={handleCloseToast}
					hideDuration={Numbers.twoThousand}
					message={questionnaireResultsLabels.downloadInProgress}
					severity={'info'}
					show={showToaster}
				></Toaster>
				{/* TO-DO Uncomment below Line of Code when Results API is available. */}
				<Loader isLoading={showLoader} />
				<ResultHeadingContainer>
					<HeadingTextContainer>
						<StyledPrevButton
							onClick={goToHome}
							aria-label="previous"
							data-testid="previous-btn"
						>
							<LeftArrowIcon />
						</StyledPrevButton>
						<Box>
							<Typography variant="h1">
								{questionnaireResultsLabels.questionnaireResults}
							</Typography>
							<Typography variant="body2" fontSize="14px" lineHeight="18px">
								{questionnaireResultsLabels.lastRefreshTxt}{' '}
								{getTimeInLastRefreshFormat(
									questionnaireResultData?.lastRefreshedDate
								)}
							</Typography>
						</Box>
						<Typography sx={{ fontSize: '14px', maxWidth: '450px' }}>
							{questionnaireResultsLabels.showingResultsFor}{' '}
							{questionnaireResultData?.questionnaireName}
						</Typography>
					</HeadingTextContainer>
					<ButtonsGroup
						onButtonSelect={(buttonName) => {
							setSelectedSector(buttonName);
						}}
						buttonsList={sectorBtnList}
					/>
				</ResultHeadingContainer>
				<GeneralResultHeadingContainer>
					<Typography variant="h2">
						{questionnaireResultsLabels.generalMetricsTxt} {selectedSector}
					</Typography>
					<ButtonsGroup
						onButtonSelect={handleDataDisplayChange}
						buttonsList={dataDisplayTypeBtnList}
					/>
				</GeneralResultHeadingContainer>
				<GeneralResultsContainer>
					<GraphContainers
						sx={{
							flexDirection: 'column',
							width: '35%',
						}}
					>
						<Typography variant="h2">
							{questionnaireResultsLabels.CompletedOverallTxt}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								height: '200px',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%',
								paddingRight: '45px',
							}}
						>
							{donutChartVal && (
								<DonutChart
									data={[
										{
											label: questionnaireResultsLabels.respondedTxt,
											value: donutChartVal.respondedVal,
											color: STEPTheme.colors.lightGreen,
										},
										{
											label: questionnaireResultsLabels.notRespondedTxt,
											value: donutChartVal.notRespondedVal,
											color: STEPTheme.colors.lightRed,
										},
									]}
									width={150}
									height={150}
									centerText={`${donutChartVal.respondedVal} ${
										dataDisplayType ===
										questionnaireResultsLabels.dataDisplayTypePercentage
											? '%'
											: ''
									}`}
								/>
							)}
							<ChartSummary
								data={[
									{
										label: questionnaireResultsLabels.respondedTxt,
										value: donutChartVal.respondedVal,
										color: STEPTheme.colors.lightGreen,
										outOfValue: donutChartVal.respondedVal,
										totalValue: donutChartVal.totalCount,
									},
									{
										label: questionnaireResultsLabels.notRespondedTxt,
										value: donutChartVal.notRespondedVal,
										color: STEPTheme.colors.lightRed,
										outOfValue: donutChartVal.notRespondedVal,
										totalValue: donutChartVal.totalCount,
									},
								]}
								chartType={dataDisplayType}
							/>
						</Box>
					</GraphContainers>
					<GraphContainers
						sx={{
							width: '67%',
							flexDirection: 'column',
						}}
					>
						<Typography variant="h2">
							{questionnaireResultsLabels.completedByRegionTxt}
						</Typography>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
							<BarChart
								data={[
									{
										label: questionnaireResultsLabels.northAmericaTxt,
										segments: [
											{
												value: completedByRegionData.completedInNorthAmerica,
												color: STEPTheme.colors.lightBlue,
												label: questionnaireResultsLabels.northAmericaTxt,
											},
										],
									},
									{
										label: questionnaireResultsLabels.latinAmericaTxt,
										segments: [
											{
												value: completedByRegionData.completedInLatinAmerica,
												color: STEPTheme.colors.latinBlue,
												label: questionnaireResultsLabels.latinAmericaTxt,
											},
										],
									},
									{
										label: questionnaireResultsLabels.emeaTxt,
										segments: [
											{
												value: completedByRegionData.completedInEMEA,
												color: STEPTheme.colors.emeaBlue,
												label: questionnaireResultsLabels.emeaTxt,
											},
										],
									},
									{
										label: questionnaireResultsLabels.asiaPacificTxt,
										segments: [
											{
												value: completedByRegionData.completedInAsiaPacific,
												color: STEPTheme.colors.asiaPacificGreen,
												label: questionnaireResultsLabels.asiaPacificTxt,
											},
										],
									},
								]}
								width={500}
								height={200}
								chartType={dataDisplayType}
								yMax={completedByRegionData.completedByRegionYMax}
								id="regional-bar-chart"
							/>
							<ChartSummary
								data={[
									{
										label: questionnaireResultsLabels.northAmericaTxt,
										value: completedByRegionData.completedInNorthAmerica,
										color: STEPTheme.colors.lightBlue,
										outOfValue: completedByRegionData.completedInNorthAmerica,
										totalValue: completedByRegionData.totalInNorthAmerica,
									},
									{
										label: questionnaireResultsLabels.latinAmericaTxt,
										value: completedByRegionData.completedInLatinAmerica,
										color: STEPTheme.colors.latinBlue,
										outOfValue: completedByRegionData.completedInLatinAmerica,
										totalValue: completedByRegionData.totalInLatinAmerica,
									},
									{
										label: questionnaireResultsLabels.emeaTxt,
										value: completedByRegionData.completedInEMEA,
										color: STEPTheme.colors.emeaBlue,
										outOfValue: completedByRegionData.completedInEMEA,
										totalValue: completedByRegionData.totalInEMEA,
									},
									{
										label: questionnaireResultsLabels.asiaPacificTxt,
										value: completedByRegionData.completedInAsiaPacific,
										color: STEPTheme.colors.asiaPacificGreen,
										outOfValue: completedByRegionData.completedInAsiaPacific,
										totalValue: completedByRegionData.totalInAsiaPacific,
									},
								]}
								chartType={dataDisplayType}
							/>
						</Box>
					</GraphContainers>
				</GeneralResultsContainer>
				{/* Rendering Optional graph only if targetAudience is not Self */}
				{questionnaireResultData?.targetAudience !== BASIC_CONSTANT.SELF && (
					<>
						<Typography variant="h2" sx={{ mb: '20px' }}>
							{`${questionnaireResultsLabels.optionalResultsMetricsTxt} ${selectedSector}`}
						</Typography>
						<GraphContainers
							sx={{
								justifyContent: 'center',
								alignItems: 'center',
								gap: '40px',
							}}
						>
							<BarChartComponent
								id="option-bar-chart"
								data={optionalBarChartData}
								width={600}
								height={200}
								chartType={dataDisplayType}
								bottomBarColor={STEPTheme.colors.lightGreen}
								middleBarColor={STEPTheme.colors.yellow}
								topBarColor={STEPTheme.colors.lightRed}
								yMax={
									dataDisplayType ===
									questionnaireResultsLabels.dataDisplayTypePercentage
										? Numbers.hundred
										: optionalCompletionData.yMaxOptionalResult
								}
							/>
							<Box>
								<ChartIndicator
									color={STEPTheme.colors.lightRed}
									label={questionnaireResultsLabels.outOfScopeTxt}
								/>
								<ChartIndicator
									color={STEPTheme.colors.yellow}
									label={questionnaireResultsLabels.notAssessedTxt}
								/>
								<ChartIndicator
									color={STEPTheme.colors.lightGreen}
									label={questionnaireResultsLabels.inScopeTxt}
								/>
							</Box>
						</GraphContainers>
					</>
				)}
				<StyledTooltip
					title={questionnaireResultsLabels.completeDownloadTooltip}
					placement="top-end"
				>
					<DownloadButtonContainer>
						<Button
							variant="outlined"
							color="secondary"
							disabled={disabledCompleteDownload}
							startIcon={<DownloadIcon />}
							onClick={handleCompleteDownload}
						>
							{questionnaireResultsLabels.completeDownload}
						</Button>
					</DownloadButtonContainer>
				</StyledTooltip>
			</QuestionnaireResultContainer>
			<GenericModalPopup
				openProp={showPopup}
				msgBody={questionnaireResultsLabels.reportDownloadErrorTitle}
				mainMsg=""
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={handleClosePopup}
				leftBtnHandler={handleClosePopup}
			/>
		</>
	);
};

export default QuestionnaireResult;
