import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	exportUsersAsList,
	exportUsersListExcelData,
	exportUsersListResponse,
	setExportUsersListExcelData,
	selectedAudienceCount,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { AUDIENCE_CONSTANT } from '../../constants/StepConstants';
import {
	commonLabels,
	questionnaireResultsLabels,
	uploadExcelLabels,
} from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';
import StyledTooltip from '../StyledTooltip';
import GenericConfirmationPopup from './GenericConfirmationPopup';
import Toaster from './Toaster';

interface ExportButtonProps {
	filename: string;
}

// It Takes two props data which is populated in ExcelSheet and fileName will be the name of downloaded Excel
const ExportExcelButton: React.FC<ExportButtonProps> = ({ filename }) => {
	const [showLoader, setLoaderOpen] = React.useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const dispatch = useAppDispatch();
	const exportUsersListRepnse = useAppSelector(exportUsersListResponse);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const exportUsersListExcelInfo = useAppSelector(exportUsersListExcelData);
	const selectedSurveyAudienceCount = useAppSelector(selectedAudienceCount);
	const modifiedFilterRequestPayload = {
		includeClause: surveyQuestionnaireInfo?.includeClause,
		excludeClause: surveyQuestionnaireInfo?.excludeClause,
	};

	useEffect(() => {
		if (
			exportUsersListRepnse === AUDIENCE_CONSTANT.SUCCESS &&
			exportUsersListExcelInfo
		) {
			handleExportClick(exportUsersListExcelInfo as Blob);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exportUsersListRepnse, exportUsersListExcelInfo]);

	const retrieveData = () => {
		if (
			selectedSurveyAudienceCount &&
			selectedSurveyAudienceCount > Numbers.oneLakh
		) {
			setShowPopup(true);
		} else {
			setLoaderOpen(true);
			dispatch(exportUsersAsList(modifiedFilterRequestPayload));
		}
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	const handleCloseToast = () => {
		setLoaderOpen(false);
	};

	const handleExportClick = (excelData: Blob) => {
		const blob = new Blob([excelData], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = `${filename}.csv`;
		downloadLink.click();
		dispatch(setExportUsersListExcelData(null));
	};

	return (
		<>
			<Toaster
				onClose={handleCloseToast}
				hideDuration={Numbers.twoThousand}
				message={questionnaireResultsLabels.downloadInProgress}
				severity={'info'}
				show={showLoader}
			></Toaster>
			<StyledTooltip
				title={questionsLabels.audienceExportTooltip}
				placement="top"
			>
				<Button
					variant="outlined"
					color="primary"
					onClick={retrieveData}
					disabled={showLoader || selectedSurveyAudienceCount === 0}
				>
					{questionsLabels.exportUserList}
				</Button>
			</StyledTooltip>
			<GenericConfirmationPopup
				open={showPopup}
				msgBody={uploadExcelLabels.audienceMaxRowsMessage}
				title=""
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={handleClosePopup}
				leftBtnHandler={handleClosePopup}
			/>
		</>
	);
};

export default ExportExcelButton;
