import axios from 'axios';
import { getDocumentCookie } from '../../utils/Helpers';
import { RESPONSE_CODE } from '../../constants/StepConstants';
import { showRedirectPopUp } from '../../App';
/* axiosIntercept
 * Set Base URL globaly
 * Set Header globaly
 */
const axiosIntercept = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosIntercept.interceptors.request.use(
	(config) => {
		const token = getDocumentCookie();
		if (config?.url === 'msal') {
			config.url = `${process.env.REACT_APP_MSAL_ENDPOINT}`;
		} else {
			config.url = config.baseURL + config.url;
		}

		/// Setting token in header
		if (token?.idToken) {
			return {
				...config,
				headers: {
					...(token !== null && { Authorization: `Bearer ${token.idToken}` }),
					...config.headers,
				},
			};
		} else {
			// token was not found- session left idle
			showRedirectPopUp();
		}
	},
	(error) => Promise.reject(error)
);

axiosIntercept.interceptors.response.use(
	(response) => response,
	(error) => {
		if (
			error?.response?.status === RESPONSE_CODE.HTTP_STATUS_UNAUTHORIZED ||
			error?.response?.data?.code?.toLowerCase()?.includes('expired')
		) {
			showRedirectPopUp();
		}
		return Promise.reject(error);
	}
);

export default axiosIntercept;
