/**
 * Method converts a date into the format  YYYY-MM-DD
 * @param aDate
 * @returns string
 */
const getFormattedDate = (aDate: Date | null) => {
	return aDate?.toISOString().split('T')[0];
};

const isFileTypeValidForExcel = (file: File): boolean => {
	return (
		file.type ===
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
		file.type === 'application/vnd.ms-excel'
	);
};

const getFormatedDate = (date: Date) => {
	return date?.toLocaleDateString('en-US', {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	});
};

export { getFormattedDate, isFileTypeValidForExcel, getFormatedDate };
