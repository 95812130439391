import { Box, Card } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Instruction from '../components/endUser/CW/Instruction';
import QuestionCW from '../components/endUser/QuestionCw';
import TableCW from '../components/endUser/TableCw';
import TableFilter from '../components/endUser/TableFilter';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	API_STATUS_CONSTANT,
	BASIC_CONSTANT,
} from '../constants/StepConstants';
import { configurationData } from '../redux/SurveySlice';
import { IType } from '../types/CommonTypes';
import {
	getQuestionnaireData,
	getQuestionnaireDetailsCWAPIStatus,
	getTodoMessage,
	questionnaireData,
} from '../redux/QuestionnarieUserSlice';
import GenericModalPopup from '../components/common/GenericModalPopup';

const CWListingPage = function CWListingPage() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isSelf, setIsSelf] = useState(false);
	const questionData = useAppSelector(questionnaireData);
	const configs = useAppSelector(configurationData);
	const { id: questionnaireId } = useParams();
	const questionnaireDetailsCWAPIStatus = useAppSelector(
		getQuestionnaireDetailsCWAPIStatus
	);
	const [isGenericModalOpen, setGenericModalOpen] = useState(false);
	const genericModalMsg = useAppSelector(getTodoMessage);
	useEffect(() => {
		if (configs?.user?.userEmailId) {
			const endUserReq: IType = {
				id: questionnaireId ? questionnaireId : '',
				email: configs?.user?.userEmailId,
			};
			dispatch(getQuestionnaireData(endUserReq));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (questionData?.data?.targetAudience === BASIC_CONSTANT.SELF) {
			setIsSelf(true);
		} else {
			setIsSelf(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionData, isSelf]);
	useEffect(() => {
		if (
			questionnaireDetailsCWAPIStatus ===
			API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
		) {
			setGenericModalOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireDetailsCWAPIStatus]);
	const goToHome = () => {
		navigate('/');
		navigate(0);
	};

	return (
		<>
			<div>
				<Instruction />
			</div>
			<Card
				sx={{
					width: '80%',
					marginLeft: '8%',
					marginBottom: '5%',
					borderRadius: '16px',
					padding: '3% 2% 3% 2%',
				}}
			>
				{!isSelf && (
					<Box>
						<TableFilter></TableFilter>
						<TableCW></TableCW>
						<QuestionCW></QuestionCW>
					</Box>
				)}
				{isSelf && (
					<Box>
						<QuestionCW></QuestionCW>
					</Box>
				)}
			</Card>
			{isGenericModalOpen && (
				<GenericModalPopup
					openProp={true}
					msgBody={genericModalMsg}
					mainMsg=""
					rightBtnText={'Go To Home'}
					leftBtnText={''}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={goToHome}
					leftBtnHandler={() => {}}
					hideCloseBtn={true}
				/>
			)}
		</>
	);
};
export default CWListingPage;
