import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import SurveyHorizontalStepper from '../../components/SurveyHorizontalStepper';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/LeftArrow.svg';
import { useAppSelector } from '../../app/Hooks';
import {
	questionnaireStatus,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { QUE_JOURNEY_STEPS } from '../../utils/Enum';
import { SURVEY_STATUS } from '../../constants/StepConstants';
import CreateSurveyPreview from './CreateSurveyPreview';
import CreateSurveyNotifications from './CreateSurveyNotifications';
import CreateSurveyDetails from './CreateSurveyDetails';
import CreateSurveyAudiences from './CreateSurveyAudiences';
import CreateSurveyQuestions from './CreateSurveyQuestions';

const useStyles = makeStyles({
	disbaledNav: {
		opacity: '0.2',
		pointerEvents: 'none',
	},
});
const StyledPrevButton = styled(IconButton)({
	'&:hover': {
		backgroundColor: 'transparent',
	},
	position: 'absolute',
	top: '90px',
	left: '70px',
});

const CreateSurvey: React.FC = () => {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(
		QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS
	);
	const navigate = useNavigate();
	const questionnaireDetailsStep = QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS;
	const createSurveyAudiencesStep = 2;
	const createSurveyQuestionsStep = 3;
	const createSurveyPreviewStep = 4;
	const createSurveyNotificationsStep = 5;
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const questionnaireStatusInfo = useAppSelector(questionnaireStatus);
	const [isStatusActive, setIsStatusActive] = useState(false);
	useEffect(() => {
		if (
			questionnaireStatusInfo === SURVEY_STATUS.ACTIVE ||
			questionnaireStatusInfo === SURVEY_STATUS.PUBLISHED ||
			questionnaireStatusInfo === SURVEY_STATUS.COMPLETED
		) {
			setIsStatusActive(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireStatusInfo]);
	useEffect(() => {
		if (
			surveyQuestionnaireInfo?.currentStep &&
			surveyQuestionnaireInfo?.currentStep !==
				QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS
		) {
			setActiveStep(surveyQuestionnaireInfo.currentStep);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Move forwards to the next step for the create-questionnaire form
	 *
	 * @method
	 * @param - Empty.
	 * @returns void
	 */
	const handleNext = (): void => {
		setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
	};

	const handleBack = (): void => {
		if (activeStep > 1) {
			setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
		} else {
			navigate('/');
			navigate(0);
		}
	};

	const backToDetailsPage = (): void => {
		if (activeStep === createSurveyQuestionsStep) {
			setActiveStep(questionnaireDetailsStep);
		}
	};

	return (
		<div>
			<StyledPrevButton
				aria-label="previous"
				data-testid="previous-btn"
				className={isStatusActive ? classes.disbaledNav : ''}
				onClick={handleBack}
			>
				<LeftArrowIcon />
			</StyledPrevButton>
			<SurveyHorizontalStepper
				activeStep={activeStep}
				setActiveStep={(outerActiveStep) =>
					((innerActiveStep: number): void => {
						setActiveStep(innerActiveStep);
					})(outerActiveStep)
				}
			/>

			{activeStep === questionnaireDetailsStep && (
				<CreateSurveyDetails handleNext={handleNext} />
			)}
			{activeStep === createSurveyAudiencesStep && (
				<CreateSurveyAudiences handleNext={handleNext} />
			)}
			{activeStep === createSurveyQuestionsStep && (
				<CreateSurveyQuestions
					handleNext={handleNext}
					handleBack={handleBack}
					backToDetailsPage={backToDetailsPage}
					activeStep={QUE_JOURNEY_STEPS.STEP_QUESTIONS}
				/>
			)}
			{activeStep === createSurveyPreviewStep && (
				<CreateSurveyPreview handleNext={handleNext} />
			)}
			{activeStep === createSurveyNotificationsStep && (
				<CreateSurveyNotifications handleNext={handleNext} />
			)}
		</div>
	);
};

export default CreateSurvey;
