import React, { useEffect } from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@material-ui/core';
import { commonLabels } from '../../../utils/CommonLabels';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { STEPTheme } from '../../../utils/Theme';
import {
	selectedFilterRequestPayloadData,
	surveyQuestionnaireData,
	updateSurveyQuestionnaire,
} from '../../../redux/SurveySlice';
import { IAppliedFilterArray } from '../../../types/CommonTypes';
import { QUE_JOURNEY_STEPS } from '../../../utils/Enum';
import {
	initialExcludedFilterValue,
	initialIncludedFilterValue,
} from '../../../utils/SurveyAudience';
import { questionsLabels } from '../../../utils/QuestionsLabels';
import SelectedUserCriteria from './SelectedUserCriteria';

const useStyles = makeStyles({
	actionBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '30px',
	},
	plusIcon: {
		width: '0.8em',
	},
	mainContainer: {
		backgroundColor: STEPTheme.colors.white,
		width: '100%',
		height: '100%',
		paddingTop: '20px',
	},
	selectUserCriteria: {
		marginLeft: '11%',
		marginTop: '10px',
		backgroundColor: STEPTheme.colors.white,
		paddingTop: '5px',
	},
	selectedUserCont: {
		marginTop: '15px',
		height: '100%',
	},
});

const SaveProceedContainer = styled(Box)({
	marginRight: '50px',
	marginBottom: '20px',
});

const TotalAudienceCountText = styled(Typography)({
	color: '#004685',
	marginLeft: '10%',
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '18px',
	lineHeight: '24px',
	fontWeight: 700,
});

const ActionButton = styled(Button)({
	width: 'auto',
	height: '32px',
	marginLeft: '10px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.primary,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.primary}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
	},
});

const SelectedUserCriteriaCont = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '16px',
	fontWeight: '700',
	lineHeight: '12px',
	letterSpacing: '0px',
	marginLeft: '10%',
	marginTop: '40px',
});

const PreviewQuestionnaireFooter: React.FC<{ handleNext: () => void }> = ({
	handleNext,
}) => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const filterRequestPayload = useAppSelector(
		selectedFilterRequestPayloadData
	) as IAppliedFilterArray;

	let showIncludedFiltersCriteria = false;
	let showExcludedFiltersCriteria = false;
	if (
		!surveyQuestionnaireInfo?.includeClause?.endsWith('xlsx') &&
		(filterRequestPayload.includeFilters !==
			JSON.stringify(initialIncludedFilterValue) ||
			surveyQuestionnaireInfo.surveyIncludedAudienceFilter !==
				JSON.stringify(initialIncludedFilterValue))
	) {
		showIncludedFiltersCriteria = true;
	}

	if (
		!surveyQuestionnaireInfo?.includeClause?.endsWith('xlsx') &&
		(filterRequestPayload.excludeFilters !==
			JSON.stringify(initialExcludedFilterValue) ||
			surveyQuestionnaireInfo.surveyExcludedAudienceFilter !==
				JSON.stringify(initialExcludedFilterValue))
	) {
		showExcludedFiltersCriteria = true;
	}

	const [isNextPreviewClicked, setNextPreviewClicked] = React.useState(false);
	const saveNextPreview = () => {
		setNextPreviewClicked(true);
		dispatch(
			updateSurveyQuestionnaire({
				...surveyQuestionnaireInfo,
				currentStep: QUE_JOURNEY_STEPS.STEP_PREVIEW,
			})
		);
	};
	useEffect(() => {
		if (
			isNextPreviewClicked &&
			surveyQuestionnaireInfo.currentStep === QUE_JOURNEY_STEPS.STEP_PREVIEW
		) {
			setNextPreviewClicked(false);
			handleNext();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNextPreviewClicked, surveyQuestionnaireInfo.currentStep]);

	return (
		<Box className={classes.mainContainer} data-testid="preview-footer">
			<TotalAudienceCountText variant="h2">
				{questionsLabels.totalAudience} :{' '}
				{surveyQuestionnaireInfo.audienceCount}
			</TotalAudienceCountText>
			{showIncludedFiltersCriteria && (
				<Box className={classes.selectedUserCont}>
					<SelectedUserCriteriaCont>
						{commonLabels.includedUserCriteria}
					</SelectedUserCriteriaCont>
					<Box className={classes.selectUserCriteria}>
						<SelectedUserCriteria
							selectedFilterType={'INCLUDE'}
							data-testid="selected-user-criteria"
						></SelectedUserCriteria>
					</Box>
				</Box>
			)}
			{showExcludedFiltersCriteria && (
				<Box className={classes.selectedUserCont}>
					<SelectedUserCriteriaCont>
						{commonLabels.excludedUserCriteria}
					</SelectedUserCriteriaCont>
					<Box className={classes.selectUserCriteria}>
						<SelectedUserCriteria
							selectedFilterType={'EXCLUDE'}
							data-testid="selected-user-criteria"
						></SelectedUserCriteria>
					</Box>
				</Box>
			)}
			<Box className={classes.actionBox} data-testid="button-save">
				<SaveProceedContainer>
					<ActionButton onClick={() => saveNextPreview()}>
						{commonLabels.saveProceed}
						<ArrowForward className={classes.plusIcon} />
					</ActionButton>
				</SaveProceedContainer>
			</Box>
		</Box>
	);
};

export default PreviewQuestionnaireFooter;
