import {
	Card,
	CardContent,
	Typography,
	CardActions,
	styled,
	Button,
	Box,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledTooltip from '../StyledTooltip';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	configurationData,
	setIsLastScreenEndUser,
} from '../../redux/SurveySlice';
import { ReactComponent as EndUserLanding } from '../../assets/images/EndUserLanding.svg';
import {
	EndUserListingPageLabels,
	questionnaireResultsLabels,
} from '../../utils/CommonLabels';
import { STEPTheme } from '../../utils/Theme';
import { ReactComponent as ArrowBlack } from '../../assets/images/Arrow-Black.svg';
import { IType } from '../../types/CommonTypes';
import {
	questionnaireData,
	getQuestionnaireData,
	getQuestionnaireDetailsCWAPIStatus,
	getTodoMessage,
} from '../../redux/QuestionnarieUserSlice';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import GenericModalPopup from '../common/GenericModalPopup';
const StartQuetionaireButton = styled(Button)({
	fontFamily: 'Johnson Display',
	width: '160px',
	height: '60px',
	color: '#EB1700',
	background: 'white',
	padding: '10px 20px',
	borderRadius: '8px',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: 'bold',
	fontSize: '15px',
	'&:hover': {
		backgroundColor: 'white',
		color: '#EB1700',
	},
});

const QuestionnaireName = styled(Box)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	color: STEPTheme.colors.white,
	display: 'flex',
	alignItems: 'center',
});
const IconStyle = styled(ArrowBlack)({
	marginRight: '20px',
	cursor: 'pointer',
});
const QuestionnaireNameBox = styled(Box)({
	backgroundColor: STEPTheme.colors.primary,
	padding: '65px',
	overflow: 'hidden',
});
const LandingScreen = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id: questionnaireId } = useParams();
	const configs = useAppSelector(configurationData);
	const QuestionnaireInfo = useAppSelector(questionnaireData);
	const [questionnaireName, setQuestionaaireName] = useState('');
	const [questionaaireDescription, setQuestionaaireDescription] = useState('');
	const questionnaireDetailsCWAPIStatus = useAppSelector(
		getQuestionnaireDetailsCWAPIStatus
	);
	const [isGenericModalOpen, setGenericModalOpen] = useState(false);
	const genericModalMsg = useAppSelector(getTodoMessage);
	useEffect(() => {
		if (configs?.user?.userEmailId) {
			const endUserReq: IType = {
				id: questionnaireId ? questionnaireId : '',
				email: configs?.user?.userEmailId,
			};
			dispatch(getQuestionnaireData(endUserReq));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configs?.user?.userEmailId]);
	useEffect(() => {
		setQuestionaaireName(QuestionnaireInfo?.data?.questionnaireName);
		setQuestionaaireDescription(
			QuestionnaireInfo?.data?.questionnaireDescription
		);
	}, [QuestionnaireInfo]);
	useEffect(() => {
		if (
			questionnaireDetailsCWAPIStatus ===
			API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
		) {
			setGenericModalOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireDetailsCWAPIStatus]);
	const goToHome = () => {
		navigate('/');
		navigate(0);
	};

	return (
		<Box>
			<QuestionnaireNameBox>
				<QuestionnaireName>
					<StyledTooltip
						title={EndUserListingPageLabels.backArrowTooltip}
						placement="top"
					>
						<IconStyle
							onClick={() => {
								dispatch(setIsLastScreenEndUser(true));
								navigate('/');
							}}
						/>
					</StyledTooltip>
					{questionnaireName}
				</QuestionnaireName>
			</QuestionnaireNameBox>
			<Card
				raised={true}
				sx={{
					marginLeft: '30%',
					marginBottom: '20%',
					marginTop: '-30px',
					borderRadius: '16px',
					padding: '20px',
					width: '40%',
					height: '380px',
					justifyContent: 'center',
					boxShadow: '0px 4px 16px 0px #0000000F',
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<EndUserLanding />
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<CardContent>
						<Typography
							sx={{
								maxHeight: '70px',
								overflowY: 'auto',
							}}
							variant="body1"
							color="text.secondary"
						>
							{questionaaireDescription}
						</Typography>
					</CardContent>
					<CardActions>
						<StartQuetionaireButton
							variant="contained"
							color="primary"
							onClick={() => {
								navigate(`/cwuser/${questionnaireId}`);
							}}
						>
							{questionnaireResultsLabels.startQuestionnaire}
						</StartQuetionaireButton>
					</CardActions>
				</div>
			</Card>
			{isGenericModalOpen && (
				<GenericModalPopup
					openProp={true}
					msgBody={genericModalMsg}
					mainMsg=""
					rightBtnText={'Go To Home'}
					leftBtnText={''}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={goToHome}
					leftBtnHandler={() => {}}
					hideCloseBtn={true}
				/>
			)}
		</Box>
	);
};

export default LandingScreen;
