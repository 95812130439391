import React from 'react';
import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { STEPTheme } from '../utils/Theme';
import STEP_LOGO from '../assets/images/STEP-Logo-other.svg';
import NavBar from './Navbar';
import UserMenu from './UserMenu';

const StyledLogo = styled('img')({
	marginRight: '10px',
});

const StyledAppBar = styled(AppBar)({
	background: STEPTheme.colors.white,
	width: 'auto',
	borderRadius: '12px',
	margin: '8px',
	boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
});

const StyledDiv = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	height: '64px',
	justifyContent: 'space-between',
	[theme.breakpoints.up('sm')]: {
		padding: '0px 10px',
	},
	[theme.breakpoints.up('md')]: {
		padding: '0px 42px',
	},
	[theme.breakpoints.up('lg')]: {
		padding: '0px 64px',
	},
	[theme.breakpoints.up('xl')]: {
		padding: '0px 64px',
	},
}));

const LogoTabContainer = styled('div')({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
});

const UserMenuContainer = styled('div')({
	flexGrow: 0,
	display: 'flex',
	justifyContent: 'flex-end',
	minWidth: '350px',
});

const Header: React.FC<unknown> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const goToHome = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		navigate('/');
		navigate(0);
	};

	return (
		<>
			<StyledAppBar className="step-app-bar-header" position="sticky">
				<StyledDiv>
					<LogoTabContainer>
						<Link to="/" onClick={goToHome}>
							<StyledLogo
								src={STEP_LOGO}
								className="STEP-logo"
								title="Self-service Training Efficiency Profile"
								alt="STEP"
								data-testid="STEP_LOGO"
							/>
						</Link>
					</LogoTabContainer>
					{pathname === '/' && <NavBar />}
					<UserMenuContainer>
						<UserMenu data-testid="userName"></UserMenu>
					</UserMenuContainer>
				</StyledDiv>
			</StyledAppBar>
		</>
	);
};

export default Header;
