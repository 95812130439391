import { Numbers } from './Enum';

export const formatDate = (date: Date | null): string => {
	if (!date) {
		return '';
	}

	const day = date.getDate().toString().padStart(Numbers.two, '0');
	const month = date.toLocaleDateString('en-US', { month: 'short' });
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
};

export const dueDate = (
	startDate: Date | null,
	otherDueDate: Date | null,
	duration?: number
): string => {
	if (startDate !== null && duration && duration !== 0) {
		const getDueDate = new Date(startDate);
		getDueDate.setDate(getDueDate.getDate() + duration);
		return formatDate(getDueDate);
	}
	if (otherDueDate !== null) {
		return formatDate(otherDueDate);
	}
	return '';
};

// This function converts timestamp into '22 Mar 2024, 06:57:04 AM EST' Format
export const getTimeInLastRefreshFormat = (refreshTimeStamp: string) => {
	const d = new Date(refreshTimeStamp);
	const lastRefreshedDate = d.toLocaleString('default', { day: '2-digit' });
	const lastRefreshedMonth = d.toLocaleDateString('en-US', { month: 'short' });
	const lastRefreshYear = d.getFullYear();
	const lastRefreshTime = d.toLocaleTimeString().split(':');

	// Add zero if hour, minute or second is in single digit
	const formattedLastRefreshTime = lastRefreshTime
		.map((component) => (component.length === 1 ? `0${component}` : component))
		.join(':');

	return `${lastRefreshedDate} ${lastRefreshedMonth} ${lastRefreshYear}, ${formattedLastRefreshTime} EST`;
};
