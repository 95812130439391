import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../app/Store';
import {
	DownloadResults,
	QuestionnaireResultsData,
} from '../middleware/QuestionnaireResultsService';
import {
	IQuestionnaireResultsData,
	IQuestionnaireResultsSummaryCollection,
	ISectorSummaryCollection,
	IOptionalScopeSummaryByRegionCollection,
	ICompletionSummaryCollection,
	IRegionalSummaryCollection,
	IOptionalScopeSummaryCollection,
	QuestionnaireResultsResponse,
} from '../types/QuestionnaireResultsType';
import { API_STATUS_CONSTANT, RESPONSE_CODE } from '../constants/StepConstants';
const initialOptionalScopeSummaryByRegionCollectionState: IOptionalScopeSummaryByRegionCollection =
	{
		inScopeInValue: 0,
		notInScopeInValue: 0,
		inScopeInPercentage: 0,
		notInScopeInPercentage: 0,
		notAssessedInValue: 0,
		notAssessedInPercentage: 0,
		totalCount: 0,
	};

const initialCompletionSummaryCollectionState: ICompletionSummaryCollection = {
	totalCount: 0,
	respondedInNumber: 0,
	notRespondedInNumber: 0,
	respondedInPercentage: 0,
	notRespondedInPercentage: 0,
};
const initialRegionalSummaryCollectionState: IRegionalSummaryCollection = {
	asia_pacific: initialCompletionSummaryCollectionState,
	emea: initialCompletionSummaryCollectionState,
	latin_america: initialCompletionSummaryCollectionState,
	north_america: initialCompletionSummaryCollectionState,
};
const initialOptionalScopeSummaryCollectionState: IOptionalScopeSummaryCollection =
	{
		asia_pacific: initialOptionalScopeSummaryByRegionCollectionState,
		emea: initialOptionalScopeSummaryByRegionCollectionState,
		latin_america: initialOptionalScopeSummaryByRegionCollectionState,
		north_america: initialOptionalScopeSummaryByRegionCollectionState,
	};

const initialSummaryCollectionState: ISectorSummaryCollection = {
	overallCompletionSummary: initialCompletionSummaryCollectionState,
	regionalCompletionSummary: initialRegionalSummaryCollectionState,
	optionalScopeSummaryByRegion: initialOptionalScopeSummaryCollectionState,
};

const initialQuestionnaireResultsSummaryCollectionState: IQuestionnaireResultsSummaryCollection =
	{
		all: initialSummaryCollectionState,
		medical_devices: initialSummaryCollectionState,
		pharmaceuticals: initialSummaryCollectionState,
		corporate: initialSummaryCollectionState,
		consumer: initialSummaryCollectionState,
	};

const initialState: IQuestionnaireResultsData = {
	questionnaireName: '',
	questionnaireCode: '',
	summaryCollection: initialQuestionnaireResultsSummaryCollectionState,
	lastRefreshedDate: '',
	totalCount: 0,
	resultApiCompletionStatus: 'idle',
	downloadResultsData: null,
	downloadResultsApiStatus: 'idle',
	targetAudience: '',
};

export const fetchQuestionnaireResultsData = createAsyncThunk(
	'questionnaireResults',
	async (
		questionnaireResultsReq: {
			questionnaireId: string;
			action?: string;
		},
		{ dispatch }
	) => {
		QuestionnaireResultsData(questionnaireResultsReq)
			.then((response: AxiosResponse<QuestionnaireResultsResponse>) => {
				if (
					(response?.status === RESPONSE_CODE.HTTP_STATUS_OK ||
						response?.status === RESPONSE_CODE.HTTP_STATUS_NO_CONTENT) &&
					response?.data
				) {
					dispatch(setQuestionnaireResultsTotalCount(response?.data?.data));
				} else {
					dispatch(setResultApiCompletionStatus(API_STATUS_CONSTANT.FAILED));
				}
			})
			.catch(() => {
				dispatch(setResultApiCompletionStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);

export const downloadResults = createAsyncThunk(
	'questionnaireResults/downloadResults',
	async (id: string, { dispatch }) => {
		DownloadResults(id)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setDownloadResultsData(response?.data));
					dispatch(setDownloadResultsApiStatus('success'));
				} else {
					dispatch(setDownloadResultsApiStatus('failed'));
				}
			})
			.catch(() => {
				dispatch(setDownloadResultsApiStatus('failed'));
			});
	}
);

export const questionnaireResultsSlice = createSlice({
	name: 'questionnaireResults',
	initialState,
	reducers: {
		setQuestionnaireResultsTotalCount: (
			state,
			action: PayloadAction<IQuestionnaireResultsData>
		) => {
			return Object.assign({}, state, {
				...action.payload,
				resultApiCompletionStatus: API_STATUS_CONSTANT.SUCCESS,
			});
		},
		setDownloadResultsData: (state, action: PayloadAction<unknown>) => {
			state.downloadResultsData = action.payload;
		},
		setDownloadResultsApiStatus: (state, action: PayloadAction<string>) => {
			state.downloadResultsApiStatus = action.payload;
		},
		setResultApiCompletionStatus: (state, action) => {
			state.resultApiCompletionStatus = action.payload;
		},
	},
});

export const questionnaireResultsData = (state: RootState) =>
	state.questionnaireResults;
export const questionnaireResultsLastRefresh = (state: RootState) =>
	state.questionnaireResults.lastRefreshedDate;
export const downloadResultsData = (state: RootState) =>
	state.questionnaireResults.downloadResultsData;
export const downloadResultsApiStatus = (state: RootState) =>
	state.questionnaireResults.downloadResultsApiStatus;
export const {
	setQuestionnaireResultsTotalCount,
	setResultApiCompletionStatus,
	setDownloadResultsData,
	setDownloadResultsApiStatus,
} = questionnaireResultsSlice.actions;
export default questionnaireResultsSlice.reducer;
