import React from 'react';
import { styled } from '@mui/material/styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Typography,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useDispatch } from 'react-redux';
import { STEPTheme } from '../../utils/Theme';
import { DialogPropsDeletePopUp } from '../../types/CommonTypes';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { commonLabels } from '../../utils/CommonLabels';
import {
	deleteQuestion,
	setFileName,
} from '../../redux/QuestionnaireSurveySlice';

const ActionButton = styled(Button)({
	width: '200px',
	height: '32px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.text,
	padding: '2px 10px',
	borderRadius: '8px',
	whiteSpace: 'nowrap',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.text}`,
	wordWrap: 'break-word',
	marginRight: '8px',
	'&:hover': {
		backgroundColor: STEPTheme.colors.text,
		borderColor: STEPTheme.colors.white,
	},
});

const TitleContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	whiteSpace: 'nowrap',
});

const CloseIcon = styled(CloseOutlinedIcon)({
	marginLeft: '290px',
	cursor: 'pointer',
});

const QuestionDeletePopup: React.FC<DialogPropsDeletePopUp> = ({
	open,
	onClose,
	index,
}) => {
	const dispatch = useDispatch();
	const handleClose = (e: string) => {
		if (e === 'yes') {
			dispatch(deleteQuestion(index));
			dispatch(setFileName({ index: index, name: '' }));
		}
		onClose(e);
	};
	return (
		<Dialog
			data-testid="question-delete-popup"
			open={open}
			onClose={handleClose}
			PaperProps={{ style: { borderRadius: 10 } }}
		>
			<DialogTitle>
				<TitleContainer>
					<Typography variant="h1">{questionsLabels.deleteQueTitle}</Typography>
					<CloseIcon onClick={() => handleClose('close')} />
				</TitleContainer>
			</DialogTitle>
			<DialogActions>
				<ActionButton
					variant="outlined"
					size="small"
					onClick={() => handleClose('yes')}
				>
					{commonLabels.yes}
				</ActionButton>
				<ActionButton variant="outlined" onClick={() => handleClose('close')}>
					{commonLabels.no}
				</ActionButton>
			</DialogActions>
		</Dialog>
	);
};
export default QuestionDeletePopup;
