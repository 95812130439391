import {
	IQuestionnaireResultsSummaryCollection,
	IOptionalScopeSummaryCollection,
	IRegionalSummaryCollection,
	ICompletionSummaryCollection,
} from '../types/QuestionnaireResultsType';

import { Numbers } from './Enum';

export const getQuestionnaireResultByCategory = (
	data: IQuestionnaireResultsSummaryCollection,
	sector: string
) => {
	if (sector === 'All') {
		return data?.all;
	} else if (sector === 'Med Tech') {
		return data?.medical_devices;
	} else if (sector === 'Pharma') {
		return data?.pharmaceuticals;
	} else if (sector === 'Non-Operating') {
		return data?.corporate;
	} else {
		return data?.all;
	}
};

export const getOptionalResultsData = (
	optionalCompletionSummary: IOptionalScopeSummaryCollection,
	dataDisplayType: string
) => {
	let yMaxOptionalResult = 0;
	let asiaPacificInScope = 0;
	let asiaPacificNotAssessed = 0;
	let asiaPacificOutOfScope = 0;
	let emeaInScope = 0;
	let emeaNotAssessed = 0;
	let emeaOutOfScope = 0;
	let northAmericaInScope = 0;
	let latinAmericaInScope = 0;
	let northAmericaNotAssessed = 0;
	let latinAmericaNotAssessed = 0;
	let northAmericaOutOfScope = 0;
	let latinAmericaOutOfScope = 0;

	if (dataDisplayType === 'Percentage') {
		yMaxOptionalResult = Numbers.hundred;
		asiaPacificInScope =
			optionalCompletionSummary?.asia_pacific?.inScopeInPercentage || 0;
		asiaPacificNotAssessed =
			optionalCompletionSummary?.asia_pacific?.notAssessedInPercentage || 0;
		asiaPacificOutOfScope =
			optionalCompletionSummary?.asia_pacific?.notInScopeInPercentage || 0;
		emeaInScope = optionalCompletionSummary?.emea?.inScopeInPercentage || 0;
		emeaNotAssessed =
			optionalCompletionSummary?.emea?.notAssessedInPercentage || 0;
		emeaOutOfScope =
			optionalCompletionSummary?.emea?.notInScopeInPercentage || 0;
		northAmericaInScope =
			optionalCompletionSummary?.north_america?.inScopeInPercentage || 0;
		latinAmericaInScope =
			optionalCompletionSummary?.latin_america?.inScopeInPercentage || 0;
		northAmericaNotAssessed =
			optionalCompletionSummary?.north_america?.notAssessedInPercentage || 0;
		latinAmericaNotAssessed =
			optionalCompletionSummary?.latin_america?.notAssessedInPercentage || 0;
		northAmericaOutOfScope =
			optionalCompletionSummary?.north_america?.notInScopeInPercentage || 0;
		latinAmericaOutOfScope =
			optionalCompletionSummary?.latin_america?.notInScopeInPercentage || 0;
	} else {
		yMaxOptionalResult =
			(optionalCompletionSummary?.emea?.totalCount || 0) +
			(optionalCompletionSummary?.asia_pacific?.totalCount || 0) +
			(optionalCompletionSummary?.north_america?.totalCount || 0) +
			Numbers.sixty;
		asiaPacificInScope =
			optionalCompletionSummary?.asia_pacific?.inScopeInValue || 0;
		asiaPacificNotAssessed =
			optionalCompletionSummary?.asia_pacific?.notAssessedInValue || 0;
		asiaPacificOutOfScope =
			optionalCompletionSummary?.asia_pacific?.notInScopeInValue || 0;
		emeaInScope = optionalCompletionSummary?.emea?.inScopeInValue || 0;
		emeaNotAssessed = optionalCompletionSummary?.emea?.notAssessedInValue || 0;
		emeaOutOfScope = optionalCompletionSummary?.emea?.notInScopeInValue || 0;
		northAmericaInScope =
			optionalCompletionSummary?.north_america?.inScopeInValue || 0;
		latinAmericaInScope =
			optionalCompletionSummary?.latin_america?.inScopeInValue || 0;
		northAmericaNotAssessed =
			optionalCompletionSummary?.north_america?.notAssessedInValue || 0;
		latinAmericaNotAssessed =
			optionalCompletionSummary?.latin_america?.notAssessedInValue || 0;
		northAmericaOutOfScope =
			optionalCompletionSummary?.north_america?.notInScopeInValue || 0;
		latinAmericaOutOfScope =
			optionalCompletionSummary?.latin_america?.notInScopeInValue || 0;
	}
	return {
		yMaxOptionalResult,
		asiaPacificInScope,
		asiaPacificNotAssessed,
		asiaPacificOutOfScope,
		emeaInScope,
		emeaNotAssessed,
		emeaOutOfScope,
		northAmericaInScope,
		latinAmericaInScope,
		northAmericaNotAssessed,
		latinAmericaNotAssessed,
		northAmericaOutOfScope,
		latinAmericaOutOfScope,
	};
};

export const getCompletedByRegionData = (
	regionalCompletionSummary: IRegionalSummaryCollection,
	dataDisplayType: string
) => {
	let completedByRegionYMax = 0;
	let totalCount = 0;
	let totalInNorthAmerica = 0;
	let totalInAsiaPacific = 0;
	let totalInEMEA = 0;
	let totalInLatinAmerica = 0;
	let completedInNorthAmerica = 0;
	let completedInLatinAmerica = 0;
	let completedInAsiaPacific = 0;
	let completedInEMEA = 0;

	if (dataDisplayType === 'Percentage') {
		completedByRegionYMax = Numbers.hundred;
		totalCount = regionalCompletionSummary?.emea?.totalCount || 0;
		completedInAsiaPacific =
			regionalCompletionSummary?.asia_pacific?.respondedInPercentage || 0;
		completedInEMEA =
			regionalCompletionSummary?.emea?.respondedInPercentage || 0;
		completedInNorthAmerica =
			regionalCompletionSummary?.north_america?.respondedInPercentage || 0;
		completedInLatinAmerica =
			regionalCompletionSummary?.latin_america?.respondedInPercentage || 0;
		totalInAsiaPacific =
			regionalCompletionSummary?.asia_pacific?.notRespondedInPercentage || 0;
		totalInEMEA =
			regionalCompletionSummary?.emea?.notRespondedInPercentage || 0;
		totalInNorthAmerica =
			regionalCompletionSummary?.north_america?.notRespondedInPercentage || 0;
		totalInLatinAmerica =
			regionalCompletionSummary?.latin_america?.notRespondedInPercentage || 0;
	} else {
		completedByRegionYMax =
			(regionalCompletionSummary?.emea?.totalCount || 0) +
			(regionalCompletionSummary?.asia_pacific?.totalCount || 0) +
			(regionalCompletionSummary?.north_america?.totalCount || 0) +
			Numbers.sixty;
		totalCount = regionalCompletionSummary?.emea?.totalCount || 0;
		completedInAsiaPacific =
			regionalCompletionSummary?.asia_pacific?.respondedInNumber || 0;
		completedInEMEA = regionalCompletionSummary?.emea?.respondedInNumber || 0;
		completedInNorthAmerica =
			regionalCompletionSummary?.north_america?.respondedInNumber || 0;
		completedInLatinAmerica =
			regionalCompletionSummary?.latin_america?.respondedInNumber || 0;
		totalInAsiaPacific =
			regionalCompletionSummary?.asia_pacific?.totalCount || 0;
		totalInEMEA = regionalCompletionSummary?.emea?.totalCount || 0;
		totalInNorthAmerica =
			regionalCompletionSummary?.north_america?.totalCount || 0;
		totalInLatinAmerica =
			regionalCompletionSummary?.latin_america?.totalCount || 0;
	}
	return {
		completedByRegionYMax,
		totalCount,
		completedInAsiaPacific,
		completedInEMEA,
		completedInNorthAmerica,
		completedInLatinAmerica,
		totalInAsiaPacific,
		totalInEMEA,
		totalInNorthAmerica,
		totalInLatinAmerica,
	};
};

export const getCompletedOverallData = (
	overallCompletionSummary: ICompletionSummaryCollection,
	dataDisplayType: string
) => {
	let respondedVal = 0;
	let notRespondedVal = 0;
	const totalCount = overallCompletionSummary?.totalCount || 0;
	if (dataDisplayType === 'Percentage') {
		respondedVal = overallCompletionSummary?.respondedInPercentage || 0;
		notRespondedVal = overallCompletionSummary?.notRespondedInPercentage || 0;
	} else {
		respondedVal = overallCompletionSummary?.respondedInNumber || 0;
		notRespondedVal = overallCompletionSummary?.notRespondedInNumber || 0;
	}
	return { respondedVal, notRespondedVal, totalCount };
};
