import {
	IAppliedFilterArray,
	ISurveyListingRequest,
	IFetchAudiencePayload,
	IExportAudiencePayload,
} from '../types/CommonTypes';
import {
	IPostSelectedAudienceFilteredData,
	IQuestionnaireDataRequest,
	IQuestionnaireIDRequest,
} from '../types/SurveyProps';
import axiosIntercept from '../app/authentication/axiosIntercept';
import { IType } from '../types/EnduserType';

export const FetchSurveyList = async (
	requestPayload: ISurveyListingRequest
) => {
	let URL = `Questionnaire`;
	if (requestPayload.createdBy && requestPayload.type === 'Me') {
		URL = `${URL}?createdBy=${requestPayload.createdBy}`;
	}
	return axiosIntercept.get(URL);
};

export const FetchConfigData = async () => {
	return axiosIntercept.get('configs?category=system');
};

export const FetchAudienceFilterList = async () => {
	return axiosIntercept.get('users');
};

export const PostQuestionnaireData = async (
	requestPayload: IQuestionnaireDataRequest
) => {
	return axiosIntercept.post('questionnaire', requestPayload);
};

export const PostSelectedAudienceFilteredData = async (
	requestPayload: IAppliedFilterArray
) => {
	return axiosIntercept.post('users', requestPayload);
};

export const PostFetchAudienceData = async (
	requestPayload: IFetchAudiencePayload
) => {
	return axiosIntercept.post('users/fetchAudience', requestPayload);
};

export const PostSelectedAudienceCollectionFilteredData = async (
	requestPayload: IPostSelectedAudienceFilteredData
) => {
	return axiosIntercept.post('users/validate', requestPayload);
};

export const ExportUsersAsList = async (
	requestPayload: IExportAudiencePayload
) => {
	return axiosIntercept.post('users/export', requestPayload, {
		responseType: 'blob',
	});
};

export const GetQuestionnaireByID = async (
	requestPayload: IQuestionnaireIDRequest
) => {
	return axiosIntercept.get(`questionnaire/${requestPayload.id}`);
};

export const GetQuestionnaireDeactiveResponse = async (
	requestPayload: IQuestionnaireIDRequest
) => {
	return axiosIntercept.put(
		`questionnaire/${requestPayload.id}/deactivatequestionnaire`
	);
};

export const PutQuestionnaireDetails = async (requestPayload: IType) => {
	axiosIntercept.defaults.headers.common['Content-Type'] = 'application/json';
	let URL = `${process.env.REACT_APP_API_BASE_URL}`;
	if (requestPayload.email) {
		URL = `questionnaire/${requestPayload.id}/copyquestionnaire?createdBy=${requestPayload.email}`;
	}
	return axiosIntercept.put(URL);
};
