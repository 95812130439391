import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import FilterAction from '../components/FilterActions';
import SurveyTable from '../components/home/SurveyTable';
import { ISurveyListingDataItem, TabPanelProps } from '../types/CommonTypes';
import { commonLabels } from '../utils/CommonLabels';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { navbar } from '../redux/NavBarSlice';
import QuestionarieSurveyTable from '../components/QuestionarieSurveyTable';
import QuestionarieFilterAction from '../components/QuestionarieFilterActions';
import Loader from '../components/Loader';
import {
	resetSurveyPayload,
	getAudienceFilterData,
	configurationData,
	setModifiedSurveyResponse,
	setQuestionnaireStatus,
	surveyAllListingData,
} from '../redux/SurveySlice';
import { IconButton } from '../components/common/IconButton';
import { ReactComponent as PlusIcon } from '../assets/images/plus.svg';
import { setError, setHelperText } from '../redux/QuestionnaireSurveySlice';

const StyledTab = styled(Tabs)({
	'.Mui-selected': {
		fontFamily: '"JohnsonText-Regular"',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		letterSpacing: '0px',
		textAlign: 'center',
		color: '#212121 !important',
		textTransform: 'none',
	},
	fontFamily: '"JohnsonText-Regular"',
});

const CustomTabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	);
};

const a11yProps = (index: number) => {
	return {
		id: `survey-tab-${index}`,
		'aria-controls': `survey-tabpanel-${index}`,
	};
};

const ListingPage: React.FC<unknown> = () => {
	const [value, setValue] = useState(0);
	const selectedNavBar = useAppSelector(navbar);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const configs = useAppSelector(configurationData);
	const surveyLegacyListData: ISurveyListingDataItem[] =
		useAppSelector(surveyAllListingData);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		dispatch(resetSurveyPayload({}));
		const filterTypes = [
			'location',
			'status',
			'questionnaireName',
			'questionnaireAuthor',
		];
		filterTypes.forEach((filterType) => {
			dispatch(setError({ filterType: filterType, error: false }));
			dispatch(setHelperText({ filterType: filterType, helperText: '' }));
		});
	};

	useEffect(() => {
		configs?.rolePermissions?.isAdmin && dispatch(getAudienceFilterData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configs?.rolePermissions]);

	return (
		<>
			<Loader isLoading={false}></Loader>
			<Box sx={{ padding: '0px 72px' }}>
				{selectedNavBar === 0 && (
					<>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<StyledTab
								value={value}
								onChange={handleChange}
								aria-label="Questionnaire Tab"
								TabIndicatorProps={{
									style: {
										backgroundColor: '#EB1700',
									},
								}}
							>
								<Tab
									role="tab"
									label={commonLabels.createdByMe}
									{...a11yProps(0)}
								/>
								<Tab
									role="tab"
									label={commonLabels.allQuestionnaire}
									{...a11yProps(1)}
								/>
							</StyledTab>
							{configs.rolePermissions?.isAdmin && (
								<Box sx={{ padding: '12px 12px 0px 0px' }}>
									<IconButton
										data-testid="icon-btn"
										style={{ width: '241px', height: '32px' }}
										iconType={<PlusIcon />}
										align="start"
										labelText={'Create New Questionnaire'}
										onClick={() => {
											dispatch(setModifiedSurveyResponse(surveyLegacyListData));
											dispatch(setQuestionnaireStatus(''));
											navigate('/create-survey');
										}}
									/>
								</Box>
							)}
						</Box>
						<CustomTabPanel value={value} index={0}>
							<FilterAction />
							<SurveyTable type={'Me'} />
						</CustomTabPanel>

						<CustomTabPanel value={value} index={1}>
							<FilterAction />
							<SurveyTable type={'All'} />
						</CustomTabPanel>
					</>
				)}
				{selectedNavBar === 1 && (
					<>
						<Box>
							<QuestionarieFilterAction />
							<QuestionarieSurveyTable />
						</Box>
					</>
				)}
			</Box>
		</>
	);
};

export default ListingPage;
