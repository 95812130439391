import React from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
	styled,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { InstructionDialogProps } from '../../types/CommonTypes';
import { STEPTheme } from '../../utils/Theme';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { commonLabels } from '../../utils/CommonLabels';
import TextEditor from '../common/TextEditor';
import {
	surveyQuestionnaireData,
	updateSurveyQuestionnaire,
} from '../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';

const StyledTitleTypography = styled(Box)`
	margin-bottom: 2px;
`;
const StyledSubTitleTypography = styled(Box)`
	font-family: 'JohnsonText-Regular !important';
	font-size: 16px;
	font-weight: 400;
	line-height: 16px;
	color: ${STEPTheme.colors.subText};
	margin-bottom: 2px;
`;

const ActionButtonFooter = styled(Button)({
	width: '50%',
	height: '32px',
	marginLeft: '10px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.primary,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.primary}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
	},
});
const CancelButtonFooter = styled(Button)({
	width: '50%',
	height: '32px',
	marginLeft: '10px',
	color: STEPTheme.colors.primary,
	background: STEPTheme.colors.white,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.white}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
		color: STEPTheme.colors.white,
	},
});
const useStyles = makeStyles({
	dialogBox: {
		borderRadius: '15px !important',
		width: '900px',
	},
	dialogTitle: {
		paddingBottom: '20px !important',
		paddingTop: '50px !important',
	},
	insTextFieldInput: {
		height: '20px !important',
		padding: '10px 14px !important',
		borderRadius: '8px',
	},
	insTextFieldRoot: {
		borderRadius: '8px !important',
	},
	insLabel: {
		top: '-6px !important',
		fontFamily: "'JohnsonText-Regular'  !important",
		fontSize: '14px !important',
	},
	modalContent: {
		paddingTop: '5px !important',
		paddingBottom: '5px !important',
	},
	modalFooter: {
		justifyContent: 'space-around',
		padding: '20px 24px !important',
	},
	modalButton: {
		width: '50%',
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		background: STEPTheme.colors.background,
		justifyContent: 'center',
	},
});
const QuestionAddInstructionPop: React.FC<InstructionDialogProps> = ({
	open,
	onClose,
}) => {
	const [content, setContent] = React.useState('');
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const dispatch = useAppDispatch();
	const handleClose = () => {
		onClose();
	};
	const handleSubmit = () => {
		dispatch(
			updateSurveyQuestionnaire({
				...surveyQuestionnaireInfo,
				instruction: content,
			})
		);
		handleClose();
	};
	const instructionApiContent = surveyQuestionnaireInfo.instruction;
	const contentChangeHandler = (newContent: string) => {
		setContent(newContent);
	};
	const classes = useStyles();
	return (
		<Dialog
			open={open}
			data-testid="question-add-instruction-pop"
			onClose={handleClose}
			PaperProps={{
				component: 'form',
				className: classes.dialogBox,
			}}
			disableEnforceFocus
		>
			<DialogTitle className={classes.dialogTitle}>
				<StyledTitleTypography>
					<Typography variant="h1">{questionsLabels.addQueIns}</Typography>
				</StyledTitleTypography>
				<StyledSubTitleTypography>
					{questionsLabels.addQueInsDes}
				</StyledSubTitleTypography>
			</DialogTitle>
			<IconButton
				aria-label="close"
				data-testid="close-icon"
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: STEPTheme.colors.black,
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent className={classes.modalContent}>
				<TextEditor
					OnchangeHandler={(text) => contentChangeHandler(text)}
					emailTemplate={
						instructionApiContent ? instructionApiContent : content
					}
					charLimit={1000}
				/>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<CancelButtonFooter data-testid="cancel" onClick={handleClose}>
					{commonLabels.cancel}
				</CancelButtonFooter>
				<ActionButtonFooter data-testid="submit" onClick={handleSubmit}>
					{commonLabels.submit}
				</ActionButtonFooter>
			</DialogActions>
		</Dialog>
	);
};
export default QuestionAddInstructionPop;
