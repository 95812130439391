import React from 'react';
import { Alert, AlertColor, Snackbar, styled } from '@mui/material';
import { STEPTheme } from '../../utils/Theme';

interface ToasterProps {
	onClose: (showToast: boolean) => void;
	hideDuration: number;
	message: string;
	severity: AlertColor | undefined;
	show: boolean;
}

const SnackbarBox = styled(Snackbar)({
	'&.info .MuiPaper-root': {
		backgroundColor: STEPTheme.colors.emeaBlue,
		color: STEPTheme.colors.totalLightGray,
	},
	'&.success .MuiPaper-root': {
		backgroundColor: STEPTheme.colors.toastSuccess,
		color: STEPTheme.colors.text,
	},
	'&.error .MuiPaper-root': {
		backgroundColor: STEPTheme.colors.red,
		color: STEPTheme.colors.white,
	},
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '700',
	'&.error .MuiAlert-icon': {
		color: STEPTheme.colors.white,
	},
	'&.error .MuiAlert-action': {
		color: STEPTheme.colors.white,
	},
	'&.info .MuiAlert-icon': {
		color: STEPTheme.colors.totalLightGray,
	},
	'&.info .MuiAlert-action': {
		color: STEPTheme.colors.totalLightGray,
	},
	'&.success .MuiAlert-icon': {
		color: STEPTheme.colors.green,
	},
	'&.success .MuiAlert-action': {
		color: STEPTheme.colors.text,
	},
});

const Toaster: React.FC<ToasterProps> = ({
	onClose,
	hideDuration,
	message,
	severity,
	show,
}) => {
	const handleCloseToast = () => {
		onClose(false);
	};
	return (
		<SnackbarBox
			className={severity}
			onClose={handleCloseToast}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			open={show}
			autoHideDuration={hideDuration}
		>
			<Alert
				onClose={handleCloseToast}
				severity={severity}
				variant="filled"
				sx={{ width: '100%' }}
			>
				{message}
			</Alert>
		</SnackbarBox>
	);
};

export default Toaster;
