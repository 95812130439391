import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Button } from '@mui/material';
import { batch } from 'react-redux';
import { filter } from '../utils/Filter';
import { ISurveyListingDataItem } from '../types/CommonTypes';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	surveyModifiedListingData,
	surveyListingRequest,
	setModifiedSurveyResponse,
	surveyAllListingData,
	resetSurveyPayload,
	configurationData,
	setFilterDate,
	getFilterDate,
} from '../redux/SurveySlice';
import { STEPTheme } from '../utils/Theme';
import { filterSurveyData } from '../utils/Helpers';
import {
	toggleResetInputValues,
	setError,
	setHelperText,
} from '../redux/QuestionnaireSurveySlice';
import Table from './common/Table';
import FilterSegments from './FilterSegments';

const TextContainer = styled('span')`
  display: flex;
  justify-content: space-between;
}
`;

const ResultTextContainer = styled('div')({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.darkGray,
	margin: '12px 0px 38px 0px',
});

const ItemSurveyText = styled('span')``;

const FilterBox = styled(Box)({
	width: '100%',
	height: '48px',
	alignItems: 'center',
	justifyContent: 'space-between',
	border: '1px',
});

const FilterButtonsWrapper = styled(Box)({
	display: 'flex',
	marginTop: 'auto',
	marginBottom: '2px',
	gap: '20px',
});

const ResetButton = styled(Button)({
	width: '114px',
	height: '32px',
	color: STEPTheme.colors.text,
	background: 'white',
	padding: '8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.text}`,
	'&:hover': {
		backgroundColor: 'white',
		borderColor: 'black',
		color: STEPTheme.colors.text,
	},
});
export const getFilterLabel = (item: string) => {
	if (item === 'location') {
		return filter.label.QUESTIONNAIRE_CATEGORY;
	} else if (item === 'status') {
		return filter.label.STATUS;
	} else if (item === 'questionnaireName') {
		return filter.label.QUESTIONNAIRE_NAME;
	} else {
		return filter.label.CREATE_BY;
	}
};

const getDistinctData = <T, K extends keyof T>(
	data: T[],
	property: K
): T[K][] => {
	const modifiedData = data.reduce((values: T[K][], current) => {
		if (current[property]) {
			values.push(current[property]);
		}
		return values;
	}, []);

	return [...new Set(modifiedData)];
};

const FilterAction: React.FC<unknown> = () => {
	const surveyLegacyListData: ISurveyListingDataItem[] =
		useAppSelector(surveyAllListingData);
	const surveyReqData = useAppSelector(surveyListingRequest);
	const surveyListData: ISurveyListingDataItem[] = useAppSelector(
		surveyModifiedListingData
	);
	const configs = useAppSelector(configurationData);
	const filteredDate = useAppSelector(getFilterDate);
	const dispatch = useAppDispatch();
	useEffect(() => {
		handleFilterChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyReqData]);
	useEffect(() => {
		resetState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFilterChange = () => {
		const surveyFilteredData = filterSurveyData(
			surveyLegacyListData,
			surveyReqData,
			filteredDate
		);
		dispatch(setModifiedSurveyResponse(surveyFilteredData));
	};

	const resetState = () => {
		batch(() => {
			dispatch(resetSurveyPayload({}));
			dispatch(setError({ filterType: 'questionnaireName', error: false }));
			dispatch(
				setHelperText({ filterType: 'questionnaireName', helperText: '' })
			);
			dispatch(setError({ filterType: 'location', error: false }));
			dispatch(setHelperText({ filterType: 'location', helperText: '' }));
			dispatch(setError({ filterType: 'questionnaireAuthor', error: false }));
			dispatch(
				setHelperText({ filterType: 'questionnaireAuthor', helperText: '' })
			);
			dispatch(setError({ filterType: 'status', error: false }));
			dispatch(setHelperText({ filterType: 'status', helperText: '' }));
			dispatch(
				setFilterDate({
					startDate: '',
					endDate: '',
					createdAtStartDate: '',
					createdAtEndDate: '',
				})
			);
			dispatch(toggleResetInputValues());
		});
	};

	return (
		<>
			<div className="survey-texts">
				<Box>
					<TextContainer>
						<ResultTextContainer>
							<ItemSurveyText>{`${filter.label.TOTAL_NUMBER_QUESTIONNAIRE_RECORDS}: ${surveyListData.length}`}</ItemSurveyText>
						</ResultTextContainer>
					</TextContainer>
					<Table />
				</Box>
			</div>
			<div className="survey-filters">
				<Grid
					container
					spacing={2}
					sx={{
						width: '100%',
						margin: '0px',
						justifyContent: 'space-between',
						marginBottom: '26px',
					}}
				>
					<FilterBox
						display="flex"
						onKeyPress={(event) => {
							if (event.key === 'Enter') {
								handleFilterChange();
							}
						}}
					>
						<Box sx={{ display: 'flex', width: '1032px', gap: '24px' }}>
							<FilterSegments
								data-testid="questionnaire-Name"
								type="questionnaireName"
								filterItems={getDistinctData(
									surveyListData,
									'questionnaireName'
								).concat(getDistinctData(surveyListData, 'questionnaireId'))}
								handleFilterChange={handleFilterChange}
							/>
							<FilterSegments
								data-testid="location"
								type="location"
								filterItems={configs?.location || []}
								handleFilterChange={handleFilterChange}
							/>
							<FilterSegments
								data-testid="questionnaire-Author"
								type="questionnaireAuthor"
								filterItems={getDistinctData(
									surveyListData,
									'questionnaireAuthor'
								)}
								handleFilterChange={handleFilterChange}
							/>
							<FilterSegments
								data-testid="status"
								type="status"
								filterItems={configs?.status || []}
								handleFilterChange={handleFilterChange}
							/>
						</Box>
						<FilterButtonsWrapper>
							<ResetButton
								data-testid="reset-button"
								variant="outlined"
								size="small"
								onClick={resetState}
							>
								{`${filter?.label?.RESET}`}
							</ResetButton>
						</FilterButtonsWrapper>
					</FilterBox>
				</Grid>
			</div>
		</>
	);
};

export default FilterAction;
