import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { previewQuestionnaireLabels } from '../../../utils/CommonLabels';

const useStyles = makeStyles({
	pageTitle: {
		marginBottom: '25px',
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
	},
});

const StyledTitleTypography = styled(Box)`
	margin-bottom: 2px;
`;

const PreviewQuestionnaireHeader: React.FC<unknown> = () => {
	const classes = useStyles();
	return (
		<Box>
			<Box
				className={classes.pageTitle}
				data-testid="PreviewQuestionnaireHeader-header"
			>
				<StyledTitleTypography>
					<Typography variant="h1">
						{previewQuestionnaireLabels.previewQuestionnaire}
					</Typography>
				</StyledTitleTypography>
			</Box>
		</Box>
	);
};
export default PreviewQuestionnaireHeader;
