import axiosIntercept from '../app/authentication/axiosIntercept';
import {
	EndUserListingPayload,
	EndUserListingRequest,
	UpdateMyQuestionnairesPayload,
} from '../types/CommonTypes';
import { IType } from '../types/EnduserType';

export const GetQuestionnairesForMe = async (
	requestPayload: EndUserListingRequest
) => {
	let URL = `myquestionnaire`;
	if (requestPayload.userEmailId) {
		URL = `${URL}?userEmail=${requestPayload.userEmailId}`;
	}
	return axiosIntercept.get(URL);
};

export const GetQuestionnaireDetailsCW = async (requestPayload: IType) => {
	let URL = `myquestionnaire/${requestPayload.id}`;
	if (requestPayload.email) {
		URL = `${URL}?userEmail=${requestPayload.email}`;
	}
	return axiosIntercept.get(URL);
};

export const GetUsersListForCompletingQuestionnaire = async (
	requestPayload: EndUserListingPayload
) => {
	return axiosIntercept.post(
		'myquestionnaire/fetchquestionnaire',
		requestPayload.endUserListingObj
	);
};

export const UpdateMyQuestionnaires = async (
	requestPayload: UpdateMyQuestionnairesPayload
) => {
	return axiosIntercept.put(
		`myquestionnaire/${requestPayload.updateMyQuestionnairesObj.questionnaireId}`,
		requestPayload.updateMyQuestionnairesObj
	);
};
