import axiosIntercept from '../app/authentication/axiosIntercept';
import { Numbers } from '../utils/Enum';

export const setLocalStorageValues = async () => {
	const { data } = await axiosIntercept.get('msal');
	const response = data?.data[Numbers.zero]?.keyValue;
	const parsedValue = JSON.parse(response)?.val;
	if (parsedValue) {
		parsedValue?.forEach((k: { [s: string]: string }) => {
			localStorage.setItem(
				Object.entries(k)[Numbers.zero][Numbers.zero],
				Object.entries(k)[Numbers.zero][Numbers.one]
			);
		});
		return true;
	}
	return false;
};
