import React, { useEffect } from 'react';
import {
	select,
	scaleBand,
	scaleLinear,
	axisBottom,
	axisLeft,
	ticks,
} from 'd3';
import { Numbers } from '../../utils/Enum';

interface Segment {
	value: number;
	color: string;
	label: string;
}
interface DataItem {
	label: string;
	segments: Segment[];
}
interface BarChartProps {
	data: DataItem[];
	width: number;
	height: number;
	chartType: string;
	yMax: number;
	id: string;
}
const BarChart: React.FC<BarChartProps> = ({
	data,
	width,
	height,
	chartType,
	yMax,
	id,
}) => {
	const svgMarginTop = 20;
	const svgMarginRight = 20;
	const svgMarginBottom = 30;
	const svgMarginLeft = 60;
	const barPadding = 0.8;
	const gridOpacity = 0.1;

	useEffect(() => {
		const svg = select(`#${id}`);
		svg.selectAll('*').remove();

		const margin = {
			top: svgMarginTop,
			right: svgMarginRight,
			bottom: svgMarginBottom,
			left: svgMarginLeft,
		};
		const innerWidth = width - margin.left - margin.right;
		const innerHeight = height - margin.top - margin.bottom;
		const maxValue = yMax;
		const xScale = scaleBand()
			.domain(
				data.map(function (d) {
					return d.label;
				})
			)
			.range([0, innerWidth])
			.padding(barPadding);
		const yScale = scaleLinear()
			.domain([0, maxValue])
			.nice()
			.range([innerHeight, 0]);
		const numTicks = 5;
		const tickValues = ticks(yScale.domain()[0], yScale.domain()[1], numTicks);

		const g = svg
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`)
			.style('font-family', "'JohnsonText-Regular'")
			.style('font-size', '12px');

		const tooltip = select('body')
			.append('div')
			.attr('class', 'tooltip')
			.style('display', 'none')
			.style('position', 'absolute')
			.style('background-color', 'black')
			.style('color', 'white')
			.style('padding', '10px')
			.style('border-radius', '11px')
			.style('box-shadow', '0px 4px 24px 0px rgba(0, 0, 0, 0.24)')
			.style('font-family', "'JohnsonText-Regular'")
			.style('font-size', '12px');

		//x-axis
		g.append('g')
			.attr('class', 'x-axis')
			.attr('transform', `translate(0,${innerHeight})`)
			.call(axisBottom(xScale));
		// Add Y axis
		g.append('g')
			.attr('class', 'y-axis')
			.call(
				axisLeft(yScale)
					.tickValues(tickValues)
					.tickFormat((d) => (chartType === 'Percentage' ? `${d}% ` : `${d}`))
			);
		svg.selectAll('.tick line').attr('stroke', 'white');
		svg
			.append('g')
			.call(
				axisLeft(yScale)
					.tickValues(tickValues)
					.tickSize(-innerWidth)
					.tickFormat(() => '')
			)
			.attr('transform', `translate(${margin.left},${margin.top})`)
			.style('opacity', gridOpacity)
			.attr('class', 'grid');

		// Blends the X-axis and Y-axis lines with Background
		svg.selectAll('.domain').attr('stroke', 'white');

		g.selectAll('.bar-group')
			.data(data)
			.enter()
			.append('g')
			.attr('class', 'bar-group')
			.attr('transform', function (d) {
				return `translate(${xScale(d.label)},0)`;
			})
			.selectAll('rect')
			.data(function (d) {
				return d.segments;
			})
			.enter()
			.append('rect')
			.attr('class', 'bar')
			.attr('x', 0)
			.attr('y', (d: Segment) => yScale(d.value))
			.attr('width', xScale.bandwidth())
			.attr('height', (d: Segment) => innerHeight - yScale(d.value))
			.attr('fill', function (d) {
				return d.color;
			})
			.on('mouseover', function (event, d) {
				tooltip
					.html(`${d.value}${chartType === 'Percentage' ? '%' : ''}`)
					.style('display', 'block')
					.style('top', `${event.pageY - Numbers.thirty}px`)
					.style('left', `${event.pageX}px`);
			})
			.on('mouseout', function () {
				tooltip.style('display', 'none');
			});

		g.selectAll('text')
			.style('font-family', "'JohnsonText-Regular'")
			.style('font-size', '12px');
	}, [data, width, height, id, yMax, chartType]);
	return <svg id={id} width={width} height={height}></svg>;
};
export default BarChart;
