import React, { useEffect, useState, useRef } from 'react';
import {
	MenuItem,
	Typography,
	FormControl,
	Checkbox,
	TextField,
	Autocomplete,
	Box,
	FormControlLabel,
	FormGroup,
	Button,
	DialogTitle,
} from '@mui/material';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { STEPTheme } from '../utils/Theme';
import {
	getFilterAllJobFunctions,
	getFilterJobFunction,
	getFilterRegion,
	getFilterRegionCountry,
	getSurveyAudienceExcludedFilterData,
	getSurveyAudienceIncludeFilterData,
	setDummySelectedFilterRequestPayload,
	selectedFilterRequestPayloadData,
} from '../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { getFilterLabelById, getKey } from '../utils/Helpers';
import {
	IAudienceModalExcludedFilter,
	IAudienceModalIncludedFilter,
} from '../types/CommonTypes';
import { ValidationErrorMsgs, commonLabels } from '../utils/CommonLabels';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import { Numbers } from '../utils/Enum';
import { AudienceFilterLabels } from '../utils/SurveyAudience';
import { VALIDATIONS_RULES } from '../constants/StepConstants';

const LISTBOX_PADDING = 8; //

const SelectFilter = styled(Typography)({
	width: '497px',
	height: '32px',
	marginTop: '62px',
	fontfamily: 'JohnsonDisplay-Regular',
	fontSize: '31px',
	fontWeight: '700',
	lineHeight: '32px',
	letterSpacing: '0px',
	textAlign: 'left',
});

const StyledFilterRowsContainer = styled(Box)({
	display: 'grid',
	gridTemplateColumns: '1fr 1fr 1fr',
});

const StyledFilterRows = styled(Box)({
	flex: '0 1 calc(1 / 4 * 100%)',
});

const StyledTypography = styled(Typography)({
	fontfamily: 'JohnsonText-Regular',
	fontsize: '14px',
	fontWeight: '700',
	lineHeight: '16px',
	letterspacing: '0px',
	textalign: 'left',
	color: STEPTheme.colors.text,
	width: 'auto',
	height: '16px',
	marginTop: '30px',
	marginLeft: '5px',
	gap: '5px',
});

const StyledFormControl = styled(FormControl)({
	margin: '0px',
	width: '100%',
	borderRadius: '8px',
});

const StyledSelectField = styled(Select)({
	width: '250px',
	height: '32px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '4px',
	borderRadius: '8px',
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.primary,
	},
});

const StyledBox = styled(Box)({
	maxWidth: '220px',
	height: '48px',
	justifyContent: 'space-between',
	border: `1px ${STEPTheme.colors.text}`,
	'.MuiAutocomplete-inputRoot': {
		backgroundColor: `${STEPTheme.colors.white} !important`,
		padding: '0 !important',
		minWidth: '250px',
		height: '32px',
		borderRadius: '8px',
		border: `1px solid ${STEPTheme.colors.darkGray}`,
	},
});

const Reset = styled(Button)({
	width: '123px',
	height: '16px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '400px',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	marginTop: '62px',
	color: STEPTheme.colors.blue,
	textTransform: 'none',
});

const ResetWWID = styled(Button)({
	height: '12px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '400px',
	color: STEPTheme.colors.blue,
	textTransform: 'none',
});

const FilterBoxHeader = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	mb: '20px',
});

const initialIncludedFilterValue = {
	people_leader: [],
	worker_type: [],
	work_region: [],
	work_country: [],
	sector: [],
	mrc_code: [],
	legal_entity: [],
	job_function: [],
	job_subfunction: [],
	job_group: [],
	employee_function: [],
	manager_wwid: [],
};

const initialExcludedFilterValue = {
	sector: [],
	mrc_code: [],
	manager_wwid: [],
};

//data is inconsistent here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderRow(props: any) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING,
	};

	if (Object.hasOwn(dataSet, 'group')) {
		return <>{dataSet.group}</>;
	}

	return (
		<Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
			{`${dataSet[1]}`}
		</Typography>
	);
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});
function useResetCache(data: number) {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData: React.ReactElement[] = [];
	(children as React.ReactElement[]).forEach(
		(item: React.ReactElement & { children?: React.ReactElement[] }) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	);

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? Numbers.thritySix : Numbers.fourtyEight;

	const getChildSize = (child: React.ReactElement) => {
		if (Object.hasOwn(child, 'group')) {
			return Numbers.fourtyEight;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > LISTBOX_PADDING) {
			return LISTBOX_PADDING * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + Numbers.sixteen}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index: number) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

interface AudienceFilterPopupProps {
	hasPeopleLeaderError: boolean;
	includeFilterErrorMessage: string | null;
	setIncludeFilterErrorMessage: React.Dispatch<
		React.SetStateAction<string | null>
	>;
	excludeFilterErrorMessage: string | null;
	setExcludeFilterErrorMessage: React.Dispatch<
		React.SetStateAction<string | null>
	>;
}

const AudienceFilterPopup: React.FC<AudienceFilterPopupProps> = ({
	hasPeopleLeaderError,
	includeFilterErrorMessage,
	setIncludeFilterErrorMessage,
	excludeFilterErrorMessage,
	setExcludeFilterErrorMessage,
}) => {
	const dispatch = useAppDispatch();
	const [includedFilterValue, setIncludedFilterValue] =
		React.useState<IAudienceModalIncludedFilter>(initialIncludedFilterValue);
	const [excludedFilterValue, setExcludedFilterValue] =
		React.useState<IAudienceModalExcludedFilter>(initialExcludedFilterValue);
	const surveyAudienceIncludedFilterList = useAppSelector(
		getSurveyAudienceIncludeFilterData
	);
	const surveyAudienceExcludedFilterList = useAppSelector(
		getSurveyAudienceExcludedFilterData
	);
	const [region, setRegion] = useState<string[]>([]);
	const [countries, setCountries] = useState<string[]>([]);

	const [includeFreeSolo, setIncludeFreeSolo] = useState(true);
	const [includeOpen, setIncludeOpen] = useState(false);
	const [excludeFreeSolo, setExcludeFreeSolo] = useState(true);
	const [excludeOpen, setExcludeOpen] = useState(false);
	const [includeInputValue, setIncludeInputValue] = useState('');
	const [excludeInputValue, setExcludeInputValue] = useState('');

	const [jobFunction, setJobFunction] = useState<string[]>([]);
	const [jobSubFunctions, setJobSubFunctions] = useState<string[]>([]);

	const [includedWWIDs, setIncludedWWIDs] = useState<string[]>([]);
	const excludedWWIDs: string[] = [];

	const filterRegion = useAppSelector(getFilterRegion);
	const filterRegionCountry = useAppSelector(getFilterRegionCountry);
	const filterJobFunction = useAppSelector(getFilterJobFunction);
	const filterAllJobFunctions = useAppSelector(getFilterAllJobFunctions);
	const [filterHasChildren, setFilterHasChildren] = useState<string[]>([]);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);

	const getIncludedWWIDArr = (manager_wwid: []) => {
		setIncludedWWIDs([]);
		Object.values(manager_wwid)?.map((value) => {
			const newWWID = Object.values(value)[0];
			includedWWIDs.push(newWWID as string);
		});
	};

	const getExcludedWWIDArr = (manager_wwid: []) => {
		Object.values(manager_wwid)?.map((value) => {
			const newWWID = Object.values(value)[0];
			excludedWWIDs.push(newWWID as string);
		});
	};

	useEffect(() => {
		if (
			JSON.parse(filterRequestPayload?.includeFilters) !==
			initialIncludedFilterValue
		) {
			const includedFilters = JSON.parse(filterRequestPayload?.includeFilters);
			setIncludedFilterValue(includedFilters);
			getIncludedWWIDArr(includedFilters?.manager_wwid);
		}
		if (
			JSON.parse(filterRequestPayload?.excludeFilters) !==
			initialExcludedFilterValue
		) {
			const excludeFilters = JSON.parse(filterRequestPayload?.excludeFilters);
			setExcludedFilterValue(excludeFilters);
			getExcludedWWIDArr(excludeFilters?.manager_wwid);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRequestPayload]);
	useEffect(() => {
		const filterRegionCountries = Object.entries(filterRegionCountry);
		const filterjobFunctionJobSubFunctions = Object.entries(
			filterAllJobFunctions
		);
		if (Object.keys(includedFilterValue?.work_region).length > 0) {
			includedFilterValue?.work_region.map((value) => {
				Object.values(value).forEach((item) => {
					if (filterRegionCountries.length > 0) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								if (filterCountryRegion === item) {
									countries.push(
										...filterRegionCountries[filterCountryRegionIndex][1]
									);
								}
							}
						);
					}
				});
			});
		}

		if (Object.keys(includedFilterValue?.job_function).length > 0) {
			includedFilterValue?.job_function.map((value) => {
				Object.values(value).forEach((item) => {
					if (filterjobFunctionJobSubFunctions.length > 0) {
						Object.keys(filterAllJobFunctions).forEach(
							(filterAllJobFunction, filterAllJobFunctionIndex) => {
								if (filterAllJobFunction === item) {
									jobSubFunctions.push(
										...filterjobFunctionJobSubFunctions[
											filterAllJobFunctionIndex
										][1]
									);
								}
							}
						);
					}
				});
			});
		}
	}, [
		includedFilterValue?.work_region,
		includedFilterValue?.job_function,
		filterRegionCountry,
		filterAllJobFunctions,
		countries,
		jobSubFunctions,
	]);

	useEffect(() => {
		const updatedFilterRequestPayload = {
			includeFilters: JSON.stringify({ ...includedFilterValue }),
			excludeFilters: JSON.stringify({ ...excludedFilterValue }),
			filterLabelJson: JSON.stringify({
				includeFilters: includedFilterValue,
				excludeFilters: excludedFilterValue,
			}),
			includeClause: '',
			excludeClause: '',
			pageSize: Numbers.ten,
			pageNumber: Numbers.one,
		};
		dispatch(setDummySelectedFilterRequestPayload(updatedFilterRequestPayload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [excludedFilterValue, includedFilterValue]);
	const getIncValCount = (currentKey: string) => {
		switch (currentKey) {
			case 'work_region':
				return includedFilterValue?.work_region.length;
			case 'work_country':
				return includedFilterValue?.work_country.length;
			case 'job_function':
				return includedFilterValue?.job_function.length;
			case 'job_subfunction':
				return includedFilterValue?.job_subfunction.length;
			case 'people_leader':
				return includedFilterValue?.people_leader.length;
			case 'worker_type':
				return includedFilterValue?.worker_type.length;
			case 'sector':
				return includedFilterValue?.sector.length;
			case 'mrc_code':
				return includedFilterValue?.mrc_code.length;
			case 'legal_entity':
				return includedFilterValue?.legal_entity.length;
			case 'job_group':
				return includedFilterValue?.job_group.length;
			case 'employee_function':
				return includedFilterValue?.employee_function.length;
			default:
				return Numbers.zero;
		}
	};

	const getExcValCount = (currentKey: string) => {
		switch (currentKey) {
			case 'sector':
				return excludedFilterValue?.sector.length;
			case 'mrc_code':
				return excludedFilterValue?.mrc_code.length;
			default:
				return Numbers.zero;
		}
	};

	const checkIncVal = (
		currentKey: string,
		surveyAudienceFilterValueKey: string
	) => {
		switch (currentKey) {
			case 'work_region':
				return (
					includedFilterValue?.work_region.findIndex(
						(item) => item.work_region === surveyAudienceFilterValueKey
					) > -1
				);
			case 'work_country':
				return (
					includedFilterValue?.work_country.findIndex(
						(item) => item.work_country === surveyAudienceFilterValueKey
					) > -1
				);
			case 'job_function':
				return (
					includedFilterValue?.job_function.findIndex(
						(item) => item.job_function === surveyAudienceFilterValueKey
					) > -1
				);
			case 'job_subfunction':
				return (
					includedFilterValue?.job_subfunction.findIndex(
						(item) => item.job_subfunction === surveyAudienceFilterValueKey
					) > -1
				);
			case 'people_leader':
				return (
					includedFilterValue?.people_leader.findIndex(
						(item) => item.people_leader === surveyAudienceFilterValueKey
					) > -1
				);
			case 'worker_type':
				return (
					includedFilterValue?.worker_type.findIndex(
						(item) => item.worker_type === surveyAudienceFilterValueKey
					) > -1
				);
			case 'sector':
				return (
					includedFilterValue?.sector.findIndex(
						(item) => item.sector === surveyAudienceFilterValueKey
					) > -1
				);
			case 'mrc_code':
				return (
					includedFilterValue?.mrc_code.findIndex(
						(item) => item.mrc_code === surveyAudienceFilterValueKey
					) > -1
				);
			case 'legal_entity':
				return (
					includedFilterValue?.legal_entity.findIndex(
						(item) => item.legal_entity === surveyAudienceFilterValueKey
					) > -1
				);
			case 'job_group':
				return (
					includedFilterValue?.job_group.findIndex(
						(item) => item.job_group === surveyAudienceFilterValueKey
					) > -1
				);
			case 'employee_function':
				return (
					includedFilterValue?.employee_function.findIndex(
						(item) => item.employee_function === surveyAudienceFilterValueKey
					) > -1
				);
			default:
				return false;
		}
	};

	const checkExcVal = (
		currentKey: string,
		surveyAudienceFilterValueKey: string
	) => {
		switch (currentKey) {
			case 'sector':
				return (
					excludedFilterValue?.sector.findIndex(
						(item) => item.sector === surveyAudienceFilterValueKey
					) > -1
				);
			case 'mrc_code':
				return (
					excludedFilterValue?.mrc_code.findIndex(
						(item) => item.mrc_code === surveyAudienceFilterValueKey
					) > -1
				);
			default:
				return false;
		}
	};

	const handleIncludedFilterChanges = (
		event: React.ChangeEvent<HTMLInputElement>,
		type: string,
		hasChildren = false,
		childArray = Array
	) => {
		const value = event.target.value;
		const isChecked = event.target.checked;

		const filterRegionCountries = Object.entries(filterRegionCountry);
		const filterjobFunctionJobSubFunctions = Object.entries(
			filterAllJobFunctions
		);
		switch (type) {
			case 'work_country':
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = includedFilterValue?.work_country.findIndex(
						(item) => item.work_country === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.work_country.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_country: [...includedFilterValue.work_country],
					}));
				} else {
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_country: [...prevState.work_country, { work_country: value }],
					}));
				}
				break;
			case 'job_subfunction':
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = includedFilterValue?.job_subfunction.findIndex(
						(item) => item.job_subfunction === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.job_subfunction.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_subfunction: [...includedFilterValue.job_subfunction],
					}));
				} else {
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_subfunction: [
							...prevState.job_subfunction,
							{ job_subfunction: value },
						],
					}));
				}
				break;
			case 'job_function':
				if (!isChecked) {
					setJobSubFunctions([]);
					// unchecking the box...
					const currentIndex = includedFilterValue?.job_function.findIndex(
						(item) => item.job_function === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.job_function.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_function: [...includedFilterValue.job_function],
					}));

					if (filterjobFunctionJobSubFunctions.length > 0) {
						Object.keys(filterjobFunctionJobSubFunctions).forEach(
							(
								filterjobFunctionJobSubFunction,
								filterjobFunctionJobSubFunctionIndex
							) => {
								const selectedJobFunctionArr =
									includedFilterValue?.job_function.filter(
										(item) =>
											item.job_function === filterjobFunctionJobSubFunction
									);
								if (selectedJobFunctionArr.length) {
									setJobSubFunctions([
										...(jobSubFunctions as []),
										...filterjobFunctionJobSubFunctions[
											filterjobFunctionJobSubFunctionIndex
										][1],
									]);
								}
							}
						);
					}
				} else {
					setJobFunction([...(jobFunction as []), ...value]);
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_function: [...prevState.job_function, { job_function: value }],
					}));

					if (filterjobFunctionJobSubFunctions.length > 0) {
						Object.keys(filterAllJobFunctions).forEach(
							(flterJobFunction, flterJobFunctionIndex) => {
								if (flterJobFunction === value) {
									setJobSubFunctions([
										...(jobSubFunctions as []),
										...filterjobFunctionJobSubFunctions[
											flterJobFunctionIndex
										][1],
									]);
								}
							}
						);
					}
				}
				break;
			case 'work_region':
				if (!isChecked) {
					setCountries([]);
					// unchecking the box...
					const currentIndex = includedFilterValue?.work_region.findIndex(
						(item) => item.work_region === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.work_region.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_region: [...includedFilterValue.work_region],
					}));

					if (filterRegionCountries.length > 0) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								const selectedRegionarr =
									includedFilterValue?.work_region.filter(
										(item) => item.work_region === filterCountryRegion
									);
								if (selectedRegionarr.length) {
									setCountries([
										...(countries as []),
										...filterRegionCountries[filterCountryRegionIndex][1],
									]);
								}
							}
						);
					}
				} else {
					setRegion([...(region as []), ...value]);
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_region: [...prevState.work_region, { work_region: value }],
					}));

					if (filterRegionCountries.length > 0) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								if (filterCountryRegion === value) {
									setCountries([
										...(countries as []),
										...filterRegionCountries[filterCountryRegionIndex][1],
									]);
								}
							}
						);
					}
				}
				break;
			case 'people_leader':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					if (childArray instanceof Array) {
						childArray.map((element: string) => {
							const childItemIndex =
								includedFilterValue?.people_leader.findIndex(
									(item) => item.people_leader === element
								);
							if (childItemIndex > -1) {
								includedFilterValue?.people_leader.splice(childItemIndex, 1);
							}
						});
					}

					const currentIndex = includedFilterValue?.people_leader.findIndex(
						(item) => item.people_leader === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.people_leader.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						people_leader: [...includedFilterValue.people_leader],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						people_leader: [
							...prevState.people_leader,
							{ people_leader: value },
						],
					}));
				}
				break;
			case 'worker_type':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.worker_type.findIndex(
						(item) => item.worker_type === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.worker_type.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						worker_type: [...includedFilterValue.worker_type],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						worker_type: [...prevState.worker_type, { worker_type: value }],
					}));
				}
				break;
			case 'sector':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.sector.findIndex(
						(item) => item.sector === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.sector.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						sector: [...includedFilterValue.sector],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						sector: [...prevState.sector, { sector: value }],
					}));
				}
				break;
			case 'mrc_code':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.mrc_code.findIndex(
						(item) => item.mrc_code === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.mrc_code.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...includedFilterValue.mrc_code],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...prevState.mrc_code, { mrc_code: value }],
					}));
				}
				break;
			case 'legal_entity':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.legal_entity.findIndex(
						(item) => item.legal_entity === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.legal_entity.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						legal_entity: [...includedFilterValue.legal_entity],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						legal_entity: [...prevState.legal_entity, { legal_entity: value }],
					}));
				}
				break;
			case 'job_group':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.job_group.findIndex(
						(item) => item.job_group === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.job_group.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_group: [...includedFilterValue.job_group],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_group: [...prevState.job_group, { job_group: value }],
					}));
				}
				break;
			case 'employee_function':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.employee_function.findIndex(
						(item) => item.employee_function === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.employee_function.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						employee_function: [...includedFilterValue.employee_function],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						employee_function: [
							...prevState.employee_function,
							{ employee_function: value },
						],
					}));
				}
				break;
			default:
				break;
		}
	};

	const handleExcludedFilterChanges = (
		event: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		const value = event.target.value;
		const isChecked = event.target.checked;
		switch (type) {
			case 'sector':
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = excludedFilterValue?.sector.findIndex(
						(item) => item.sector === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.sector.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						sector: [...excludedFilterValue.sector],
					}));
				} else {
					setExcludedFilterValue((prevState) => ({
						...prevState,
						sector: [...prevState.sector, { sector: value }],
					}));
				}
				break;
			case 'mrc_code':
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = excludedFilterValue?.mrc_code.findIndex(
						(item) => item.mrc_code === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.mrc_code.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...excludedFilterValue.mrc_code],
					}));
				} else {
					setExcludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...prevState.mrc_code, { mrc_code: value }],
					}));
				}
				break;
			default:
				break;
		}
	};

	const surveyAudienceIncludedFilters = Object.entries(
		surveyAudienceIncludedFilterList
	);
	const surveyAudienceExcludedFilters = Object.entries(
		surveyAudienceExcludedFilterList
	);

	const clearIncludeResetFilter = useRef<HTMLDivElement>(null);
	const clearExcludeResetFilter = useRef<HTMLDivElement>(null);

	const handleResetExcludeFilters = () => {
		setExcludedFilterValue(initialExcludedFilterValue);
		if (clearExcludeResetFilter && clearExcludeResetFilter.current) {
			const ele = clearExcludeResetFilter.current.getElementsByClassName(
				'MuiAutocomplete-clearIndicator'
			)[0] as HTMLElement;
			if (ele) {
				ele.click();
			}
		}
	};

	const handleResetIncludeFilters = () => {
		setIncludedFilterValue(initialIncludedFilterValue);
		setCountries([]);
		setJobSubFunctions([]);
		setFilterHasChildren([]);
		setIncludedWWIDs([]);
		if (clearIncludeResetFilter && clearIncludeResetFilter.current) {
			const ele = clearIncludeResetFilter.current.getElementsByClassName(
				'MuiAutocomplete-clearIndicator'
			)[0] as HTMLElement;
			if (ele) {
				ele.click();
			}
		}
	};

	const handleClearAllWWID = (isInclude: boolean) => {
		if (isInclude) {
			setIncludedWWIDs([]);
			if (clearIncludeResetFilter && clearIncludeResetFilter.current) {
				const ele = clearIncludeResetFilter.current.getElementsByClassName(
					'MuiAutocomplete-clearIndicator'
				)[0] as HTMLElement;
				if (ele) {
					ele.click();
				}
			}
		} else {
			if (clearExcludeResetFilter && clearExcludeResetFilter.current) {
				const ele = clearExcludeResetFilter.current.getElementsByClassName(
					'MuiAutocomplete-clearIndicator'
				)[0] as HTMLElement;
				if (ele) {
					ele.click();
				}
			}
		}
	};

	return (
		<>
			<DialogTitle data-testid="dialogTitleCheck">
				<FilterBoxHeader className="d-flex">
					<SelectFilter>{AudienceFilterLabels.includeFilters}</SelectFilter>
					<Reset
						className="pr-0 justify-end"
						variant="text"
						onClick={() => {
							handleResetIncludeFilters();
						}}
						data-testid="reset"
					>
						{AudienceFilterLabels.resetAll}
					</Reset>
				</FilterBoxHeader>
			</DialogTitle>
			{surveyAudienceIncludedFilters.length > 0 && (
				<StyledFilterRowsContainer>
					{surveyAudienceIncludedFilters.map((surveyAudienceIncludedFilter) => {
						const currentKey = surveyAudienceIncludedFilter[0];
						const currentMultipleKeys = currentKey.split('-');
						const surveyAudienceFilterValues =
							//Disabling here data is inconsistent
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							surveyAudienceIncludedFilter[1];
						const surveyAudienceFilterValueKeys = getKey(
							surveyAudienceFilterValues
						);
						return currentKey !== 'manager_wwid' ? (
							currentMultipleKeys.length > 1 ? (
								<>
									{currentMultipleKeys.map((currentMultipleKey) => {
										return (
											<>
												{filterRegion.length > 0 &&
												currentMultipleKey === 'work_region' ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																placeholder={commonLabels.select}
																displayEmpty
																multiple
																value={[]}
																renderValue={() =>
																	getIncValCount(currentMultipleKey) > 0
																		? `${getIncValCount(currentMultipleKey)} Selected`
																		: commonLabels.select
																}
															>
																{filterRegion.map((filterRegn: string) => {
																	return (
																		<MenuItem
																			key={filterRegn}
																			value={filterRegn}
																		>
																			<FormControlLabel
																				control={
																					<Checkbox
																						id={`aud_filter_${filterRegn.replace(/\s/g, '')}`}
																						value={filterRegn}
																						onChange={(e) => {
																							handleIncludedFilterChanges(
																								e,
																								currentMultipleKey,
																								false
																							);
																						}}
																						checked={checkIncVal(
																							currentMultipleKey,
																							filterRegn
																						)}
																					/>
																				}
																				label={filterRegn}
																				sx={{
																					width: '100%',
																				}}
																				htmlFor={`aud_filter_${filterRegn.replace(/\s/g, '')}`}
																			/>
																		</MenuItem>
																	);
																})}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
												{currentMultipleKey === 'work_country' ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																value={[]}
																placeholder={commonLabels.select}
																displayEmpty
																multiple
																renderValue={() =>
																	getIncValCount(currentMultipleKey) > 0
																		? `${getIncValCount(currentMultipleKey)} Selected`
																		: commonLabels.select
																}
															>
																{countries
																	.sort()
																	.filter(function (v, i, self) {
																		return i === self.indexOf(v);
																	})
																	.map((cntrs: string) => {
																		return (
																			<MenuItem key={cntrs} value={cntrs}>
																				<FormControlLabel
																					control={
																						<Checkbox
																							id={`aud_filter_${cntrs.replace(/\s/g, '')}`}
																							value={cntrs}
																							onChange={(e) => {
																								handleIncludedFilterChanges(
																									e,
																									currentMultipleKey,
																									false
																								);
																							}}
																							checked={checkIncVal(
																								currentMultipleKey,
																								cntrs
																							)}
																						/>
																					}
																					label={cntrs}
																					sx={{
																						width: '100%',
																					}}
																					htmlFor={`aud_filter_${cntrs.replace(/\s/g, '')}`}
																				/>
																			</MenuItem>
																		);
																	})}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
												{currentMultipleKey === 'job_function' ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																placeholder={commonLabels.select}
																displayEmpty
																multiple
																value={[]}
																renderValue={() =>
																	getIncValCount(currentMultipleKey) > 0
																		? `${getIncValCount(currentMultipleKey)} Selected`
																		: commonLabels.select
																}
															>
																{filterJobFunction.map(
																	(filterJobFunc: string) => {
																		return (
																			<>
																				<MenuItem
																					key={filterJobFunc}
																					value={filterJobFunc}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${filterJobFunc.replace(/\s/g, '')}`}
																								value={filterJobFunc}
																								onChange={(e) => {
																									handleIncludedFilterChanges(
																										e,
																										currentMultipleKey,
																										false
																									);
																								}}
																								checked={checkIncVal(
																									currentMultipleKey,
																									filterJobFunc
																								)}
																							/>
																						}
																						label={filterJobFunc}
																						sx={{
																							width: '100%',
																						}}
																						htmlFor={`aud_filter_${filterJobFunc.replace(/\s/g, '')}`}
																					/>
																				</MenuItem>
																			</>
																		);
																	}
																)}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
												{currentMultipleKey === 'job_subfunction' ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																value={[]}
																placeholder={commonLabels.select}
																displayEmpty
																multiple
																renderValue={() =>
																	getIncValCount(currentMultipleKey) > 0
																		? `${getIncValCount(currentMultipleKey)} Selected`
																		: commonLabels.select
																}
															>
																{jobSubFunctions
																	.sort()
																	.filter(function (v, i, self) {
																		return i === self.indexOf(v);
																	})
																	.map((jobSubFnction: string) => {
																		return (
																			<>
																				<MenuItem
																					key={jobSubFnction}
																					value={jobSubFnction}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${jobSubFnction.replace(/\s/g, '')}`}
																								value={jobSubFnction}
																								onChange={(e) => {
																									handleIncludedFilterChanges(
																										e,
																										currentMultipleKey,
																										false
																									);
																								}}
																								checked={checkIncVal(
																									currentMultipleKey,
																									jobSubFnction
																								)}
																							/>
																						}
																						label={jobSubFnction}
																						sx={{
																							width: '100%',
																						}}
																						htmlFor={`aud_filter_${jobSubFnction.replace(/\s/g, '')}`}
																					/>
																				</MenuItem>
																			</>
																		);
																	})}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
											</>
										);
									})}
								</>
							) : (
								<StyledFilterRows>
									<StyledTypography>
										{getFilterLabelById(currentKey)}
									</StyledTypography>
									<StyledFormControl>
										<StyledSelectField
											placeholder={commonLabels.select}
											displayEmpty
											multiple
											value={surveyAudienceFilterValueKeys}
											renderValue={() =>
												getIncValCount(currentKey) > 0
													? `${getIncValCount(currentKey)} Selected`
													: commonLabels.select
											}
										>
											{surveyAudienceFilterValueKeys.map(
												(surveyAudienceFilterValueKey) => {
													return (
														<>
															<MenuItem
																key={surveyAudienceFilterValueKey}
																value={surveyAudienceFilterValueKey}
															>
																<FormControlLabel
																	control={
																		<Checkbox
																			id={`aud_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																			value={surveyAudienceFilterValueKey}
																			onChange={(e) =>
																				handleIncludedFilterChanges(
																					e,
																					currentKey,
																					surveyAudienceFilterValues[
																						surveyAudienceFilterValueKey
																					].length > 0,
																					surveyAudienceFilterValues[
																						surveyAudienceFilterValueKey
																					]
																				)
																			}
																			checked={checkIncVal(
																				currentKey,
																				surveyAudienceFilterValueKey
																			)}
																		/>
																	}
																	label={surveyAudienceFilterValueKey}
																	sx={{
																		width: '100%',
																	}}
																	htmlFor={`aud_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																/>
															</MenuItem>
															{/* 1st Level Children  */}
															{surveyAudienceFilterValues[
																surveyAudienceFilterValueKey
															].length > 0 &&
																checkIncVal(
																	currentKey,
																	surveyAudienceFilterValueKey
																) &&
																surveyAudienceFilterValues[
																	surveyAudienceFilterValueKey
																].map(
																	(surveyAudienceFilterValueVal: string) => {
																		return (
																			<>
																				<FormGroup
																					sx={{
																						display: 'flex',
																						direction: 'column',
																						justifyContent: 'center',
																						alignItems: 'center',
																					}}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${surveyAudienceFilterValueVal.replace(/\s/g, '')}`}
																								value={
																									surveyAudienceFilterValueVal
																								}
																								onChange={(e) =>
																									handleIncludedFilterChanges(
																										e,
																										currentKey,
																										false
																									)
																								}
																								checked={checkIncVal(
																									currentKey,
																									surveyAudienceFilterValueVal
																								)}
																							/>
																						}
																						label={surveyAudienceFilterValueVal}
																						htmlFor={`aud_filter_${surveyAudienceFilterValueVal.replace(/\s/g, '')}`}
																					/>
																				</FormGroup>
																			</>
																		);
																	}
																)}
														</>
													);
												}
											)}
										</StyledSelectField>
									</StyledFormControl>
									{hasPeopleLeaderError && currentKey === 'people_leader' && (
										<p className="error-msg">
											{AudienceFilterLabels.selectSubgroupMsg}
										</p>
									)}
								</StyledFilterRows>
							)
						) : (
							<StyledFilterRows>
								<StyledBox key={currentKey}>
									<StyledTypography
										sx={{
											marginBottom: '10px',
											marginTop: '25px',
											width: '250px',
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										{getFilterLabelById(currentKey)}
										<ResetWWID
											onClick={() => {
												handleClearAllWWID(true);
											}}
										>
											{AudienceFilterLabels.reset}
										</ResetWWID>
									</StyledTypography>
									<Autocomplete
										defaultValue={includedWWIDs}
										id="include_manager_wwid"
										multiple
										limitTags={4}
										ref={clearIncludeResetFilter}
										disableListWrap
										ListboxComponent={ListboxComponent}
										options={surveyAudienceFilterValueKeys}
										sx={{
											'& .MuiAutocomplete-inputRoot': {
												overflowY: 'auto',
												height: includedWWIDs.length > 0 ? '80px' : '',
												maxHeight: '80px',
											},
											'& .MuiFilledInput-root .MuiAutocomplete-endAdornment': {
												display: 'none',
											},
										}}
										onChange={(event, newValue) => {
											const allValidWwids = newValue.every((wwid) =>
												VALIDATIONS_RULES.VALIDATION_FILTER_WWIDS.test(wwid)
											);
											if (allValidWwids) {
												setIncludeFilterErrorMessage(null);
												setIncludedFilterValue((prevState) => ({
													...prevState,
													manager_wwid: newValue.map((item) => ({
														manager_wwid: item,
													})),
												}));
											} else {
												setIncludeFilterErrorMessage(
													ValidationErrorMsgs.incorrectWWIDsFormatMsg
												);
											}
										}}
										inputValue={includeInputValue}
										onInputChange={(event, newInputValue) => {
											const trimmedInputValue = newInputValue.trim();
											if (trimmedInputValue === '') {
												setIncludeFilterErrorMessage(null);
												setIncludeFreeSolo(true);
												setIncludeOpen(false);
												setIncludeInputValue('');
											} else {
												const isValidInput =
													VALIDATIONS_RULES.VALIDATION_FILTER_WWIDS.test(
														trimmedInputValue
													);
												if (!isValidInput) {
													setIncludeFilterErrorMessage(
														ValidationErrorMsgs.incorrectWWIDsFormatMsg
													);
													setIncludeFreeSolo(false);
													setIncludeOpen(false);
												} else {
													setIncludeFilterErrorMessage(null);
													setIncludeFreeSolo(true);
													setIncludeOpen(true);
												}
												setIncludeInputValue(trimmedInputValue);
											}
										}}
										freeSolo={includeFreeSolo}
										open={includeOpen}
										renderOption={(props, option, state) =>
											[props, option, state.index] as React.ReactNode
										}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="filled"
												placeholder={commonLabels.search}
												helperText={includeFilterErrorMessage}
												error={!!includeFilterErrorMessage}
												InputProps={{
													...params.InputProps,
													startAdornment: (
														<>
															<SearchIcon />
															{params.InputProps.startAdornment}
														</>
													),
													disableUnderline: true,
												}}
											/>
										)}
									/>
								</StyledBox>
							</StyledFilterRows>
						);
					})}
				</StyledFilterRowsContainer>
			)}

			{surveyAudienceExcludedFilters.length > 0 && (
				<>
					<FilterBoxHeader className="d-flex">
						<SelectFilter>{AudienceFilterLabels.excludeFilters}</SelectFilter>
						{surveyAudienceExcludedFilters.length > 0 && (
							<Reset
								className="pr-0 justify-end"
								variant="text"
								onClick={() => {
									handleResetExcludeFilters();
								}}
								data-testid="include-filter-popup-reset"
							>
								{AudienceFilterLabels.resetAll}
							</Reset>
						)}
					</FilterBoxHeader>

					<StyledFilterRowsContainer>
						{surveyAudienceExcludedFilters.map(
							(surveyAudienceExcludedFilter) => {
								const currentKey = surveyAudienceExcludedFilter[0];
								const surveyAudienceFilterValues =
									//disabling here because data is inconsistent
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									surveyAudienceExcludedFilter[1];
								const surveyAudienceFilterValueKeys = getKey(
									surveyAudienceFilterValues
								);
								return currentKey !== 'manager_wwid' ? (
									<StyledFilterRows>
										<StyledTypography>
											{getFilterLabelById(currentKey)}
										</StyledTypography>
										<StyledFormControl>
											<StyledSelectField
												placeholder={commonLabels.select}
												displayEmpty
												multiple
												value={surveyAudienceFilterValueKeys}
												renderValue={() =>
													getExcValCount(currentKey) > 0
														? `${getExcValCount(currentKey)} Selected`
														: commonLabels.select
												}
											>
												{surveyAudienceFilterValueKeys.map(
													(surveyAudienceFilterValueKey) => {
														return (
															<MenuItem
																key={surveyAudienceFilterValueKey}
																value={surveyAudienceFilterValueKey}
															>
																<FormControlLabel
																	control={
																		<Checkbox
																			id={`aud_ex_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																			value={surveyAudienceFilterValueKey}
																			onChange={(e) =>
																				handleExcludedFilterChanges(
																					e,
																					currentKey
																				)
																			}
																			checked={checkExcVal(
																				currentKey,
																				surveyAudienceFilterValueKey
																			)}
																		/>
																	}
																	label={surveyAudienceFilterValueKey}
																	sx={{
																		width: '100%',
																	}}
																	htmlFor={`aud_ex_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																/>
															</MenuItem>
														);
													}
												)}
											</StyledSelectField>
										</StyledFormControl>
									</StyledFilterRows>
								) : (
									<StyledFilterRows>
										<StyledBox key={currentKey}>
											<StyledTypography
												sx={{
													marginBottom: '10px',
													marginTop: '25px',
													width: '250px',
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												{getFilterLabelById(currentKey)}
												<ResetWWID
													onClick={() => {
														handleClearAllWWID(false);
													}}
												>
													{AudienceFilterLabels.reset}
												</ResetWWID>
											</StyledTypography>
											<Autocomplete
												defaultValue={excludedWWIDs}
												id="exclude_manager_wwid"
												multiple
												limitTags={4}
												ref={clearExcludeResetFilter}
												disableListWrap
												ListboxComponent={ListboxComponent}
												options={surveyAudienceFilterValueKeys}
												sx={{
													'& .MuiAutocomplete-inputRoot': {
														overflowY: 'auto',
														height: excludedWWIDs.length > 0 ? '80px' : '',
														maxHeight: '80px',
														marginBottom:
															excludedWWIDs.length > 0 ? '10px' : '',
													},
													'& .MuiFilledInput-root .MuiAutocomplete-endAdornment':
														{
															display: 'none',
														},
												}}
												onChange={(event, newValue) => {
													const allValidWwids = newValue.every((wwid) =>
														VALIDATIONS_RULES.VALIDATION_FILTER_WWIDS.test(wwid)
													);
													if (allValidWwids) {
														setExcludeFilterErrorMessage(null);
														setExcludedFilterValue((prevState) => ({
															...prevState,
															manager_wwid: newValue?.map((item) => ({
																manager_wwid: item,
															})),
														}));
													} else {
														setExcludeFilterErrorMessage(
															ValidationErrorMsgs.incorrectWWIDsFormatMsg
														);
													}
												}}
												inputValue={excludeInputValue}
												onInputChange={(event, newInputValue) => {
													const trimmedInputValue = newInputValue.trim();
													if (trimmedInputValue === '') {
														setExcludeFilterErrorMessage(null);
														setExcludeFreeSolo(true);
														setExcludeOpen(false);
														setExcludeInputValue('');
													} else {
														const isValidInput =
															VALIDATIONS_RULES.VALIDATION_FILTER_WWIDS.test(
																trimmedInputValue
															);
														if (!isValidInput) {
															setExcludeFilterErrorMessage(
																ValidationErrorMsgs.incorrectWWIDsFormatMsg
															);
															setExcludeFreeSolo(false);
															setExcludeOpen(false);
														} else {
															setExcludeFilterErrorMessage(null);
															setExcludeFreeSolo(true);
															setExcludeOpen(true);
															setExcludeInputValue(trimmedInputValue);
														}
													}
												}}
												freeSolo={excludeFreeSolo}
												open={excludeOpen}
												renderOption={(props, option, state) =>
													[props, option, state.index] as React.ReactNode
												}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="filled"
														placeholder={commonLabels.search}
														helperText={excludeFilterErrorMessage}
														error={!!excludeFilterErrorMessage}
														InputProps={{
															...params.InputProps,
															startAdornment: (
																<>
																	<SearchIcon />
																	{params.InputProps.startAdornment}
																</>
															),
															disableUnderline: true,
														}}
													/>
												)}
											/>
										</StyledBox>
									</StyledFilterRows>
								);
							}
						)}
					</StyledFilterRowsContainer>
				</>
			)}
		</>
	);
};

export default AudienceFilterPopup;
