/* eslint-disable @typescript-eslint/no-explicit-any */
/* value(includedFilters) data not coming in specific format and dynamic key like below so that we have used any
['mrc_code', [{mrc_code: 1410}]] */
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { ReactComponent as ClearIcon } from '../assets/images/Clear-icon.svg';
import { STEPTheme } from '../utils/Theme';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	resetAppliedFilterData,
	selectedFilterRequestPayloadData,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setSurveyAudienceCount,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
	setSurveyAudienceIncludeClause,
	updateAudienceFilterListingData,
} from '../redux/SurveySlice';
import {
	IAudienceExcListingRequest,
	IAudienceIncListingRequest,
} from '../types/CommonTypes';
import { getFilterLabelById } from '../utils/Helpers';
import { Numbers } from '../utils/Enum';
import {
	AudienceFilterLabels,
	initialFilterLabelJson,
} from '../utils/SurveyAudience';
const StyledBox = styled(Box)({
	width: 'auto',
	top: '304px',
	left: '98px',
	padding: '4px 8px',
	gap: '8px',
	display: 'flex',
	color: STEPTheme.colors.white,
	cursor: 'pointer',
});

const BoxContiner = styled(Box)({
	display: 'flex',
	gap: '8px',
});

const StyledTypography = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.text,
});

const initialIncludedFilterValue = {
	people_leader: [],
	worker_type: [],
	work_region: [],
	work_country: [],
	sector: [],
	mrc_code: [],
	legal_entity: [],
	job_function: [],
	job_subfunction: [],
	job_group: [],
	employee_function: [],
	manager_wwid: [],
};

const initialExcludedFilterValue = {
	sector: [],
	mrc_code: [],
	manager_wwid: [],
};

const SelectedFilters = () => {
	const dispatch = useAppDispatch();
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	const includedFilters = filterRequestPayload.includeFilters
		? JSON.parse(filterRequestPayload.includeFilters)
		: (initialIncludedFilterValue as IAudienceIncListingRequest);
	const excludedFilters = filterRequestPayload.excludeFilters
		? JSON.parse(filterRequestPayload.excludeFilters)
		: (initialExcludedFilterValue as IAudienceExcListingRequest);

	const handleClose = (value: any, type: string) => {
		if (type === 'include') {
			includedFilters[value[0]] = [];
		} else {
			excludedFilters[value[0]] = [];
		}

		if (
			_.isEqual(initialIncludedFilterValue, includedFilters) &&
			_.isEqual(initialExcludedFilterValue, excludedFilters)
		) {
			dispatch(resetAppliedFilterData([]));
			dispatch(setSurveyAudienceCount(0));
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: JSON.stringify(initialIncludedFilterValue),
					excludeFilters: JSON.stringify(initialExcludedFilterValue),
					includeClause: '',
					excludeClause: '',
					filterLabelJson: initialFilterLabelJson,
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
			dispatch(
				setSurveyAudienceBlobName(JSON.stringify(initialIncludedFilterValue))
			);
			dispatch(setSurveyAudienceIncludeClause(''));
			dispatch(setSurveyAudienceExcludeClause(''));
			dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
		} else {
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: JSON.stringify({ ...includedFilters }),
					excludeFilters: JSON.stringify({ ...excludedFilters }),
					filterLabelJson: JSON.stringify({
						includeFilters: includedFilters,
						excludeFilters: excludedFilters,
					}),
					includeClause: '',
					excludeClause: '',
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
			if (includedFilters !== '{}') {
				dispatch(
					updateAudienceFilterListingData({
						includeFilters: JSON.stringify({ ...includedFilters }),
						excludeFilters: JSON.stringify({ ...excludedFilters }),
						filterLabelJson: JSON.stringify({
							includeFilters: includedFilters,
							excludeFilters: excludedFilters,
						}),
						includeClause: '',
						excludeClause: '',
						pageSize: Numbers.ten,
						pageNumber: Numbers.one,
					})
				);
			}
		}
	};
	let hasIncludedFiltersCount = false;
	let hasExcludedFiltersCount = false;
	if (Object.values(includedFilters).length > 0) {
		Object.values(includedFilters).forEach((includedFiltersValue: any) => {
			if (includedFiltersValue.length) {
				hasIncludedFiltersCount = true;
			}
		});
	}
	if (Object.values(excludedFilters).length > 0) {
		Object.values(excludedFilters).forEach((excludedFiltersValue: any) => {
			if (excludedFiltersValue.length) {
				hasExcludedFiltersCount = true;
			}
		});
	}

	return (
		<Box data-testid="selectedFilters">
			<BoxContiner>
				{hasIncludedFiltersCount && (
					<Typography variant="h6">
						{AudienceFilterLabels.includedFilters}
					</Typography>
				)}
				{Object.entries(includedFilters).map((includedFiltersValue: any) => {
					const filerValues: string[][] = [];
					includedFiltersValue &&
						includedFiltersValue[1].length > 0 &&
						Object.values(includedFiltersValue[1]).map((filterVal: any) => {
							filerValues.push(Object.values(filterVal));
						});
					return (
						includedFiltersValue &&
						includedFiltersValue[1].length > 0 && (
							<StyledBox>
								<ClearIcon
									onClick={() => handleClose(includedFiltersValue, 'include')}
								/>
								<StyledTypography>
									{getFilterLabelById(includedFiltersValue[0])}-
									{filerValues.join(', ')}
								</StyledTypography>
							</StyledBox>
						)
					);
				})}
			</BoxContiner>
			<BoxContiner
				sx={{
					marignTop: '25px',
				}}
			>
				{hasExcludedFiltersCount && (
					<Typography variant="h6">
						{AudienceFilterLabels.excludedFilters}
					</Typography>
				)}
				{Object.entries(excludedFilters).map((excludedFiltersValue: any) => {
					const filerValues: string[][] = [];
					excludedFiltersValue &&
						excludedFiltersValue[1].length > 0 &&
						Object.values(excludedFiltersValue[1]).map((filterVal: any) => {
							filerValues.push(Object.values(filterVal));
						});
					return (
						excludedFiltersValue &&
						excludedFiltersValue[1].length > 0 && (
							<StyledBox>
								<ClearIcon
									onClick={() => handleClose(excludedFiltersValue, 'exclude')}
								/>
								<StyledTypography>
									{getFilterLabelById(excludedFiltersValue[0])}-
									{filerValues.join(', ')}
								</StyledTypography>
							</StyledBox>
						)
					);
				})}
			</BoxContiner>
		</Box>
	);
};

export default SelectedFilters;
