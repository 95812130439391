import React, { useEffect, SetStateAction, Dispatch } from 'react';
import { orderBy } from 'lodash';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Grid,
	DialogTitle,
	Dialog,
	DialogContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavigateFunction, useNavigate } from 'react-router';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { tableHead } from '../../utils/Filter';
import { PageRows, QUE_JOURNEY_STEPS, Numbers } from '../../utils/Enum';
import {
	ISurveyConfigurationData,
	ISurveyListingDataItem,
} from '../../types/CommonTypes';
import { ReactComponent as ArrowCircleAction } from '../../assets/images/Arrow_Circle_Action.svg';
import { ReactComponent as CopyLogo } from '../../assets/images/Copy-logo.svg';
import { ReactComponent as CopyOffLogo } from '../../assets/images/Copy-logo-off.svg';
import { ReactComponent as MailLogo } from '../../assets/images/Mail-logo.svg';
import { ReactComponent as MailOffLogo } from '../../assets/images/Mail-logo-off.svg';
import { ReactComponent as EditLogo } from '../../assets/images/Edit-logo.svg';
import { ReactComponent as EditOffLogo } from '../../assets/images/Edit-logo-off.svg';
import { ReactComponent as ResultsLogo } from '../../assets/images/Result-logo.svg';
import { ReactComponent as ResultsOffLogo } from '../../assets/images/Result-logo-off.svg';
import { ReactComponent as DownArrow } from '../../assets/images/Doarrow-narrow-up.svg';
import { ReactComponent as FilterLogo } from '../../assets/images/filter-funnel-02.svg';
import { ReactComponent as DeactivateLogo } from '../../assets/images/Deactivate-logo.svg';
import { ReactComponent as ActiveFilterLogo } from '../../assets/images/Enablefilter-funnel.svg';
import {
	getSurveyData,
	pageIndex,
	surveyModifiedListingData,
	setModifiedSurveyResponse,
	setPageIndex,
	setRowsPerPageValue,
	configurationData,
	questionnaireDataAPILoadingStatus,
	setQuestionnaireDataStatus,
	getQuestionnaireByIdData,
	questionnaireDataByIdAPILoadingStatus,
	setQuestionnaireDataByIdStatus,
	setQuestionnaireStatus,
	updateSurveyQuestionnaire,
	updateUploadedAudienceListingData,
	surveyQuestionnaireData,
	initialState,
	getQuestionnaireDeactivatedData,
	questionnaireDeactivatedStatus,
	getFilterDate,
	setFilterDate,
	CopyQuestionnaireDetail,
	questionnaireCopyDataAPILoadingStatus,
	setQuestionnaireCopyDataStatus,
	setQuestionnaireId,
	questionnaireId,
} from '../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import FilterCalender from '../FilterCalender';
import { messages } from '../../utils/Messages';
import { commonLabels, notificationsLabels } from '../../utils/CommonLabels';
import FallbackUI from '../common/FallbackUi';
import Loader from '../../components/Loader';
import {
	API_STATUS_CONSTANT,
	SURVEY_STATUS,
} from '../../constants/StepConstants';
import { AppDispatch } from '../../app/Store';
import { URL_CONSTANT } from '../../utils/PathConstants';
import GenericModalPopup from '../common/GenericModalPopup';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import { isCurrentUser } from '../../utils/Helpers';
import StyledTooltip from '../StyledTooltip';

const SurveyTable: React.FC<{ type: string }> = (props: { type: string }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const surveyTablePageIndex: number = useAppSelector(pageIndex);
	const deactivatedStatus: string = useAppSelector(
		questionnaireDeactivatedStatus
	);
	const selectedQuestionnaireId: string = useAppSelector(questionnaireId);
	const surveyListDataArray: ISurveyListingDataItem[] = useAppSelector(
		surveyModifiedListingData
	);
	const configs = useAppSelector(configurationData);
	const date = useAppSelector(getFilterDate);
	const surveyListCount = surveyListDataArray.length;
	const isAdmin = configs?.rolePermissions?.isAdmin;
	const rowPage = configs?.rows_per_page?.map((i) => Number(i));
	const [page, setPage] = React.useState(surveyTablePageIndex);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		rowPage ? rowPage[0] : PageRows.firstOption
	);
	const [dateCreatedOpen, setDateCreatedOpen] = React.useState(false);
	const [startDateOpen, setStartDateOpen] = React.useState(false);

	const [surveyNameSortObj, setSurveyNameSortObj] = React.useState('desc');
	const [surveyCategorySortObj, setSurveyCategorySortObj] =
		React.useState('desc');
	const [surveyIdSortObj, setSurveyIdSortObj] = React.useState('desc');
	const [createdByObj, setCreatedByObj] = React.useState('desc');
	const [statusObj, setStatusObj] = React.useState('desc');
	const [dateCreatedObj, setDateCreatedObj] = React.useState('desc');
	const [startDateObj, setStartDateObj] = React.useState('desc');
	const [showLoader, setShowLoader] = React.useState(false);
	const [status, setStatus] = React.useState('');
	const [deactivePopupOpen, setDeactivePopupOpen] = React.useState(false);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const [leftBtn, setLeftBtn] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const [warningPopupOpen, setWarningPopupOpen] = React.useState(false);
	const [copyPopupOpen, setCopyPopupOpen] = React.useState(false);
	const [copyErrorPopupOpen, setCopyErrorPopupOpen] = React.useState(false);

	const handleEditSurvey = (row: ISurveyListingDataItem) => {
		setStatus(row.status);
		checkRow(row, dispatch, setShowLoader);
	};
	const handleCopy = (row: ISurveyListingDataItem) => {
		copyRow(row, dispatch, setShowLoader, configs);
	};

	useEffect(() => {
		dispatch(
			updateSurveyQuestionnaire({
				...surveyQuestionnaireInfo,
				surveyFormsDetails:
					initialState.surveyQuestionnaireData.surveyFormsDetails,
				surveyFormsAudience:
					initialState.surveyQuestionnaireData.surveyFormsAudience,
				surveyFormsQuestion:
					initialState.surveyQuestionnaireData.surveyFormsQuestion,
				notificationConfig:
					initialState.surveyQuestionnaireData.notificationConfig,
				currentStep: QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS,
			})
		);
		dispatch(
			setFilterDate({
				startDate: '',
				endDate: '',
				createdAtStartDate: '',
				createdAtEndDate: '',
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (configs?.user?.userEmailId) {
			setShowLoader(true);
			const surveyListingReq = {
				type: props.type,
				createdBy: configs?.user?.userEmailId,
			};
			dispatch(getSurveyData(surveyListingReq));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDateCreatedOpen(false);
		setStartDateOpen(false);
		setPage(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyListDataArray]);

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		dispatch(setPageIndex(newPage));
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		dispatch(setRowsPerPageValue(rowsPerPage));
		dispatch(setPageIndex(0));
	};

	const handleShowQuestionnaireResults = (id: string | undefined) => {
		if (id !== undefined) {
			navigate(`/questionnaire-results/${id}`);
		} else {
			return;
		}
	};

	const APICompletionStatus = useAppSelector(questionnaireDataAPILoadingStatus);
	useEffect(() => {
		checkAPICompletion(APICompletionStatus, setShowLoader, dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	const QuestionnaireAPIStatus = useAppSelector(
		questionnaireDataByIdAPILoadingStatus
	);
	const copyStatus = useAppSelector(questionnaireCopyDataAPILoadingStatus);
	useEffect(() => {
		checkQuestionnaireStatus(
			QuestionnaireAPIStatus,
			dispatch,
			setShowLoader,
			navigate,
			status
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [QuestionnaireAPIStatus]);

	useEffect(() => {
		checkQuestionnaireCopyStatus(copyStatus, dispatch, setShowLoader);
		updateCopyStatus(copyStatus);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [copyStatus]);
	const HeadText = styled('div')({
		width: 'auto',
		minHeight: '22px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		letterSpacing: '1px',
		textAlign: 'left',
		display: 'flex',
		cursor: 'pointer',
		svg: {
			cursor: 'pointer',
			width: '22px',
			height: '18px',
		},
		'.up-arrow': {
			svg: {
				transform: 'rotate(180deg)',
			},
		},
	});

	const StyledAuthorCell = styled(TableCell)({
		whiteSpace: 'nowrap',
	});

	const StyledDueDateCell = styled(TableCell)({
		whiteSpace: 'nowrap',
	});

	const handleClose = () => {
		setDateCreatedOpen(false);
		setStartDateOpen(false);
		setDeactivePopupOpen(false);
		setWarningPopupOpen(false);
	};

	const okMethod = () => {
		setCopyPopupOpen(false);
		setCopyErrorPopupOpen(false);
		navigate('/');
		navigate(0);
	};
	const customSort = (
		firstItem: ISurveyListingDataItem,
		secondItem: ISurveyListingDataItem
	): number => {
		const firstItemParts = firstItem.questionnaireId.split('_');
		const secondItemParts = secondItem.questionnaireId.split('_');
		if (firstItemParts[0] < secondItemParts[0]) return -1;
		if (firstItemParts[0] > secondItemParts[0]) return 1;
		if (firstItemParts[1] < secondItemParts[1]) return -1;
		if (firstItemParts[1] > secondItemParts[1]) return 1;
		const firstItemNumber = parseInt(firstItemParts[Numbers.two], 10);
		const secondItemNumber = parseInt(secondItemParts[Numbers.two], 10);
		return firstItemNumber - secondItemNumber;
	};

	const handleClickOpen = (row: ISurveyListingDataItem) => {
		setWarningPopupOpen(true);
		setDeactivePopupOpen(false);
		setLeftBtn(true);
		setMessage(
			`${notificationsLabels.deactivatedWarningMessage} ${row.questionnaireName}?`
		);
		dispatch(setQuestionnaireId(row?.id as string));
	};

	const handleDeactiveSurvey = (id: string) => {
		setWarningPopupOpen(false);
		const surveyDeactiveReq = {
			id: id,
		};
		dispatch(getQuestionnaireDeactivatedData(surveyDeactiveReq));
	};

	useEffect(() => {
		updateDeactivatePopupVisibility(deactivatedStatus);
	}, [deactivatedStatus]);

	const updateDeactivatePopupVisibility = (currentStatus: string) => {
		if (
			currentStatus === API_STATUS_CONSTANT.SUCCESS ||
			currentStatus === API_STATUS_CONSTANT.FAILED
		) {
			setShowLoader(false);
			setDeactivePopupOpen(true);
		} else {
			setShowLoader(true);
			setDeactivePopupOpen(false);
		}
	};

	const handleRefresh = () => {
		navigate('/');
		navigate(0);
	};

	const updateCopyStatus = (currentCopyStatus: string) => {
		if (currentCopyStatus === API_STATUS_CONSTANT.LOADING) {
			setShowLoader(true);
			setCopyPopupOpen(false);
		} else if (currentCopyStatus === API_STATUS_CONSTANT.SUCCESS) {
			setShowLoader(false);
			setCopyPopupOpen(true);
		} else if (currentCopyStatus === API_STATUS_CONSTANT.FAILED) {
			setShowLoader(false);
			setCopyErrorPopupOpen(true);
		} else {
			setShowLoader(false);
		}
	};
	const showDeactivatePopup = (msg: string) => {
		if (
			deactivatedStatus === API_STATUS_CONSTANT.SUCCESS ||
			deactivatedStatus === API_STATUS_CONSTANT.FAILED
		) {
			return (
				<GenericModalPopup
					openProp={deactivePopupOpen}
					msgBody={msg}
					mainMsg=""
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleRefresh}
					leftBtnHandler={handleClose}
				/>
			);
		}
	};

	const showCopyPopup = (msg: string) => {
		return (
			<GenericModalPopup
				openProp={true}
				msgBody={msg}
				mainMsg=""
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={okMethod}
				leftBtnHandler={okMethod}
			/>
		);
	};

	return surveyListCount > 0 ? (
		<>
			<Loader isLoading={showLoader} />
			<TableContainer
				component={Paper}
				elevation={1}
				sx={{ borderRadius: '12px', height: '420px' }}
			>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<TableCell>
								<HeadText
									onClick={() => {
										if (surveyNameSortObj === 'desc') {
											setSurveyNameSortObj('asc');
											dispatch(
												setModifiedSurveyResponse(
													orderBy(
														surveyListDataArray,
														['questionnaireName'],
														['asc']
													)
												)
											);
										} else {
											setSurveyNameSortObj('desc');
											dispatch(
												setModifiedSurveyResponse(
													orderBy(
														surveyListDataArray,
														['questionnaireName'],
														['desc']
													)
												)
											);
										}
									}}
								>
									{tableHead.label.QUESTIONNAIRE_NAME}
									{surveyNameSortObj === 'desc' && (
										<div className="up-arrow">
											<DownArrow
												onClick={() => {
													setSurveyNameSortObj('asc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(
																surveyListDataArray,
																['questionnaireName'],
																['asc']
															)
														)
													);
												}}
											/>
										</div>
									)}
									{surveyNameSortObj === 'asc' && (
										<DownArrow
											onClick={() => {
												setSurveyNameSortObj('desc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(
															surveyListDataArray,
															['questionnaireName'],
															['desc']
														)
													)
												);
											}}
										/>
									)}
								</HeadText>
							</TableCell>

							<TableCell>
								<HeadText
									sx={{ width: '180px' }}
									onClick={() => {
										if (surveyCategorySortObj === 'desc') {
											setSurveyCategorySortObj('asc');
											dispatch(
												setModifiedSurveyResponse(
													orderBy(surveyListDataArray, ['location'], ['asc'])
												)
											);
										} else {
											setSurveyCategorySortObj('desc');
											dispatch(
												setModifiedSurveyResponse(
													orderBy(surveyListDataArray, ['location'], ['desc'])
												)
											);
										}
									}}
								>
									{tableHead.label.QUESTIONNAIRE_CATEGORY}
									{surveyCategorySortObj === 'desc' && (
										<div className="up-arrow">
											<DownArrow
												onClick={() => {
													setSurveyCategorySortObj('asc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(
																surveyListDataArray,
																['location'],
																['asc']
															)
														)
													);
												}}
											/>
										</div>
									)}
									{surveyCategorySortObj === 'asc' && (
										<DownArrow
											onClick={() => {
												setSurveyCategorySortObj('desc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(surveyListDataArray, ['location'], ['desc'])
													)
												);
											}}
										/>
									)}
								</HeadText>
							</TableCell>
							<TableCell>
								<HeadText
									onClick={() => {
										if (surveyIdSortObj === 'desc') {
											setSurveyIdSortObj('asc');
											dispatch(
												setModifiedSurveyResponse(
													[...surveyListDataArray].sort(customSort)
												)
											);
										} else {
											setSurveyIdSortObj('desc');
											dispatch(
												setModifiedSurveyResponse(
													[...surveyListDataArray].sort(customSort).reverse()
												)
											);
										}
									}}
								>
									{tableHead.label.QUESTIONNAIRE_ID}
									{surveyIdSortObj === 'desc' && (
										<div className="up-arrow">
											<DownArrow
												onClick={() => {
													setSurveyIdSortObj('asc');
													dispatch(
														setModifiedSurveyResponse(
															[...surveyListDataArray].sort(customSort)
														)
													);
												}}
											/>
										</div>
									)}
									{surveyIdSortObj === 'asc' && (
										<DownArrow
											onClick={() => {
												setSurveyIdSortObj('desc');
												dispatch(
													setModifiedSurveyResponse(
														[...surveyListDataArray].sort(customSort).reverse()
													)
												);
											}}
										/>
									)}
								</HeadText>
							</TableCell>
							<TableCell>
								<HeadText
									onClick={() => {
										if (createdByObj === 'desc') {
											setCreatedByObj('asc');
											dispatch(
												setModifiedSurveyResponse(
													orderBy(
														surveyListDataArray,
														['questionnaireAuthor'],
														['asc']
													)
												)
											);
										} else {
											setCreatedByObj('desc');
											dispatch(
												setModifiedSurveyResponse(
													orderBy(
														surveyListDataArray,
														['questionnaireAuthor'],
														['desc']
													)
												)
											);
										}
									}}
								>
									{' '}
									{tableHead.label.CREATE_BY}
									{createdByObj === 'desc' && (
										<div className="up-arrow">
											<DownArrow
												onClick={() => {
													setCreatedByObj('asc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(
																surveyListDataArray,
																['questionnaireAuthor'],
																['asc']
															)
														)
													);
												}}
											/>
										</div>
									)}
									{createdByObj === 'asc' && (
										<DownArrow
											onClick={() => {
												setCreatedByObj('desc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(
															surveyListDataArray,
															['questionnaireAuthor'],
															['desc']
														)
													)
												);
											}}
										/>
									)}
								</HeadText>
							</TableCell>
							<TableCell>
								<Grid item display="flex">
									<HeadText
										onClick={() => {
											if (dateCreatedObj === 'desc') {
												setDateCreatedObj('asc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(surveyListDataArray, ['createdAt'], ['asc'])
													)
												);
											} else {
												setDateCreatedObj('desc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(
															surveyListDataArray,
															['createdAt'],
															['desc']
														)
													)
												);
											}
										}}
									>
										{tableHead.label.CREATED_DATE}
										{dateCreatedObj === 'desc' && (
											<div className="up-arrow">
												<DownArrow
													onClick={() => {
														setDateCreatedObj('asc');
														dispatch(
															setModifiedSurveyResponse(
																orderBy(
																	surveyListDataArray,
																	['createdAt'],
																	['asc']
																)
															)
														);
													}}
												/>
											</div>
										)}
										{dateCreatedObj === 'asc' && (
											<DownArrow
												onClick={() => {
													setDateCreatedObj('desc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(
																surveyListDataArray,
																['createdAt'],
																['desc']
															)
														)
													);
												}}
											/>
										)}
									</HeadText>
									{!dateCreatedOpen &&
									date.createdAtStartDate === '' &&
									date.createdAtEndDate === '' ? (
										<FilterLogo
											className="cursor-pointer"
											onClick={() => setDateCreatedOpen(true)}
										/>
									) : (
										<ActiveFilterLogo
											className="cursor-pointer"
											onClick={() => setDateCreatedOpen(true)}
										/>
									)}
								</Grid>
							</TableCell>
							<Dialog
								open={dateCreatedOpen}
								onClose={() => setDateCreatedOpen(false)}
								style={{ marginTop: '150px' }}
							>
								<DialogTitle variant="h5">Select Created Date</DialogTitle>
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
									}}
								>
									<CloseIcon />
								</IconButton>
								<DialogContent>
									<FilterCalender type={'createdAt'} />
								</DialogContent>
							</Dialog>
							<StyledTooltip
								title={tableHead.tooltips.START_DATE}
								placement="top"
							>
								<TableCell>
									<Grid item display="flex">
										<HeadText
											onClick={() => {
												if (startDateObj === 'desc') {
													setStartDateObj('asc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(
																surveyListDataArray,
																['startDate'],
																['asc']
															)
														)
													);
												} else {
													setStartDateObj('desc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(
																surveyListDataArray,
																['startDate'],
																['desc']
															)
														)
													);
												}
											}}
										>
											{tableHead.label.START_DATE}

											{startDateObj === 'desc' && (
												<div className="up-arrow">
													<DownArrow
														onClick={() => {
															setStartDateObj('asc');
															dispatch(
																setModifiedSurveyResponse(
																	orderBy(
																		surveyListDataArray,
																		['startDate'],
																		['asc']
																	)
																)
															);
														}}
													/>
												</div>
											)}
											{startDateObj === 'asc' && (
												<DownArrow
													onClick={() => {
														setStartDateObj('desc');
														dispatch(
															setModifiedSurveyResponse(
																orderBy(
																	surveyListDataArray,
																	['startDate'],
																	['desc']
																)
															)
														);
													}}
												/>
											)}
										</HeadText>

										{!startDateOpen &&
										date.startDate === '' &&
										date.endDate === '' ? (
											<FilterLogo
												className="cursor-pointer"
												onClick={() => setStartDateOpen(true)}
											/>
										) : (
											<ActiveFilterLogo
												className="cursor-pointer"
												onClick={() => setStartDateOpen(true)}
											/>
										)}
									</Grid>
								</TableCell>
							</StyledTooltip>
							<Dialog
								open={startDateOpen}
								onClose={() => setStartDateOpen(false)}
								style={{ marginTop: '150px' }}
							>
								<DialogTitle variant="h5">Select Start Date</DialogTitle>
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
									}}
								>
									<CloseIcon />
								</IconButton>
								<DialogContent>
									<FilterCalender type={'startDate'} />
								</DialogContent>
							</Dialog>
							<StyledTooltip
								title={tableHead.tooltips.DUE_DATE}
								placement="top"
							>
								<TableCell>
									<HeadText>{tableHead.label.END_DATE}</HeadText>
								</TableCell>
							</StyledTooltip>
							<StyledTooltip title={tableHead.tooltips.STATUS} placement="top">
								<TableCell>
									<HeadText
										onClick={() => {
											if (statusObj === 'desc') {
												setStatusObj('asc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(surveyListDataArray, ['status'], ['asc'])
													)
												);
											} else {
												setStatusObj('desc');
												dispatch(
													setModifiedSurveyResponse(
														orderBy(surveyListDataArray, ['status'], ['desc'])
													)
												);
											}
										}}
									>
										{' '}
										{tableHead.label.STATUS}
										{statusObj === 'desc' && (
											<div className="up-arrow">
												<DownArrow
													onClick={() => {
														setStatusObj('asc');
														dispatch(
															setModifiedSurveyResponse(
																orderBy(
																	surveyListDataArray,
																	['status'],
																	['asc']
																)
															)
														);
													}}
												/>
											</div>
										)}
										{statusObj === 'asc' && (
											<DownArrow
												onClick={() => {
													setStatusObj('desc');
													dispatch(
														setModifiedSurveyResponse(
															orderBy(surveyListDataArray, ['status'], ['desc'])
														)
													);
												}}
											/>
										)}
									</HeadText>
								</TableCell>
							</StyledTooltip>
							<TableCell>
								<HeadText> {tableHead.label.ACTION}</HeadText>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{surveyListDataArray
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row: ISurveyListingDataItem) => (
								<TableRow
									key={row.questionnaireId}
									hover
									tabIndex={-1}
									sx={{ background: '#F9F8F7' }}
								>
									<TableCell className="break-all" component="th" scope="row">
										{row.questionnaireName}
									</TableCell>
									<TableCell>{row.location}</TableCell>
									<TableCell>{row.questionnaireId}</TableCell>
									<StyledAuthorCell>{row.questionnaireAuthor}</StyledAuthorCell>
									<TableCell>{row.createdAt}</TableCell>
									<TableCell>{row.startDate}</TableCell>
									<StyledDueDateCell>
										{Number(row?.duration) > 0
											? `${row?.duration} ${commonLabels.days}`
											: row.dueDate}
									</StyledDueDateCell>
									<TableCell>{row.status}</TableCell>
									<TableCell>
										<StyledTooltip
											title={
												<div>
													{isAdmin && (
														<div>
															{isCurrentUser(row?.createdBy) &&
															row.status === 'In Draft' ? (
																<>
																	<span onClick={() => handleEditSurvey(row)}>
																		<EditLogo />
																		{commonLabels.edit}
																	</span>
																	<br />
																</>
															) : (
																<>
																	<span className="disabled-action">
																		<EditOffLogo />
																		{commonLabels.edit}
																	</span>
																	<br />
																</>
															)}
														</div>
													)}
													<div>
														{checkRowStatus(row) ||
														row.status === SURVEY_STATUS.COMPLETED ? (
															<>
																<span onClick={() => handleCopy(row)}>
																	<CopyLogo />
																	{commonLabels.copy}
																</span>
																<br />
															</>
														) : (
															<>
																<span className="disabled-action">
																	<CopyOffLogo />
																	{commonLabels.copy}
																</span>
																<br />
															</>
														)}
													</div>
													{isAdmin && (
														<div>
															{isCurrentUser(row?.createdBy) &&
															checkRowStatus(row) ? (
																<>
																	<span onClick={() => handleEditSurvey(row)}>
																		<MailLogo />
																		{commonLabels.notification}
																	</span>
																	<br />
																</>
															) : (
																<>
																	<span className="disabled-action">
																		<MailOffLogo />
																		{commonLabels.notification}
																	</span>
																	<br />
																</>
															)}
														</div>
													)}
													{checkRowCompletedStatus(row) ? (
														<>
															<span
																onClick={() =>
																	handleShowQuestionnaireResults(row.id)
																}
															>
																<ResultsLogo />
																{commonLabels.results}
															</span>
															<br />
														</>
													) : (
														<>
															<span className="disabled-action">
																<ResultsOffLogo />
																{commonLabels.results}
															</span>
															<br />
														</>
													)}
													{isAdmin && (
														<div>
															{isCurrentUser(row?.createdBy) &&
																(checkRowStatus(row) ||
																	row.status === SURVEY_STATUS.ACTIVE ||
																	row.status === SURVEY_STATUS.PUBLISHED) && (
																	<span onClick={() => handleClickOpen(row)}>
																		<DeactivateLogo />
																		{commonLabels.deactivate}
																	</span>
																)}
														</div>
													)}
												</div>
											}
											placement="right-start"
										>
											<ArrowCircleAction />
										</StyledTooltip>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{deactivatedStatus === 'success'
				? showDeactivatePopup(commonLabels.deactivatedText)
				: showDeactivatePopup(notificationsLabels.error)}

			{copyPopupOpen && showCopyPopup(commonLabels.copyText)}
			{copyErrorPopupOpen && showCopyPopup(notificationsLabels.error)}

			<GenericConfirmationPopup
				open={warningPopupOpen}
				msgBody={notificationsLabels.deactivateNoteMessage}
				title={message}
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={leftBtn}
				rightBtnHandler={() => handleDeactiveSurvey(selectedQuestionnaireId)}
				leftBtnHandler={handleClose}
			/>

			<Grid container sx={{ justifyContent: 'flex-end' }}>
				<StyledTooltip
					title={tableHead.tooltips.ROWS_PER_PAGE}
					placement="left"
				>
					<TablePagination
						rowsPerPageOptions={rowPage}
						component="div"
						count={surveyListCount}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</StyledTooltip>
			</Grid>
		</>
	) : (
		<>
			<Loader isLoading={showLoader} />
			<FallbackUI fallbackText={messages.common.NOT_QUESTIONNAIRE_FOUND} />
		</>
	);
};
export default SurveyTable;
const checkAPICompletion = (
	APICompletionStatus: string,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	dispatch: AppDispatch
) => {
	if (APICompletionStatus === API_STATUS_CONSTANT.FAILED) {
		setLoaderOpen(false);
		dispatch(setQuestionnaireDataStatus(API_STATUS_CONSTANT.IDLE));
	} else if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
		setLoaderOpen(false);
	} else {
		// No code- added for SONAR
	}
};

const checkRow = (
	row: ISurveyListingDataItem,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>
) => {
	if (row.id) {
		dispatch(
			getQuestionnaireByIdData({
				id: row.id,
			})
		);
		dispatch(
			updateUploadedAudienceListingData({
				blobCache: '',
				questionnaireId: row.id,
				pageSize: 10,
				pageNumber: 1,
			})
		);
	}
	setLoaderOpen(true);
};
const copyRow = (
	row: ISurveyListingDataItem,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	configs: ISurveyConfigurationData
) => {
	if (row.id) {
		dispatch(
			CopyQuestionnaireDetail({
				id: row.id,
				email: configs?.user?.userEmailId,
			})
		);
	}
	setLoaderOpen(true);
};

const checkRowStatus = (row: ISurveyListingDataItem) => {
	return (
		row.status === SURVEY_STATUS.PUBLISHED ||
		row.status === SURVEY_STATUS.ACTIVE
	);
};

const checkRowCompletedStatus = (row: ISurveyListingDataItem) => {
	return (
		row.status === SURVEY_STATUS.COMPLETED ||
		row.status === SURVEY_STATUS.ACTIVE
	);
};

const checkQuestionnaireStatus = (
	QuestionnaireAPIStatus: string,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	navigate: NavigateFunction,
	status: string
) => {
	if (QuestionnaireAPIStatus === API_STATUS_CONSTANT.SUCCESS) {
		setLoaderOpen(false);
		navigate(URL_CONSTANT.EDIT_SURVEY);
		dispatch(setQuestionnaireStatus(status));
		dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.IDLE));
	}
	if (QuestionnaireAPIStatus === API_STATUS_CONSTANT.FAILED) {
		dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.IDLE));
		setLoaderOpen(false);
	}
};
const checkQuestionnaireCopyStatus = (
	copyStatus: string,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>
) => {
	if (copyStatus === API_STATUS_CONSTANT.SUCCESS) {
		setLoaderOpen(false);
		dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.IDLE));
	}
	if (copyStatus === API_STATUS_CONSTANT.FAILED) {
		dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.IDLE));
		setLoaderOpen(false);
	}
};
