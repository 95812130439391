import { Box, Card, CardContent, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
	configurationData,
	setIsLastScreenEndUser,
} from '../../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { ReactComponent as ArrowBlack } from '../../../assets/images/Arrow-Black.svg';
import { STEPTheme } from '../../../utils/Theme';
import { questionnaireResultsLabels } from '../../../utils/CommonLabels';
import { URL_CONSTANT } from '../../../utils/PathConstants';
import { IType } from '../../../types/CommonTypes';
import {
	questionnaireData,
	getQuestionnaireData,
} from '../../../redux/QuestionnarieUserSlice';
import HTMLRenderer from './HtmlRenderer';

const QuestionnaireName = styled(Box)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	color: STEPTheme.colors.white,
	display: 'flex',
	alignItems: 'center',
});
const IconStyle = styled(ArrowBlack)({
	marginRight: '20px',
	cursor: 'pointer',
});
const QuestionnaireNameBox = styled(Box)({
	backgroundColor: STEPTheme.colors.primary,
	padding: '65px',
	overflow: 'hidden',
});
const Instruction: React.FC<unknown> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id: questionnaireId } = useParams();
	const QuestionnaireInfo = useAppSelector(questionnaireData);
	const configs = useAppSelector(configurationData);
	const [questionnaireName, setQuestionaaireName] = useState('');
	const [instructionDetails, setInstructionDetails] = useState('');
	const [isRespondedByWWID, setIsRespondedByWWID] = useState<string>('False');
	const [isQuestionnaireInfoReceived, setQuestionnaireInfoReceived] =
		useState<boolean>(false);
	useEffect(() => {
		if (!isQuestionnaireInfoReceived && configs?.user?.userEmailId) {
			const endUserReq: IType = {
				id: questionnaireId ? questionnaireId : '',
				email: configs?.user?.userEmailId,
			};
			dispatch(getQuestionnaireData(endUserReq));
			setQuestionnaireInfoReceived(true);
		}
	}, [configs, dispatch, questionnaireId, isQuestionnaireInfoReceived]);

	useEffect(() => {
		setIsRespondedByWWID(QuestionnaireInfo?.data?.isResponsedByWWID);
		setQuestionaaireName(QuestionnaireInfo?.data?.questionnaireName);
		setInstructionDetails(QuestionnaireInfo?.data?.questionnaireInstructions);
	}, [QuestionnaireInfo]);

	const handleBack = () => {
		if (isRespondedByWWID && isRespondedByWWID !== 'False') {
			dispatch(setIsLastScreenEndUser(true));
			navigate(`/`);
		} else {
			navigate(`${URL_CONSTANT.END_USER}/${questionnaireId}`);
		}
	};
	return (
		<Box>
			<QuestionnaireNameBox>
				<QuestionnaireName>
					<IconStyle onClick={handleBack} />
					{questionnaireName}
				</QuestionnaireName>
			</QuestionnaireNameBox>
			<Card
				raised={true}
				sx={{
					position: 'relative',
					maxHeight: '300px',
					overflow: 'Auto',
					width: '80%',
					height: '30%',
					padding: '2% 2% 2% 3%',
					marginLeft: '8%',
					marginBottom: '3%',
					marginTop: '-30px',
					borderRadius: '16px',
				}}
			>
				<CardContent>
					<h3>{questionnaireResultsLabels.instructions}</h3>
					<Typography>
						<HTMLRenderer htmlString={instructionDetails} />
					</Typography>
				</CardContent>
			</Card>
		</Box>
	);
};
export default Instruction;
