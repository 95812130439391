export const commonLabels: { [key: string]: string } = {
	save: 'Save',
	enterTxt: 'Enter Text',
	addUrl: 'Add Url',
	cancel: 'Cancel',
	submit: 'Submit',
	allRightsReserved: 'All Rights Reserved',
	yes: 'Yes',
	no: 'No',
	ok: 'OK',
	saveAsDraft: 'Save As Draft',
	saveNext: 'Save & Next',
	saveProceed: 'Save & Proceed',
	createdByMe: 'Created By Me',
	allQuestionnaire: 'All Questionnaire',
	edit: 'Edit',
	copy: 'Copy',
	notification: 'Notification',
	results: 'Results',
	result: 'Result',
	deactivate: 'Deactivate',
	backToDTH: 'Back To DTH',
	logout: 'Logout',
	days: 'Days',
	select: 'Select',
	search: 'Search',
	saveAsDraftText: 'Your changes have been saved successfully in the draft',
	deactivatedText: 'The questionnaire has been successfully deactivated',
	copyText: 'The questionnaire has been successfully copied',
	addQuestionnaireDetails: 'Add Questionnaire Details',
	questionnaireNameRequired: 'Questionnaire name is required',
	questionnaireChar: 'Questionnaire name should be at most 100 characters',
	questionnaireDes: 'Questionnaire description is required',
	questionnaireDesChar:
		'Questionnaire description should be at most 250 characters',
	questionnaireType: 'Questionnaire type is required',
	startDateReq: 'Start date is required',
	date: 'Date',
	duration: 'Duration',
	selectedUserCriteria: 'Selected User Criteria',
	includedUserCriteria: 'Included User Criteria',
	excludedUserCriteria: 'Excluded User Criteria',
	thirtyDays: '30 Days',
	sixtyDays: '60 Days',
	ninetyDays: '90 Days',
	noSurveyFound: 'No Survey Found',
	dueDateReq: 'Due date is required',
	selectReason: 'Select Reason',
	addValidDropDownOptionFile:
		'Please upload a valid file. It should have a column with header as options',
	incorrectQuestionnaireNameFormatErrorMsg: 'Invalid Questionnaire Name format',
	incorrectQuestionnaireLocationFormatErrorMsg:
		'Invalid Owning Location format',
	incorrectQuestionnaireAuthorFormatErrorMsg: 'Invalid Author Name format',
	incorrectQuestionnaireStatusFormatErrorMsg: 'Invalid Status format',
	incorrectQuestionnaireDescFormatErrorMsg: 'Please Provide a correct format',
	questionnaire: 'Questionnaire',
	sessionExpiredTitle: 'Your Session has Expired',
	sessionExpiredText:
		'To protect privacy and enhance security, the page you are trying to access is no longer available. You will be redirected to DTH',
	redirectButtonLabel: 'Redirect',
	redirectingMsg: 'Redirecting in 00:0',
	waitMsg: 'Please Wait..',
};

export const notificationsLabels: { [key: string]: string } = {
	reminderMail: 'Reminder Mail',
	preview: 'Preview',
	selectTriggerDays: 'Select Trigger Days',
	dueDate: 'Due Date',
	save: 'Save',
	configureNotificationAlerts: 'Configure Notification Alerts',
	initialMail: 'Initial Mail',
	startDate: 'Questionnaire start date',
	overdueMail: 'Overdue Mail',
	confirmSubmitText: 'Are you sure you want to publish this Questionnaire?',
	publishSurveyConfirmationText: 'NOTE: No edits can be made after publishing.',
	totalSelectedUser: 'Total Selected Users:',
	startDatePopupLabel: 'Start Date:',
	notificationTriggerDays: 'Selected Trigger Days',
	days: 'Days',
	error: 'Something went Wrong. Please try again.',
	networkError:
		'Too many users are connected to the application. Please try after sometime.',
	errorTitle: 'Error Message',
	errorMessage: 'No Trigger Days Selected',
	deactivatedWarningMessage: 'Are you sure you want to deactivate',
	deactivateNoteMessage:
		'NOTE: You will not be able to re-activate once it has been deactivated.',
	initialMailTooltip:
		'Mandatory notification sent to users on questionnaire start date',
	reminderMailTooltip:
		'Mandatory notification sent to incomplete users on selected Trigger Days BEFORE questionnaire due date.',
	overdueMailTooltip:
		'Optional notification sent to incomplete users on selected Trigger Dates AFTER questionnaire due date.',
	previewTooltip: 'View the  draft Notification content.',
	noTooltip:
		'Questionnaire will remain in-draft and will not be sent to users on questionnaire start date.',
	yesTooltip:
		'Questionnaire will be sent to users on questionnaire start date.',
};

export const stepsLabels: Array<string> = [
	'DETAILS',
	'AUDIENCE',
	'QUESTIONS',
	'PREVIEW',
	'NOTIFICATION',
	'PUBLISH',
];

export const uploadExcelLabels: { [key: string]: string } = {
	results: 'Results',
	uploadFromExcel: 'Upload from Excel',
	fallbackText: 'Upload from Excel to View Results',
	totalAudience: 'Total Audience',
	noResults: 'No Results',
	audience: 'Audience',
	audienceMaxRowsMessage:
		'Export file is greater than 100K rows. Exit this message to return to the Manage Filters and provide further filters to reduce the file size.',
};

export const previewQuestionnaireLabels: { [key: string]: string } = {
	previewQuestionnaire: 'Preview Questionnaire',
	questionnaireName: 'Questionnaire Name',
	dueDate: 'Due Date',
	startDate: 'Start Date',
	totalAudience: 'Total Audience',
};

export const questionTypeLabels: { [key: string]: string } = {
	yesOrNo: 'Yes & No',
	choice: 'Multiple Choice',
	select: 'Multi Select',
	open: 'Open Ended',
	dropdown: 'Dropdown',
};

export const questionnaireResultsLabels: { [key: string]: string } = {
	recordSubmittedMsg: 'Your records have been successfully submitted',
	someThingWrongMsg: 'Some thing went wrong',
	questionnaireResults: 'Questionnaire Results',
	lastRefreshDate: 'Last Refresh Date',
	showingResultsFor: 'Showing results for',
	allBtnTxt: 'All',
	medTechBtnTxt: 'Med Tech',
	pharmaBtnTxt: 'Pharma',
	nonOperatingBtnTxt: 'Non-Operating',
	dataDisplayTypePercentage: 'Percentage',
	dataDisplayTypeValues: 'Values',
	generalMetricsTxt: 'General Results - Metrics - ',
	optionalResultsMetricsTxt: 'Optional Results - Metrics -',
	inScopeTxt: 'In Scope',
	outOfScopeTxt: 'Out of Scope',
	notAssessedTxt: 'Not Assessed',
	asiaPacificTxt: 'Asia-Pacific',
	emeaTxt: 'EMEA',
	northAmericaTxt: 'North America',
	latinAmericaTxt: 'Latin America',
	completedByRegionTxt: 'Completed by Region',
	CompletedOverallTxt: 'Completed Overall',
	respondedTxt: 'Responded',
	notRespondedTxt: 'Not Responded',
	lastRefreshTxt: 'Last Refresh',
	titleInScope: 'You are about to submit selected records as In Scope.',
	titleOutOfScope: 'You are about to submit selected records as Out Of Scope.',
	descriptionScope:
		'Are you sure you want to submit your selected responses for ',
	startQuestionnaire: 'Start Questionnaire',
	selectStartDate: 'Select Start Date',
	selectDueDate: 'Select Due Date',
	instructions: 'Instructions',
	completeDownload: 'Complete Download',
	downloadInProgress: 'Downloading is in progress...',
	reportDownloadErrorTitle:
		'There is no consolidated report as nobody has filled the Questionnaire yet!',
	completeDownloadTooltip: 'Export results to Excel',
};

export const EndUserTableHead = {
	label: {
		WWID: 'WWID',
		LAST_NAME: 'Last Name',
		FIRST_NAME: 'First Name',
		PARTNER_COMPANY: 'Partner Company',
		ASSESMENT_STATUS: 'Assessment Status',
	},
};

export const ActionLabel = {
	Active: 'Active',
	Completed: 'Completed',
	Complete_Questionnaire: 'Complete Questionnaire',
	Edit_Response: 'Edit Response',
};

export const ValidationErrorMsgs = {
	incorrectQuestionnaireNameFormatErrorMsg: 'Invalid Questionnaire Name format',
	incorrectQuestionnaireLocationFormatErrorMsg:
		'Invalid Owning Location format',
	incorrectQuestionnaireAuthorFormatErrorMsg: 'Invalid Author Name format',
	incorrectQuestionnaireStatusFormatErrorMsg: 'Invalid Status format',
	incorrectWWIDsFormatMsg: 'WWID must be a number',
	incorrectNotificationMailSubjectFormatMsg: 'Invalid Subject format',
	incorrectFirstNameEndUserFormatMsg: 'Invalid First name format',
	incorrectLastNameEndUserFormatMsg: 'Invalid Last name format',
	incorrectQuesTypeOpenEndUserFormatMsg: 'Invalid Question type format',
};

export const EndUserListingPageLabels = {
	columnHeaderTooltip: 'Click on the icons to sort  or filter records',
	startDateTooltip: 'First date responses can  be added or modified.',
	dueDateTooltip:
		'Last date/remaining days that responses can be added or modified.',
	rowsPerPageTooltip:
		'•Choose from dropdown options 10,25,50,100.\n•Click to scroll between pages ',
	backArrowTooltip: 'Click here to return to the questionnaire listing page.',
	checkBoxTooltip: 'Check box to select all rows on current page',
	applyTooltip: 'Click here to apply search terms/filters.',
	resetTooltip: 'Click here to clear search terms/filters.',
	rowsPerPageListingScreenTooltip:
		'•Choose from dropdown options 10,15,20,25,50,100.\n•Click to scroll between pages ',
};

export const QuestionnaireDetailsLabels = {
	questionnaireNameTooltip: ' This is the name users will see.',
	questionniareDescriptionTooltip: 'Description users will see.',
	completingQuestionnaireForTooltip:
		'Will user complete the questionnaire for themself or on behalf of their direct reports?',
	questionnaireOwingLocationTooltip:
		'Select appropriate owning location: HCC (Health Care Compliance) OR RDQ (R&D Quality).',
	questionnaireStartDateTooltip:
		'First date questionnaire available for user to respond.',
	questionnaireDueDateTooltip:
		'Last date questionnaire available for user to respond. Select calendar date or questionnaire duration in days.',
};
