/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	Typography,
	styled,
} from '@mui/material';
import { makeStyles, Theme } from '@material-ui/core';
import { ReactComponent as PreviewIcon } from '../../assets/images/preview.svg';
import { ReactComponent as DisabledPreviewIcon } from '../../assets/images/eye.svg';
import { STEPTheme } from '../../utils/Theme';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { commonLabels, notificationsLabels } from '../../utils/CommonLabels';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	configurationData,
	surveyQuestionnaireData,
	updateSurveyQuestionnaire,
} from '../../redux/SurveySlice';
import { NOTI_CONSTANT } from '../../constants/StepConstants';
import { QUE_JOURNEY_STEPS } from '../../utils/Enum';
import {
	ReminderEmailFrequencyItem,
	StyleProps,
} from '../../types/CommonTypes';
import AddTemplatePopup from '../common/AddTemplatePopup';
import StyledTooltip from '../StyledTooltip';
import Preview from './Preview';

const useStyles = makeStyles<Theme, StyleProps>({
	button: {
		fontFamily: "'JohnsonText-Regular'",
		textTransform: 'none',
		fontSize: '15px',
		color: (props) =>
			props.isPreviewEnabled
				? STEPTheme.colors.disabledPreviewText
				: STEPTheme.colors.black,
	},
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
	},
	container: {
		position: 'relative',
		bottom: '25px',
		left: '20px',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		marginLeft: '453px',
		color: STEPTheme.colors.black,
	},
	label: {
		paddingLeft: '3px',
		textTransform: 'none',
		cursor: 'pointer',
		color: (props) =>
			props.isPreviewEnabled
				? STEPTheme.colors.disabledPreviewText
				: STEPTheme.colors.black,
	},
});

const StyledGridlDateContainer = styled(Grid)({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '16px',
	fontWeight: '700',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
});

const StyledGridlTextContainer = styled(Grid)({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '12px',
});

const StyledGridlParentContainer = styled(Typography)({
	margin: 'auto',
	borderRadius: '16px 16px 0 0',
	boxShadow: '-1px 8px 6px 1px rgba(0, 0, 0, 0.16)',
	width: '80%',
	padding: '15px 40px 0px 40px',
	background: '#D5CFC9',
	marginBottom: '-5px',
});

const StyledGridrParentContainer = styled(Typography)({
	width: '80%',
	padding: '25px 40px 0 40px',
	lineHeight: '4px',
	background: '#fff',
	margin: 'auto',
	marginTop: '-20px',
	marginBottom: '20px',
	borderRadius: '0 0 16px 16px',
	boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
	height: '140px',
});

const StyledGridlHeadingContainer = styled(Grid)({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '18px',
	fontWeight: '700',
	lineHeight: '24px',
	em: {
		fontStyle: 'normal',
		color: `${STEPTheme.colors.primary}`,
	},
});

const StyledSaveButton = styled(Box)({
	width: '120px',
	height: '32px',
	top: '411px',
	left: '1222px',
	padding: '8px, 16px, 8px, 16px',
	marginLeft: '263px',
	borderRadius: '8px',
	border: '1px',
	gap: '4px',
});

const styleSaveButton = {
	width: '95px',
	height: '26px',
	marginTop: '60px',
};

const EmailSegments: React.FC<{
	type: string;
	dateValue: string;
	isRequired: boolean;
	showTriggerDays: boolean;
	dateText: string;
	headingText: string;
}> = (props: {
	type: string;
	dateValue: string;
	isRequired: boolean;
	showTriggerDays: boolean;
	dateText: string;
	headingText: string;
}) => {
	const dispatch = useAppDispatch();
	const initialFreq: string[] = [];
	const configs = useAppSelector(configurationData);
	const [templateLabel, setTemplateLabel] = useState(
		questionsLabels.addTemplate
	);
	const [labelSave, setLabelSave] = useState(questionsLabels.save);
	const [frequency, setFrequency] = useState(initialFreq);
	const addTemplateFormRef = useRef<HTMLInputElement | null>(null);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const [open, setOpen] = useState(false);
	const [isVisibility, setIsVisibility] = useState(true);
	const [isTemplateVisibility, setIsTemplateVisibility] = useState(false);
	const [preview, setPreview] = useState(false);
	const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
	const [isFrequencyAdded, setIsFrequencyAdded] = useState(true);
	const [saveClicked, setSaveClicked] = useState(false);
	const defaultFrequency: boolean[] = [];
	const [frequencyCheckbox, setFrequencyCheckbox] = useState(defaultFrequency);
	const classes = useStyles({ isPreviewEnabled: !isPreviewEnabled });

	useEffect(() => {
		if (props.type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
					notificationConfig: {
						...surveyQuestionnaireInfo.notificationConfig,
						reminderEmailFrequency: frequency,
					},
				})
			);
		} else if (props.type === NOTI_CONSTANT.NOTIFICATIO_OVERDUE_EMAIL) {
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
					notificationConfig: {
						...surveyQuestionnaireInfo.notificationConfig,
						overdueEmailFrequency: frequency,
					},
				})
			);
		} else {
			// Intentionally left empty as there is nothing to be done in this case
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [frequency]);
	useEffect(() => {
		if (props.type === NOTI_CONSTANT.NOTIFICATION_INITIAL_EMAIL) {
			if (surveyQuestionnaireInfo.notificationConfig.initialEmailSubject) {
				handleSave([]);
			}
		} else if (props.type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
			if (
				surveyQuestionnaireInfo.notificationConfig.reminderEmailSubject &&
				typeof surveyQuestionnaireInfo.notificationConfig
					.reminderEmailFrequency === 'object' &&
				surveyQuestionnaireInfo.notificationConfig.reminderEmailFrequency
					.length > 0
			) {
				handleSave(
					surveyQuestionnaireInfo.notificationConfig.reminderEmailFrequency
				);
			}
		} else if (props.type === NOTI_CONSTANT.NOTIFICATIO_OVERDUE_EMAIL) {
			if (
				surveyQuestionnaireInfo.notificationConfig.overdueEmailSubject &&
				typeof surveyQuestionnaireInfo.notificationConfig
					.overdueEmailFrequency === 'object' &&
				surveyQuestionnaireInfo.notificationConfig.overdueEmailFrequency
					.length > 0
			) {
				handleSave(
					surveyQuestionnaireInfo.notificationConfig.overdueEmailFrequency
				);
			}
		} else {
			// Intentionally left empty as there is nothing to be done in this case
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (
			props.type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL ||
			props.type === NOTI_CONSTANT.NOTIFICATIO_OVERDUE_EMAIL
		) {
			if (frequency.length === 0) {
				setIsFrequencyAdded(false);
			} else {
				setIsFrequencyAdded(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [frequency]);

	const handleFrquencyChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const getValue = event.target.value;
		const getSplitedValue = getValue.split(' ');
		if (event.target.checked) {
			frequencyCheckbox[index] = true;
			setFrequencyCheckbox([...frequencyCheckbox]);
			setFrequency((prevItems) => [
				...prevItems,
				getSplitedValue[0].toString(),
			]);
		} else {
			frequencyCheckbox[index] = false;
			setFrequencyCheckbox([...frequencyCheckbox]);
			setFrequency((prevItems) => {
				return prevItems.filter(
					(freq) => freq !== getSplitedValue[0].toString()
				);
			});
		}
	};
	const checkViewTemplate = (subject: string) => {
		if (subject) {
			setIsVisibility(false);
			setTemplateLabel(questionsLabels.viewTemplate);
		}
	};
	const checkFrequency = (frequencyString: string[] | string) => {
		if (typeof frequencyString === 'object' && frequencyString.length > 0) {
			setFrequency(frequencyString);
			configs.remider_email_frequency.forEach(
				(item: ReminderEmailFrequencyItem) => {
					const value = item.value.toString();
					if (frequencyString.indexOf(value) !== -1) {
						frequencyCheckbox.push(true);
					} else {
						frequencyCheckbox.push(false);
					}
					setFrequencyCheckbox([...frequencyCheckbox]);
				}
			);
		}
	};

	useEffect(() => {
		if (props.type === NOTI_CONSTANT.NOTIFICATION_INITIAL_EMAIL) {
			checkViewTemplate(
				surveyQuestionnaireInfo.notificationConfig.initialEmailSubject
			);
		} else if (props.type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
			checkViewTemplate(
				surveyQuestionnaireInfo.notificationConfig.reminderEmailSubject
			);
			checkFrequency(
				surveyQuestionnaireInfo.notificationConfig.reminderEmailFrequency
			);
		} else if (props.type === NOTI_CONSTANT.NOTIFICATIO_OVERDUE_EMAIL) {
			checkViewTemplate(
				surveyQuestionnaireInfo.notificationConfig.overdueEmailSubject
			);
			checkFrequency(
				surveyQuestionnaireInfo.notificationConfig.overdueEmailFrequency
			);
		} else {
			// Intentionally left empty as there is nothing to be done in this case
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const handleRightButton = () => {
		setIsVisibility(false);
		setTemplateLabel(questionsLabels.viewTemplate);
	};
	const handleSave = (frequency1: string[]) => {
		if (props.type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
					notificationConfig: {
						...surveyQuestionnaireInfo.notificationConfig,
						reminderEmailFrequency: frequency1,
					},
				})
			);
		} else if (props.type === NOTI_CONSTANT.NOTIFICATIO_OVERDUE_EMAIL) {
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
					notificationConfig: {
						...surveyQuestionnaireInfo.notificationConfig,
						overdueEmailFrequency: frequency1,
					},
				})
			);
		} else {
			// Intentionally left empty as there is nothing to be done in this case
		}
		if (addTemplateFormRef?.current) {
			addTemplateFormRef.current.click();
		}
		if (labelSave === questionsLabels.save) {
			setSaveClicked(true);
		} else {
			setSaveClicked(false);
		}
		if (!isTemplateVisibility && isFrequencyAdded) {
			setLabelSave(questionsLabels.edit);
			setIsTemplateVisibility(true);
		} else {
			setIsTemplateVisibility(false);
			setLabelSave(questionsLabels.save);
		}
		setIsPreviewEnabled(true);
	};

	const handleOpenPreviewPopup = () => {
		setPreview(true);
	};

	const handleClosePreviewPopup = () => {
		setPreview(false);
	};

	function getTooltipTitle(headingText: string) {
		if (headingText === notificationsLabels.initialMail) {
			return notificationsLabels.initialMailTooltip;
		} else if (headingText === notificationsLabels.overdueMail) {
			return notificationsLabels.overdueMailTooltip;
		} else {
			return notificationsLabels.reminderMailTooltip;
		}
	}
	useEffect(() => {
		setIsPreviewEnabled(false);
	}, []);

	return (
		<Box>
			<StyledGridlParentContainer>
				<Grid container sx={{ display: 'block' }} spacing={2}>
					<StyledGridlHeadingContainer item lg={6}>
						<StyledTooltip
							title={getTooltipTitle(props.headingText)}
							placement="top"
						>
							<span>
								{props.headingText}
								{props.isRequired && <em>*</em>}
							</span>
						</StyledTooltip>
					</StyledGridlHeadingContainer>
					<Box>
						<Box className={classes.container}>
							<StyledTooltip
								title={notificationsLabels.previewTooltip}
								placement="top"
							>
								<Button
									variant="text"
									onClick={handleOpenPreviewPopup}
									className={classes.button}
									disabled={!isPreviewEnabled}
								>
									{isPreviewEnabled ? <PreviewIcon /> : <DisabledPreviewIcon />}
									<label className={classes.label}>
										{notificationsLabels.preview}
									</label>
								</Button>
							</StyledTooltip>
						</Box>
						<Preview
							open={preview}
							onClose={handleClosePreviewPopup}
							type={props.type}
						></Preview>
					</Box>
				</Grid>
			</StyledGridlParentContainer>
			<StyledGridrParentContainer>
				<Grid container sx={{ flexGrow: 1 }} spacing={2}>
					<Grid item lg={'auto'}>
						<Grid
							container
							sx={{ flexGrow: 1, flexDirection: 'column' }}
							spacing={2}
						>
							<StyledGridlTextContainer item lg={'auto'}>
								{props.dateText}
							</StyledGridlTextContainer>
							<StyledGridlDateContainer item lg={'auto'}>
								{props.dateValue}
							</StyledGridlDateContainer>
						</Grid>
					</Grid>
					<Grid item lg={true} sx={{ marginLeft: '65px' }}>
						{isTemplateVisibility && frequency.length > 0 && (
							<>
								<Grid
									container
									sx={{ flexGrow: 1, flexDirection: 'column' }}
									spacing={2}
								>
									<StyledGridlTextContainer item lg={true}>
										{notificationsLabels.notificationTriggerDays}
									</StyledGridlTextContainer>
									<StyledGridlDateContainer item lg={true}>
										{frequency
											.map(Number)
											.sort((a, b) => a - b)
											.map((value, index) => {
												return (
													<>
														{value}&nbsp;
														{notificationsLabels.days}
														{index === frequency.length - 1 ? (
															<></>
														) : (
															<>,&nbsp;</>
														)}
													</>
												);
											})}
									</StyledGridlDateContainer>
								</Grid>
							</>
						)}
						{!isFrequencyAdded && saveClicked && (
							<>
								<Grid
									container
									sx={{ flexGrow: 1, flexDirection: 'column' }}
									spacing={2}
								>
									<StyledGridlTextContainer item lg={true}>
										<div
											style={{
												color: 'red',
												marginTop: '60px',
												marginLeft: '120px',
											}}
										>
											{notificationsLabels.errorMessage}
										</div>
									</StyledGridlTextContainer>
								</Grid>
							</>
						)}
					</Grid>
					<Grid item lg={'auto'} sx={{ textAlign: 'end' }}>
						<Button
							variant="outlined"
							color="secondary"
							component="span"
							onClick={handleOpen}
							disabled={isTemplateVisibility}
						>
							{templateLabel}
						</Button>
						<StyledSaveButton>
							<Button
								sx={styleSaveButton}
								variant="outlined"
								color="secondary"
								component="span"
								disabled={isVisibility}
								onClick={() => handleSave(frequency)}
							>
								{labelSave}
							</Button>
						</StyledSaveButton>
						{open && (
							<AddTemplatePopup
								open={open}
								type={props.type}
								leftBtnText={questionsLabels.cancel}
								rightBtnText={questionsLabels.save}
								leftBtnHandler={handleClose}
								rightBtnHandler={() => handleRightButton()}
								templateLabel={templateLabel}
							/>
						)}
					</Grid>
				</Grid>
				{!isTemplateVisibility ? (
					<Grid container sx={{ display: 'flex' }} spacing={2}>
						<Grid item lg={9}>
							{props.showTriggerDays && (
								<Box
									sx={{
										position: 'relative',
										marginLeft: '165px',
										bottom: '62px',
									}}
								>
									<StyledGridlTextContainer>
										{notificationsLabels.selectTriggerDays}
									</StyledGridlTextContainer>
									<FormGroup
										sx={{
											display: 'unset',
										}}
									>
										{configs?.remider_email_frequency?.map(
											(item: ReminderEmailFrequencyItem, index: number) => (
												<FormControlLabel
													key={item.key}
													control={
														<Checkbox checked={!!frequencyCheckbox[index]} />
													}
													value={item.key}
													label={`${item.value} ${commonLabels.days}`}
													onChange={(event) =>
														handleFrquencyChange(
															event as React.ChangeEvent<HTMLInputElement>,
															index
														)
													}
												/>
											)
										)}
									</FormGroup>
								</Box>
							)}
						</Grid>

						<Grid
							item
							lg={3}
							sx={{ marginTop: '60px', marginBottom: '20px' }}
						></Grid>
					</Grid>
				) : (
					<div></div>
				)}
			</StyledGridrParentContainer>
		</Box>
	);
};
export default EmailSegments;
