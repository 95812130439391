import React, { useState } from 'react';
import { Stack, Button, styled } from '@mui/material';

interface ButtonsGroupProps {
	buttonsList: string[];
	onButtonSelect: (selectedButton: string) => void;
}

const ButtonsContainer = styled(Stack)({
	padding: '0px 5px',
	borderRadius: '25px',
	display: 'inline-block',
	margin: '5px',
	background: '#F1EFED',
});

const ButtonsGroup: React.FC<ButtonsGroupProps> = ({
	buttonsList,
	onButtonSelect,
}) => {
	const [selectedButton, setSelectedButton] = useState<string>(buttonsList[0]);
	const handleButtonClick = (buttonName: string) => {
		setSelectedButton(buttonName);
		onButtonSelect(buttonName);
	};

	return (
		<ButtonsContainer direction="row" spacing={2}>
			{buttonsList.map((buttonName, index) => (
				<Button
					key={index}
					onClick={() => handleButtonClick(buttonName)}
					variant={selectedButton === buttonName ? 'contained' : 'text'}
					color={selectedButton === buttonName ? 'primary' : 'secondary'}
					sx={{ borderRadius: '25px' }}
				>
					{buttonName}
				</Button>
			))}
		</ButtonsContainer>
	);
};

export default ButtonsGroup;
