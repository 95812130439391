/* eslint-disable no-useless-escape */
export const initialEmailTemplate = `<style>
<!--
/* Font Definitions */
@font-face
	 {font-family: Arial;
	 panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	 {font-family: Arial;
	 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	 {font-family: Arial;
	 panose-1:2 15 5 2 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	 {margin:0in;
	 font-size:13.0pt;
	 font-family:"Arial",sans-serif;}
a:link, span.MsoHyperlink
	 {mso-style-priority:99;
	 color:blue;
	 text-decoration:underline;}
span.MsoSmartlink
	 {mso-style-priority:99;
	 color:blue;
	 background:#F3F2F1;
	 text-decoration:underline;}
span.EmailStyle20
	 {mso-style-type:personal-reply;
	 font-family:"Arial",sans-serif;
	 color:windowtext;}
.MsoChpDefault
	 {mso-style-type:export-only;
	 font-size:10.0pt;
	 mso-ligatures:none;}
@page WordSection1
	 {size:8.5in 11.0in;
	 margin:0in;}
div.WordSection1
	 {page:WordSection1;}
/* List Definitions */
@list l0
	 {mso-list-id:822544210;
	 mso-list-template-ids:-57240054;}
@list l0:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l0:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1
	 {mso-list-id:1942451187;
	 mso-list-template-ids:208016620;}
@list l1:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l1:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Wingdings;}
@list l1:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
ol
	 {margin-bottom:0in;}
ul
	 {margin-bottom:0in;}
-->
</style>



<table role="presentation" style="font-family: Arial; padding-left:20px;">
<tbody>
	<tr>
		<td colspan="3" style="padding:20px;"><img src="https://dev.step.dth.jnj.com/johnson-johnson-logo.svg"></td>
	</tr>
	<tr style="height:250px">
		<td colspan="3" background="https://dev.step.dth.jnj.com/Header.png" width="100%" height="250px" style="height:250px">
			<!--[if gte mso 9]>
		<v:image xmlns:v="urn:schemas-microsoft-com:vml" 
			style='mso-width-percent:1000;height:250;position:absolute;bottom:0;left:0;border:0;z-index:-3;' 
			src="https://dev.step.dth.jnj.com/Header.png" />
	 </v:rect>
		<![endif]-->
			<table>
				<tbody>
					<tr>
						<td style="padding:20px;">
							<h1 style="color:white; font-family: Arial;">Action Required</h1>
						</td>
					</tr>
					<tr>
						<td style="padding-left:20px; width: 570px">
							<h1 style="font-weight: 700;font-family: Arial; color: white; font-size: 28px;">Complete {{QUESTIONNAIRE_NAME}}</h1>
						</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px; font-family: Arial;"><strong>Hello</strong>,</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3">
			<p style="padding: 16px; font-size: 16px; font-family: Arial;">This is a reminder to complete the {{QUESTIONNAIRE_NAME}}. You are required to assess the In Scope or Out of Scope training status for your contingent worker direct reports for 2024 Annual Complaint (Adverse Event, Special Situation, Product Quality Complaint) Awareness Training.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
			<table>
				<tbody>
					<tr>
						<td>
							<h3 style="color:#EB1700; font-size: 16px; font-family: Arial;">Action Requested by You</h3>
						</td>
					</tr>
					<tr>
						<td style="font-size: 16px; font-family: Arial;">Click the below link to open the {{QUESTIONNAIRE_NAME}} and follow the instructions to complete your questionnaire as soon as possible and no later than {{DUE_DATE}}.</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr style="padding-top:20px;padding-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
		<td style="width:40%;">
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
		<td align="center" style="width:105px;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:15px; text-decoration: none;">
			{{QUESTIONNAIRELINK}}
		</td>
		<td style="width:40%;">
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
			<table>
				<tbody>
					<tr>
						<td>
							<h3 style="color:#EB1700; font-size: 16px; font-family: Arial;">Questions?</h3>
						</td>
					</tr>
					<tr>
						<td style="font-size: 16px; font-family: Arial;">For questions regarding the {{QUESTIONNAIRE_NAME}} or the 2024 Approved Exclusions List please contact the PV Training
							Support Mailbox. Click below to send a inquiry to the PV Training Mailbox.</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr style="padding-top:20px;padding-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
		<td>
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
		<td align="center" style="width:105px; text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:15px; text-decoration: none;">
			<a href="mailto:RA-RNDUS-BRMU-TechSu@its.jnj.com?subject=Annual%20Complaint%20Awareness%20Training%20Audience%20Identification%20Tool%20" style="display:inline-block; background:#EB1700; color:white; border:none; border-radius:5px; text-decoration: none;"> Click Here </a>
		</td>
		<td>
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
	</tr>
	<!--<tr>-->
	<!--    <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>-->
	<!--    <br>-->
	<!--</tr>-->
	<tr style="margin:16px; margin-left: 0px">
		<td colspan="3" style="padding-top:20px;padding-bottom:20px; font-size: 16px; font-family: Arial;">
			Thank you for completing this assessment and supporting a culture of compliance.
		</td>
	</tr>

	<tr>
		<td colspan="3" style="padding-left:20px;padding-bottom:20px; font-size: 16px; font-family: Arial;">Please see Approved Exclusion List Below.</td>
	</tr>
	<tr style="text-align:left ; text-decoration: underline;">
		<td colspan="3" style="padding-left:20px; font-size: 16px; font-family: Arial;"><strong>2024 Approved Exclusions List: Contractor/Consultant Roles Exempt from Annual Complaint Awareness Training</strong></td>
	</tr>
	<tr>
		<td colspan="3">
			<ol style="margin:10px">
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Cafeteria and restaurant workers; cafeteria grease trap cleaners
				</li>
				<li class="MsoNormal" style=" margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Chauffeurs; fleet car drivers; couriers; loading and transport
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Childcare/Daycare workers
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Cleaning personnel/Janitorial Service; uniform &amp; linen support
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Contractors that received a WWID to access a specific non-safety system or because they have J&amp;J laptops; however, do <u>NOT</u> receive any complaints (e.g., external tax inspectors; purchasing)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">External partners that received a WWID to access specific non-safety systems (e.g., Supplier Network Collaboration [SNC] Partners; Supply Chain raw material and finished goods suppliers)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Facilities Management: Stockroom/storeroom attendants - deal with spare parts or non-production-related materials. (<b>Note</b>: Warehouse personnel who handle J&amp;J product <u>must train</u>)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Fitness instructors <u>without</u> a WWID (NOTE: Fitness/Wellness Consultants <u>with</u> WWIDs must train e.g., those who manage gym/fitness facility, perform fitness/ergonomics assessments. Nurses or other global health services staff must also train)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">IT support contractors/vendors or partners that provide only hardware support in the server room (including external support)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Lab installation; calibration technicians (i.e., calibration of machines); lab equipment related contractors
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3;font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Movers (people who move office furniture)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Non-GMP equipment maintenance technicians (e.g., elevators, electricians, office heating/ventilation/air conditioning [HVAC], utilities operation and maintenance)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Painters; construction workers; landscapers; indoor plant care
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Non-human, test or robotic users
				</li>
				<li class="MsoNormal" style=" mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Terminated
				</li>
			</ol>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px; font-size: 16px; font-family: Arial;"><strong>IMPORTANT: </strong>Security and mailroom workers <u>must always be trained</u>.</td>
	</tr>

</tbody>
</table>`;

export const reminderEmailTemplate = `<style>
<!--
/* Font Definitions */
@font-face
	 {font-family: Arial;
	 panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	 {font-family: Arial;
	 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	 {font-family: Arial;
	 panose-1:2 15 5 2 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	 {margin:0in;
	 font-size:13.0pt;
	 font-family:"Arial",sans-serif;}
a:link, span.MsoHyperlink
	 {mso-style-priority:99;
	 color:blue;
	 text-decoration:underline;}
span.MsoSmartlink
	 {mso-style-priority:99;
	 color:blue;
	 background:#F3F2F1;
	 text-decoration:underline;}
span.EmailStyle20
	 {mso-style-type:personal-reply;
	 font-family:"Arial",sans-serif;
	 color:windowtext;}
.MsoChpDefault
	 {mso-style-type:export-only;
	 font-size:10.0pt;
	 mso-ligatures:none;}
@page WordSection1
	 {size:8.5in 11.0in;
	 margin:0in;}
div.WordSection1
	 {page:WordSection1;}
/* List Definitions */
@list l0
	 {mso-list-id:822544210;
	 mso-list-template-ids:-57240054;}
@list l0:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l0:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1
	 {mso-list-id:1942451187;
	 mso-list-template-ids:208016620;}
@list l1:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l1:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Wingdings;}
@list l1:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
ol
	 {margin-bottom:0in;}
ul
	 {margin-bottom:0in;}
-->
</style>



<table role="presentation" style="font-family: Arial; padding-left:20px;">
<tbody>
	<tr>
		<td colspan="3" style="padding:20px;"><img src="https://dev.step.dth.jnj.com/johnson-johnson-logo.svg"></td>
	</tr>
	<tr style="height:250px">
		<td colspan="3" background="https://dev.step.dth.jnj.com/Header.png" width="100%" height="250px" style="height:250px">
			<!--[if gte mso 9]>
		<v:image xmlns:v="urn:schemas-microsoft-com:vml" 
			style='mso-width-percent:1000;height:250;position:absolute;bottom:0;left:0;border:0;z-index:-3;' 
			src="https://dev.step.dth.jnj.com/Header.png" />
	 </v:rect>
		<![endif]-->
			<table>
				<tbody>
					<tr>
						<td style="padding:20px;">
							<h1 style="color:white; font-family: Arial;">Action Required</h1>
						</td>
					</tr>
					<tr>
						<td style="padding-left:20px; width: 570px">
							<h1 style="font-weight: 700;font-family: Arial; color: white; font-size: 28px;">Complete {{QUESTIONNAIRE_NAME}}</h1>
						</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px; font-family: Arial;"><strong>Hello</strong>,</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3">
			<p style="padding: 16px; font-size: 16px; font-family: Arial;">This is a reminder to complete the {{QUESTIONNAIRE_NAME}}. You are required to assess the In Scope or Out of Scope training status for your contingent worker direct reports for 2024 Annual Complaint (Adverse Event, Special Situation, Product Quality Complaint) Awareness Training.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
			<table>
				<tbody>
					<tr>
						<td>
							<h3 style="color:#EB1700; font-size: 16px; font-family: Arial;">Action Requested by You</h3>
						</td>
					</tr>
					<tr>
						<td style="font-size: 16px; font-family: Arial;">Click the below link to open the {{QUESTIONNAIRE_NAME}} and follow the instructions to complete your questionnaire as soon as possible and no later than {{DUE_DATE}}.</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr style="padding-top:20px;padding-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
		<td style="width:40%;">
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
		<td align="center" style="width:105px;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:15px; text-decoration: none;">
			{{QUESTIONNAIRELINK}}
		</td>
		<td style="width:40%;">
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
			<table>
				<tbody>
					<tr>
						<td>
							<h3 style="color:#EB1700; font-size: 16px; font-family: Arial;">Questions?</h3>
						</td>
					</tr>
					<tr>
						<td style="font-size: 16px; font-family: Arial;">For questions regarding the {{QUESTIONNAIRE_NAME}} or the 2024 Approved Exclusions List please contact the PV Training
							Support Mailbox. Click below to send a inquiry to the PV Training Mailbox.</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr style="padding-top:20px;padding-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
		<td>
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
		<td align="center" style="width:105px; text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:15px; text-decoration: none;">
			<a href="mailto:RA-RNDUS-BRMU-TechSu@its.jnj.com?subject=Annual%20Complaint%20Awareness%20Training%20Audience%20Identification%20Tool%20" style="display:inline-block; background:#EB1700; color:white; border:none; border-radius:5px; text-decoration: none;"> Click Here </a>
		</td>
		<td>
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
	</tr>
	<!--<tr>-->
	<!--    <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>-->
	<!--    <br>-->
	<!--</tr>-->
	<tr style="margin:16px; margin-left: 0px">
		<td colspan="3" style="padding-top:20px;padding-bottom:20px; font-size: 16px; font-family: Arial;">
			Thank you for completing this assessment and supporting a culture of compliance.
		</td>
	</tr>

	<tr>
		<td colspan="3" style="padding-left:20px;padding-bottom:20px; font-size: 16px; font-family: Arial;">Please see Approved Exclusion List Below.</td>
	</tr>
	<tr style="text-align:left ; text-decoration: underline;">
		<td colspan="3" style="padding-left:20px; font-size: 16px; font-family: Arial;"><strong>2024 Approved Exclusions List: Contractor/Consultant Roles Exempt from Annual Complaint Awareness Training</strong></td>
	</tr>
	<tr>
		<td colspan="3">
			<ol style="margin:10px">
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Cafeteria and restaurant workers; cafeteria grease trap cleaners
				</li>
				<li class="MsoNormal" style=" margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Chauffeurs; fleet car drivers; couriers; loading and transport
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Childcare/Daycare workers
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Cleaning personnel/Janitorial Service; uniform &amp; linen support
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Contractors that received a WWID to access a specific non-safety system or because they have J&amp;J laptops; however, do <u>NOT</u> receive any complaints (e.g., external tax inspectors; purchasing)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">External partners that received a WWID to access specific non-safety systems (e.g., Supplier Network Collaboration [SNC] Partners; Supply Chain raw material and finished goods suppliers)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Facilities Management: Stockroom/storeroom attendants - deal with spare parts or non-production-related materials. (<b>Note</b>: Warehouse personnel who handle J&amp;J product <u>must train</u>)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Fitness instructors <u>without</u> a WWID (NOTE: Fitness/Wellness Consultants <u>with</u> WWIDs must train e.g., those who manage gym/fitness facility, perform fitness/ergonomics assessments. Nurses or other global health services staff must also train)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">IT support contractors/vendors or partners that provide only hardware support in the server room (including external support)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Lab installation; calibration technicians (i.e., calibration of machines); lab equipment related contractors
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3;font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Movers (people who move office furniture)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Non-GMP equipment maintenance technicians (e.g., elevators, electricians, office heating/ventilation/air conditioning [HVAC], utilities operation and maintenance)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Painters; construction workers; landscapers; indoor plant care
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Non-human, test or robotic users
				</li>
				<li class="MsoNormal" style=" mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Terminated
				</li>
			</ol>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px; font-size: 16px; font-family: Arial;"><strong>IMPORTANT: </strong>Security and mailroom workers <u>must always be trained</u>.</td>
	</tr>

</tbody>
</table>`;

export const overdueEmailTemplate = `<style>
<!--
/* Font Definitions */
@font-face
	 {font-family: Arial;
	 panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	 {font-family: Arial;
	 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	 {font-family: Arial;
	 panose-1:2 15 5 2 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	 {margin:0in;
	 font-size:13.0pt;
	 font-family:"Arial",sans-serif;}
a:link, span.MsoHyperlink
	 {mso-style-priority:99;
	 color:blue;
	 text-decoration:underline;}
span.MsoSmartlink
	 {mso-style-priority:99;
	 color:blue;
	 background:#F3F2F1;
	 text-decoration:underline;}
span.EmailStyle20
	 {mso-style-type:personal-reply;
	 font-family:"Arial",sans-serif;
	 color:windowtext;}
.MsoChpDefault
	 {mso-style-type:export-only;
	 font-size:10.0pt;
	 mso-ligatures:none;}
@page WordSection1
	 {size:8.5in 11.0in;
	 margin:0in;}
div.WordSection1
	 {page:WordSection1;}
/* List Definitions */
@list l0
	 {mso-list-id:822544210;
	 mso-list-template-ids:-57240054;}
@list l0:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l0:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1
	 {mso-list-id:1942451187;
	 mso-list-template-ids:208016620;}
@list l1:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l1:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Wingdings;}
@list l1:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
ol
	 {margin-bottom:0in;}
ul
	 {margin-bottom:0in;}
-->
</style>



<table role="presentation" style="font-family: Arial; padding-left:20px;">
<tbody>
	<tr>
		<td colspan="3" style="padding:20px;"><img src="https://dev.step.dth.jnj.com/johnson-johnson-logo.svg"></td>
	</tr>
	<tr style="height:250px">
		<td colspan="3" background="https://dev.step.dth.jnj.com/Header.png" width="100%" height="250px" style="height:250px">
			<!--[if gte mso 9]>
		<v:image xmlns:v="urn:schemas-microsoft-com:vml" 
			style='mso-width-percent:1000;height:250;position:absolute;bottom:0;left:0;border:0;z-index:-3;' 
			src="https://dev.step.dth.jnj.com/Header.png" />
	 </v:rect>
		<![endif]-->
			<table>
				<tbody>
					<tr>
						<td style="padding:20px;">
							<h1 style="color:white; font-family: Arial;">Action Required</h1>
						</td>
					</tr>
					<tr>
						<td style="padding-left:20px; width: 570px">
							<h1 style="font-weight: 700;font-family: Arial; color: white; font-size: 28px;">Complete {{QUESTIONNAIRE_NAME}}</h1>
						</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px; font-family: Arial;"><strong>Hello</strong>,</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3">
			<p style="padding: 16px; font-size: 16px; font-family: Arial;">This is a reminder to complete the {{QUESTIONNAIRE_NAME}}. You are required to assess the In Scope or Out of Scope training status for your contingent worker direct reports for 2024 Annual Complaint (Adverse Event, Special Situation, Product Quality Complaint) Awareness Training.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
			<table>
				<tbody>
					<tr>
						<td>
							<h3 style="color:#EB1700; font-size: 16px; font-family: Arial;">Action Requested by You</h3>
						</td>
					</tr>
					<tr>
						<td style="font-size: 16px; font-family: Arial;">Click the below link to open the {{QUESTIONNAIRE_NAME}} and follow the instructions to complete your questionnaire as soon as possible and no later than {{DUE_DATE}}.</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr style="padding-top:20px;padding-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
		<td style="width:40%;">
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
		<td align="center" style="width:105px;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:15px; text-decoration: none;">
			{{QUESTIONNAIRELINK}}
		</td>
		<td style="width:40%;">
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr>
		<td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
			<table>
				<tbody>
					<tr>
						<td>
							<h3 style="color:#EB1700; font-size: 16px; font-family: Arial;">Questions?</h3>
						</td>
					</tr>
					<tr>
						<td style="font-size: 16px; font-family: Arial;">For questions regarding the {{QUESTIONNAIRE_NAME}} or the 2024 Approved Exclusions List please contact the PV Training
							Support Mailbox. Click below to send a inquiry to the PV Training Mailbox.</td>
					</tr>
				</tbody>
			</table>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

	</tr>
	<tr style="padding-top:20px;padding-bottom:20px;display:flex;justify-content:space-between;align-items: center;">
		<td>
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
		<td align="center" style="width:105px; text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:15px; text-decoration: none;">
			<a href="mailto:RA-RNDUS-BRMU-TechSu@its.jnj.com?subject=Annual%20Complaint%20Awareness%20Training%20Audience%20Identification%20Tool%20" style="display:inline-block; background:#EB1700; color:white; border:none; border-radius:5px; text-decoration: none;"> Click Here </a>
		</td>
		<td>
			<hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
		</td>
	</tr>
	<!--<tr>-->
	<!--    <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>-->
	<!--    <br>-->
	<!--</tr>-->
	<tr style="margin:16px; margin-left: 0px">
		<td colspan="3" style="padding-top:20px;padding-bottom:20px; font-size: 16px; font-family: Arial;">
			Thank you for completing this assessment and supporting a culture of compliance.
		</td>
	</tr>

	<tr>
		<td colspan="3" style="padding-left:20px;padding-bottom:20px; font-size: 16px; font-family: Arial;">Please see Approved Exclusion List Below.</td>
	</tr>
	<tr style="text-align:left ; text-decoration: underline;">
		<td colspan="3" style="padding-left:20px; font-size: 16px; font-family: Arial;"><strong>2024 Approved Exclusions List: Contractor/Consultant Roles Exempt from Annual Complaint Awareness Training</strong></td>
	</tr>
	<tr>
		<td colspan="3">
			<ol style="margin:10px">
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Cafeteria and restaurant workers; cafeteria grease trap cleaners
				</li>
				<li class="MsoNormal" style=" margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Chauffeurs; fleet car drivers; couriers; loading and transport
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Childcare/Daycare workers
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Cleaning personnel/Janitorial Service; uniform &amp; linen support
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Contractors that received a WWID to access a specific non-safety system or because they have J&amp;J laptops; however, do <u>NOT</u> receive any complaints (e.g., external tax inspectors; purchasing)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">External partners that received a WWID to access specific non-safety systems (e.g., Supplier Network Collaboration [SNC] Partners; Supply Chain raw material and finished goods suppliers)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Facilities Management: Stockroom/storeroom attendants - deal with spare parts or non-production-related materials. (<b>Note</b>: Warehouse personnel who handle J&amp;J product <u>must train</u>)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">Fitness instructors <u>without</u> a WWID (NOTE: Fitness/Wellness Consultants <u>with</u> WWIDs must train e.g., those who manage gym/fitness facility, perform fitness/ergonomics assessments. Nurses or other global health services staff must also train)
				</li>
				<li class="MsoNormal" style="margin-left:10px; mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">IT support contractors/vendors or partners that provide only hardware support in the server room (including external support)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Lab installation; calibration technicians (i.e., calibration of machines); lab equipment related contractors
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3;font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Movers (people who move office furniture)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Non-GMP equipment maintenance technicians (e.g., elevators, electricians, office heating/ventilation/air conditioning [HVAC], utilities operation and maintenance)
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Painters; construction workers; landscapers; indoor plant care
				</li>
				<li class="MsoNormal" style="mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Non-human, test or robotic users
				</li>
				<li class="MsoNormal" style=" mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;mso-list:l1 level1 lfo3; font-size: 14px; font-family: Arial;">&nbsp;&nbsp;Terminated
				</li>
			</ol>
		</td>
	</tr>
	<tr>
		<td colspan="3" style="padding-left:20px; font-size: 16px; font-family: Arial;"><strong>IMPORTANT: </strong>Security and mailroom workers <u>must always be trained</u>.</td>
	</tr>

</tbody>
</table>`;
