import React, { useEffect } from 'react';
import { select, arc, PieArcDatum, pie, scaleOrdinal } from 'd3';
import { Numbers } from '../../utils/Enum';

interface DonutDataItem {
	label: string;
	value: number;
	color: string;
}
interface DonutChartProps {
	data: DonutDataItem[];
	width: number;
	height: number;
	centerText: string;
}

const DonutChart: React.FC<DonutChartProps> = ({
	data,
	width,
	height,
	centerText,
}) => {
	useEffect(() => {
		if (data.length === 0) return;

		const svg = select('#donut-chart-svg');
		svg.selectAll('*').remove();

		const donutThicknessFactor = 0.8;
		const radius = Math.min(width, height) / Numbers.two;

		// Define the arc generator
		const donutArc = arc<PieArcDatum<DonutDataItem>>()
			.innerRadius(radius * donutThicknessFactor)
			.outerRadius(radius);

		// Define the pie layout
		const svgPie = pie<DonutDataItem>().value((d) => d.value);
		// Create arcs for each data entry
		const arcs = svgPie(data);
		// Set up colors
		const color = scaleOrdinal()
			.domain(data.map((d) => d.label))
			.range(data.map((d) => d.color));

		// Append the arcs to the svg
		svg
			.selectAll('path')
			.data(arcs)
			.enter()
			.append('path')
			.attr('d', donutArc)
			.attr('fill', (d) => color(d.data.label) as string)
			.attr(
				'transform',
				`translate(${width / Numbers.two}, ${height / Numbers.two})`
			);

		svg
			.append('text')
			.text('Responded')
			.attr('x', width / Numbers.two)
			.attr('y', height / Numbers.two - Numbers.ten)
			.attr('dy', '0.35em')
			.style('text-anchor', 'middle')
			.style('font-family', 'JohnsonText-Regular')
			.style('font-size', '14px');

		svg
			.append('text')
			.text(centerText)
			.attr('x', width / Numbers.two)
			.attr('y', height / Numbers.two + Numbers.ten)
			.attr('dy', '0.35em')
			.style('text-anchor', 'middle')
			.style('font-family', 'JohnsonDisplay-Bold')
			.style('font-size', '24px')
			.style('line-height', '32px');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, width, height]);
	return <svg id="donut-chart-svg" width={width} height={height}></svg>;
};
export default DonutChart;
