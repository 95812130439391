export function getAuthToken() {
	let keys = Object.keys(localStorage);
	let token = '',
		graphToken = '',
		userEmailId = '',
		userName = '',
		roles = [],
		userFirstName = '',
		userLastName = '';

	for (const key of keys) {
		let val = localStorage.getItem(key);

		if (val && validJSON(val)) {
			let parsedVal = JSON.parse(val);
			if (Object.prototype.hasOwnProperty.call(parsedVal, 'credentialType')) {
				({ token, graphToken } = getToken(parsedVal, token, graphToken));
			} else {
				({ userEmailId, userName, roles } = getUserDetails(
					parsedVal,
					userEmailId,
					userName,
					roles
				));
			}
		}
	}
	return {
		idToken: token,
		graphToken: graphToken,
		userEmailId: userEmailId,
		userName: userName,
		roles: roles,
		userFirstName: userFirstName,
		userLastName: userLastName,
	};
}

/* Get user details
 * userEmailId
 * userName
 * roles
 */
const getUserDetails = (parsedVal, userEmailId, userName, roles) => {
	if (Object.prototype.hasOwnProperty.call(parsedVal, 'authorityType')) {
		userEmailId = parsedVal.username;
		userName = parsedVal.name;
		roles = parsedVal?.idTokenClaims?.roles;
	}
	return { userEmailId, userName, roles };
};

/* Get Token
 * IdToken
 * AccessToken
 */
const getToken = (parsedVal, token, graphToken) => {
	if (
		parsedVal.credentialType === 'IdToken' &&
		parsedVal.clientId ===
			process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID
	) {
		token = parsedVal.secret;
	} else {
		if (parsedVal.credentialType === 'AccessToken') {
			graphToken = parsedVal.secret;
		}
	}
	return { token, graphToken };
};

function validJSON(str) {
	try {
		// eslint-disable-next-line no-unused-vars
		let obj = JSON.parse(str);
		if (obj) {
			return true;
		}
	} catch (error) {
		return false;
	}
	return true;
}
