export const URL_CONSTANT = {
	EDIT_SURVEY: '/edit-survey',
	END_USER: '/end-user',
};
export const ADMIN_API_URLS = {
	API_QUESTIONNAIRE_LIST_URL: '/api/v1/Questionnaire',
	API_QUESTIONNAIRE_CONFIG_URL: '/api/v1/configs?category=system',
	API_QUESTIONNAIRE_USERS_URL: '/api/v1/users',
	API_POST_QUESTIONNAIRE_USERS_URL: '/api/v1/questionnaire',
	API_QUESTIONNAIRE_USERS_VALIDATE_URL: '/api/v1/users/validate',
	API_QUESTIONNAIRE_USERS_EXPORT_URL: '/api/v1/users/export',
	API_QUESTIONNAIRE_BY_ID_URL: '/api/v1/questionnaire',
};
export const FRONT_USER_API_URLS = {
	API_MYQUESTIONNAIRE_LIST_URL: '/api/v1/MyQuestionnaires',
};
