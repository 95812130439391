import { Routes, Route } from 'react-router-dom';
import ListingPage from '../pages/ListingPage';
import CreateSurvey from '../pages/create-survey/CreateSurvey';
import NotFound from '../pages/NotFound';
import QuestionnaireResult from '../pages/questionnaire-results/QuestionnaireResult';
import LandingScreen from '../components/endUser/LandingScreen';
import CWListingPage from '../pages/CwListingPage';

const RouterOutlet = () => {
	return (
		<Routes>
			<Route path="/" element={<ListingPage />} />
			<Route path="/listing" element={<ListingPage />} />
			<Route path="/create-survey" element={<CreateSurvey />} />
			<Route path="/edit-survey" element={<CreateSurvey />} />
			<Route
				path="/questionnaire-results/:questionnaireID"
				element={<QuestionnaireResult />}
			/>
			<Route path="/end-user/:id" element={<LandingScreen />} />
			<Route path="/cwuser/:id" element={<CWListingPage />} />
			<Route
				path="/unauthorized"
				Component={() => {
					// This will trigger logout from DTH
					window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
					return null;
				}}
			/>
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};
export default RouterOutlet;
