import React from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import ChartIndicator from './ChartIndicator';

const ChartSummaryContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '10px',
});
interface DataItemProps {
	label: string;
	value: number;
	color: string;
	outOfValue: number | null;
	totalValue: number | null;
}
interface ChartSummaryProps {
	data: DataItemProps[];
	chartType: string;
}
export const ChartSummary: React.FC<ChartSummaryProps> = ({
	data,
	chartType,
}) => {
	return (
		<Stack>
			{data.map((element) => (
				<ChartSummaryContainer key={element.label}>
					<ChartIndicator
						key={element.label}
						color={element.color}
						label={element.label}
					/>
					{chartType === 'Percentage' && (
						<Typography fontSize="14px" variant="body1">
							{element.value}%
						</Typography>
					)}
					{chartType === 'Values' && (
						<Typography fontSize="14px" variant="body1">
							{element.outOfValue}/{element.totalValue}
						</Typography>
					)}
				</ChartSummaryContainer>
			))}
		</Stack>
	);
};

export default ChartSummary;
