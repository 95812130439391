import React, { useState } from 'react';
import {
	Avatar,
	Box,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Logout from '@mui/icons-material/Logout';
import { STEPTheme } from '../utils/Theme';
import { ReactComponent as MenuIcon } from '../assets/images/menu-icon.svg';
import { ReactComponent as DTH_Logo } from '../assets/images/DTH_home_icon.svg';
import { ReactComponent as DTHBackIcon } from '../assets/images/flip-backward.svg';
import { useAppSelector } from '../app/Hooks';
import { configurationData } from '../redux/SurveySlice';
import { commonLabels } from '../utils/CommonLabels';

const UserMenuContainer = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: '0.8rem',
});

const StyledTypography = styled(Typography)({
	color: STEPTheme.colors.text,
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '16px',
	fontWeight: '400',
	lineHeight: '16px',
});

const UserMenu: React.FC<unknown> = () => {
	const configs = useAppSelector(configurationData);

	/**
	 * Handle Logout User.
	 */
	const handlelogoutUser = () => {
		localStorage.clear();
		// This will trigger logout from DTH
		window.location.replace(
			`${process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI}`
		);
	};

	const handleRedirectionToDTH = () => {
		localStorage.clear();
		// This will redirect to DTH
		window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
	};

	const [anchorElUser, setAnchorElUser] = useState<HTMLButtonElement | null>(
		null
	);

	/**
	 * Handle Open user Menu.
	 */
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		const val = event.currentTarget;
		setAnchorElUser(val);
	};

	/**
	 * Handle Close User Menu.
	 */
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	let userInitials = '';
	if (configs?.user?.userName) {
		userInitials = (
			configs.user.userName.split(',')[1][1] +
			configs.user.userName.substring(0, 1)
		).toUpperCase();
	}
	return (
		<UserMenuContainer>
			{configs?.user?.userName && (
				<StyledTypography>
					Hi {configs.user.userName.split(',')[1].split('[')[0].trim()}{' '}
					{configs.user.userName.split(',')[0]}
				</StyledTypography>
			)}

			<Avatar alt="Profile Photo" src={''}>
				{userInitials}
			</Avatar>
			<IconButton
				sx={{ p: 0 }}
				onClick={(event) =>
					handleOpenUserMenu(event as React.MouseEvent<HTMLButtonElement>)
				}
			>
				<MenuIcon data-testid="menu-icon" />
			</IconButton>
			<Menu
				anchorEl={anchorElUser}
				id="account-menu"
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
				onClick={handleCloseUserMenu}
				disableScrollLock={true}
				PaperProps={{
					elevation: 0,
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1,
							pointerEvents: 'none',
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0,
							pointerEvents: 'none',
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem onClick={handleRedirectionToDTH}>
					<ListItemIcon>
						<DTHBackIcon fontSize="small" />
					</ListItemIcon>
					<Box sx={{ paddingRight: '10px' }}>{commonLabels.backToDTH}</Box>
					<DTH_Logo fontSize="small" />
				</MenuItem>
				<MenuItem onClick={handlelogoutUser}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					{commonLabels.logout}
				</MenuItem>
			</Menu>
		</UserMenuContainer>
	);
};

export default UserMenu;
